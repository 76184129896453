import { Button, Card, Flex, Modal, Result } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { FiPlus } from "react-icons/fi";

const Payments = () => {
  const navigate = useNavigate();
  const { amount, price, type } = useParams();

  return (
    <Modal open={true} footer={null} onCancel={() => {
      if (type == "Whatsapp") {
        navigate("/Campaigns/Create/1");
      } else if (type == "Email") {
        navigate("/Campaigns/Create/0");
      } else {
        navigate("/Campaigns/Create/2");
      }
    }}>

    <Flex
      align="center"
      justify="center"
      style={{ width: "100%" }}
    >
        <Result
          status="success"
          title={`Successfully Purchased ${type} Balance!`}
          subTitle={`You purchased ${amount} ${type} at ${price} $`}
          extra={[
            <Button
              type="primary"
              key="console"
              size="large"
              onClick={() => {
                if (type == "Whatsapp") {
                  navigate("/Campaigns/Create/1");
                } else if (type == "Email") {
                  navigate("/Campaigns/Create/0");
                } else {
                  navigate("/Campaigns/Create/2");
                }
              }}
            >
              Create campaign
            </Button>,
            <Button size="large" key="buy" onClick={() => {}}>
              Increase balances
            </Button>,
          ]}
        />
    </Flex>
    </Modal>
  );
};

export default Payments;
