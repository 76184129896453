import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { OrderDirection } from "../../shared/enums";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { SearchContext } from "../../context/SearchContext";
import QueryString from "qs";

export const FollowupContext= createContext({
    filters: {
        from: null,
        to: null,
        authors: [],
        selectedAuthors: [],
        orderBy: null,
        orderDirection: null,
    },
    setFilters: () => {},

    page: 1,
    setPage: () => {},

    stats: {

    },
    setStats: () => {},

    data: [],
    setData: ()=> {},

    loading: false,
    setLoading: () => {}

})

export const FollowupContextProvider = ({children}) => {
    const axios = useAxiosPrivate();

    const [filters, setFilters] = useState()
    const [page, setPage] = useState(1);
    const [stats, setStats] = useState();   
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState()

    const {debouncedValue} = useContext(SearchContext)
    
    const perPage = 7;

    useEffect(async() => {
        const response = await axios.get(`Users/GetAuthors`);

        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
              value: d.id,
              name: `${d.firstname} ${d.lastname}`,
              profile: d.profileImagePath,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));

    }, [])

    useEffect(async () => {
        setLoading(true)
        let response = await axios.get(`/Followup/List`, {
            params: {

                authors: filters?.selectedAuthors,
                from: filters?.from ? new Date(filters.from) : null,
                to: filters?.to ? new Date(filters.to) : null,
                search: debouncedValue,
                sortDirection: filters?.orderDirection,
                orderBy: filters?.orderBy,
                pageSize: perPage,
                page: page  
            }, paramsSerializer: params => {
                return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true});
              }
        })

        setData(response.data.data);

        setStats(prev => ({
            total: response.data.count,
            first: response.data.first,
            last: response.data.last,
            authorName: response.data.authorName,
            authorProfile: response.data.authorProfile
        }))

        setLoading(false);
    } ,[filters, debouncedValue, page])

    return <FollowupContext.Provider value={{
        filters, 
        setFilters,

        data,
        setData,

        stats,
        setStats,

        page,
        setPage,
        
        perPage,

        loading
    }}>
        {children}
    </FollowupContext.Provider>
}