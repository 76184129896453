import { Avatar, Col, Row } from "antd";
import Stat from "../../shared/Stat";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import { IoDocument } from "react-icons/io5";
import { DateFormat } from "../../dateformat";
import { useContext } from "react";
import { MediaContext } from "./MediaContext";
import env from "../../env.json";
import { UserOutlined } from "@ant-design/icons";
export const MediaStats = () => {
  const { stats } = useContext(MediaContext);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            name="Total"
            value={stats?.total}
            icon={<IoDocument style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            icon={<MdFirstPage style={{ fontSize: 24 }} />}
            name="First"
            value={new Date(stats?.first).toLocaleDateString(
              "en-US",
              DateFormat
            )}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            name="Last"
            value={new Date(stats?.last).toLocaleDateString(
              "en-US",
              DateFormat
            )}
            icon={<MdLastPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            name="Most authored"
            loading={false}
            icon={
              <Avatar size="large"
                icon={<UserOutlined />}
                src={`${env.fileUpload}${stats?.mostUsed?.profile}`}
              />
            }
            value={stats?.mostUsed?.name}
          />
        </Col>
      </Row>
    </>
  );
};
