import axios from "../api/axios";
import useAuth from "./useAuth";
import useLocalStorage from "./useLocalStorage";

const useLogout = () => {
  const { setAuth } = useAuth();
  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", "");

  const logout = async () => {
    
    try {
      setAuth({});
      const data = JSON.parse(refreshToken);
      
      const response = await axios(`/Account/logout?userEmail=${data.email}`, {
        withCredentials: true,
      });


      data.email ="";
      data.refreshToken ="";

      setRefreshToken(JSON.stringify(data));
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
