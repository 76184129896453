import React, { memo, useContext } from "react";
import WonDealModal from "../WonDealModal";
import { arrayMove } from "@dnd-kit/sortable";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { message } from "antd";
import { CardStatus } from "../../../shared/enums";
import { DealListContext } from "../DealListContext";

const KanbanWonDealModal = memo(
  ({ open, setOpen, setKanbanCards, initialCardPositionRef, dragEvent }) => {
 
    const axios = useAxiosPrivate();
    const {stats, setStats} = useContext(DealListContext)

    function onClose() {
      setKanbanCards((prev) => {
        if(!initialCardPositionRef.current) return prev
        const movedCard = prev.find(
          (card) => card.id === initialCardPositionRef.current.card.id
          );
        const movedCardIndex = prev.indexOf(movedCard);
        prev[movedCardIndex] = initialCardPositionRef.current.card;
        return arrayMove(
          prev,
          movedCardIndex,
          initialCardPositionRef.current.index
          );
        });
        initialCardPositionRef.current = {}
        setOpen(false);
      
    }
    
    const onSubmit = async (e) => {

      if(dragEvent){

        const { delta, active, over } = dragEvent;
        
        const response = await axios.post(`/Deal/UpdateDealPosition`,{
          id: active.data.current.card.id,
          index: active.data.current.sortable.index,
          newStage:CardStatus.Won,
          deals: active.data.current.sortable.items,
          closeDate: e.closeDate,
          totalRevenue: e.revenue,
          reason: e.closedReason 
        });
        
        if(response.data.success){
          setOpen(false);
          
          setStats(prev => ({
            ...prev,
            lost: initialCardPositionRef.current.card.status == CardStatus.Closed ? prev.lost - 1: prev.lost,
            new: initialCardPositionRef.current.card.status == CardStatus.New ? prev.new - 1: prev.new,
            qualifying: initialCardPositionRef.current.card.status == CardStatus.Qualifying ? prev.qualifying - 1: prev.qualifying,
            demoScheduled: initialCardPositionRef.current.card.status == CardStatus.DemoScheduled ? prev.demoScheduled - 1: prev.demoScheduled,
            pendingCommitment: initialCardPositionRef.current.card.status == CardStatus.PendingCommitment ? prev.pendingCommitment - 1: prev.pendingCommitment,
            inNegotiation : initialCardPositionRef.current.card.status == CardStatus.InNegotiation ? prev.inNegotiation - 1: prev.inNegotiation,
            won: prev.won + 1
          }))
        }else{
          message.error(response.data.message)
        }
      }else{
        const response = await axios.post(`/Deal/UpdateDealStatus`, {
          id: initialCardPositionRef.current.card.id,
          newStage: CardStatus.Won,
          closeDate: e.closeDate,
          totalRevenue: e.revenue,
          reason: e.reason
        });

        if(response.data.success){
          setOpen(false);
          setStats(prev => ({
            ...prev,
            lost: initialCardPositionRef.current.card.status == CardStatus.Closed ? prev.lost - 1: prev.lost,
            new: initialCardPositionRef.current.card.status == CardStatus.New ? prev.new - 1: prev.new,
            qualifying: initialCardPositionRef.current.card.status == CardStatus.Qualifying ? prev.qualifying - 1: prev.qualifying,
            demoScheduled: initialCardPositionRef.current.card.status == CardStatus.DemoScheduled ? prev.demoScheduled - 1: prev.demoScheduled,
            pendingCommitment: initialCardPositionRef.current.card.status == CardStatus.PendingCommitment ? prev.pendingCommitment - 1: prev.pendingCommitment,
            inNegotiation : initialCardPositionRef.current.card.status == CardStatus.InNegotiation ? prev.inNegotiation - 1: prev.inNegotiation,
            won: prev.won + 1
          }))
        }else{
          message.error(response.data.message);
        }
      }
    }

    return <WonDealModal open={open} onClose={onClose} onSubmit={onSubmit} />;
  }
);

export default KanbanWonDealModal;
