import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import QueryString from "qs";
import { SearchContext } from "../../../context/SearchContext";
import { EmailTemplateTypes, OrderDirection } from "../../../shared/enums";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import {
  Button,
  Card,
  Col,
  Flex,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import "./Layouts.css";
import env from "../../../env.json";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CardsLoading from "./CardsLoading";
import { FaEye } from "react-icons/fa";

const Layouts = ({openPreview, isForSelect, onUseTemplate}) => {
    const axios = useAxiosPrivate();
  const [page, setPage] = useState(1);
  const perpage = 9;
  const [templates, setTemplates] = useState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { Text, Title } = Typography;
  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTemplates = async () => {
      const response = await axios.get(`/Templates/Email`, {
        params: {
          page: page,
          pageSize: perpage,
          orderBy: "createdAt",
          sortDirection: OrderDirection.Asc,
          type: EmailTemplateTypes.Layout
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setLoading(false);
      setTemplates(response.data.items);
    };

    fetchTemplates();
    return () => controller.abort();
  }, [page]);

  const handleClick = (id) => {
    navigate(`/Templates/Create/${id}`)
  }

  const handleOpenPreview = (e, id) => {
    e.stopPropagation();
    openPreview(id, EmailTemplateTypes.Layout)
  }

  const handleUseTemplate = (e, id) =>{
    e.stopPropagation();
    onUseTemplate(id, EmailTemplateTypes.Layout)
  }

return (
    <div className="main-wrapper">
      {loading ? (
        <CardsLoading num={5}/>
      ) : templates && templates.length > 0 ? (
        <Space direction="vertical" size="large">
          <Row gutter={[12, 12]}>
            {templates.map((c, i) => (
              <Col span={8} key={i}>
                <Card className="no-shadow template-card" size="small" onClick={() => handleClick(c.id)}>
                 
                    <div className="preivew-wrapper">
                      <div className="preview-overlay">
                        {
                          isForSelect ? <>
                          <Flex align="center" justify="center" vertical className="h-100">
                            <Space direction="vertical">
                            <Button block size="large" type="primary" onClick={(e) => handleUseTemplate(e, c.id)}>Use template</Button>
                            <Button block size="large" className="flex-button" icon={<FaEye />} onClick={(e) => handleOpenPreview(e, c.id)}>Preview</Button>
                            </Space>
                          </Flex>
                          </> : 

                        <Flex
                        className="margin-10"
                        align="center"
                        justify="end"
                        >
                          <Button
                            size="large"
                            onClick={(e) => handleOpenPreview(e, c.id)}
                            icon={<MdOutlineZoomOutMap className="icon" />}
                            className="flex-button"
                            ></Button>
                        </Flex>
                          }
                      </div>
                      <img
                        className="img-preview"
                        src={`${env.fileUpload}${c.preview}`}
                      />
                    </div>
                  
                </Card>
                <div className="text-center">

                <Text className="layout-title">
                  {c.name}
                </Text>
                </div>
              </Col>
            ))}
          </Row>
        </Space>
      ) : (
        <NoDataFound
          addText="Add new template"
          title="No email templates avaliable"
          description="You can create templates using the button found on Designs/Email Add desing"
        />
      )}
    </div>
  );
}

export default Layouts