import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { render } from "react-dom";
import { DateFormat } from "../../dateformat";
import env from "../../env.json";
import { UserOutlined } from "@ant-design/icons";
import { MdEmail, MdSms } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import moment from "moment";
import dayjs from "dayjs";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
const CouponDetails = () => {
  const { Title, Text } = Typography;

  const { id } = useParams();

  const axios = useAxiosPrivate();

  const [coupon, setCoupon] = useState();

  const [loading, setLoading] = useState();

  useEffect(async () => {
    setLoading(true);

    const response = await axios.get(`/Coupon/${id}`);

    setCoupon(response.data);
    setLoading(false);
  }, []);

  const columns = [
    {
      title: "User",
      render: (row) => (
        <Flex align="center" justify="start" gap={12}>
          <Avatar
            icon={<UserOutlined />}
            src={`${env.fileUpload}${row.profilePath}`}
            size="large"
          />
          <Text strong>
            {row.spenderName} {row.spenderLastname}
          </Text>
        </Flex>
      ),
    },
    {
      width: 65,
      title: "Type",
      render: (row) => (
        <Flex align="center" justify="center">
          <Text strong>
            {row.balanceType == 1 ? (
              <MdEmail />
            ) : row.balanceType == 2 ? (
              <RiWhatsappFill />
            ) : row.balanceType == 3 ? (
              <MdSms />
            ) : (<FaUsers/> )}
          </Text>
        </Flex>
      ),
    },
    {
      title: "Email",
      render: (row) => <Text strong>{row.spenderEmail}</Text>,
    },
    {
      title: "Company",
      render: (row) => <Text strong>{row.spenderTenant}</Text>,
    },
    {
      title: "Date",
      render: (row) => (
        <Text strong>
          {new Date(row.dateSpend).toLocaleDateString("en-US", DateFormat)}
        </Text>
      ),
    },
  ];

  return (
    <>
      <Row gutter={12}>
        <Col xs={24} md={6}>
          <Card
            title={
              <Flex align="center" justify="start">
                <Title style={{ margin: 0, padding: 0 }} level={4}>
                  Coupon details
                </Title>
              </Flex>
            }
          >
            {!loading && <CouponEditableDetails data={coupon} />}
          </Card>
        </Col>
        <Col xs={24} md={18}>
          <Space direction="vertical" className="w-100">
            <Card className="zero-margin-padding no-body-card end-to-end-header"
              title={
                <Flex align="center" justify="space-between">
                  <Title style={{ margin: 0 }} level={4}>
                    All Usages ({coupon?.noUsages ?? 0})
                  </Title>
                </Flex>
              }
            ></Card>

            <Card className="zero-margin-padding">
              <Table
                columns={columns}
                dataSource={coupon?.usages}
                pagination={false}
              />
            </Card>
          </Space>
        </Col>
      </Row>
    </>
  );
};

const CouponEditableDetails = ({ data }) => {
  const [form] = Form.useForm();
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(() => false);

  const axios = useAxiosPrivate();

  const [worksOnAudience, setWorksOnAudience] = useState(() => false);
  useEffect(() => {
    form.setFieldValue("name", data?.name);
    form.setFieldValue("code", data?.code);
    form.setFieldValue("maxUsages", data?.maxUsages);
    form.setFieldValue("validTo", dayjs(data?.validTo));
    form.setFieldValue("value", data?.value);
    form.setFieldValue("worksWithAudience", data?.worksOnAudience)
    setWorksOnAudience(data?.worksOnAudience);

    if (data?.worksOnAudience) {
      form.setFieldValue("AudienceCouponLifetime", data.audienceCouponLifetime);
    }
  }, []);

  const reset = () => {
    form.resetFields();
  };

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.put(`/Coupon`, { id: data.id, ...e });

    if (response.data.success) {
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Form
        onFinish={submit}
        form={form}
        initialValues={{
          name: data?.name,
          code: data?.code,
          maxUsages: data?.maxUsages,
          validTo: dayjs(data?.validTo),
          value: data?.value,
        }}
      >
        <Space direction="vertical" className="w-100" size={0}>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Name</Text>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
                {
                  max: 100,
                  message: "Max length for name is 100",
                },
              ]}
            >
              <Input
                size="large"
                count={{
                  show: true,
                  max: 100,
                }}
              />
            </Form.Item>
          </Space>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Code</Text>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: "Code is required",
                },
                {
                  max: 124,
                  message: "Max length for code is 124",
                },
              ]}
            >
              <Input
                size="large"
                count={{
                  show: true,
                  max: 124,
                }}
              />
            </Form.Item>
          </Space>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Max usages</Text>
            <Form.Item
              name="maxUsages"
              rules={[
                {
                  type: "number",
                  min: 0,
                  message: "Value must be at least 0!",
                },
              ]}
            >
              <InputNumber size="large" className="w-100" />
            </Form.Item>
          </Space>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Valid to</Text>
            <Form.Item name="validTo">
              <DatePicker
                size="large"
                className="w-100"
                style={{ borderRadius: 15 }}
              />
            </Form.Item>
          </Space>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Value</Text>
            <Form.Item
              name="value"
              rules={[
                {
                  required: true,
                },
                {
                  type: "number",
                  min: 0,
                  message: "Value must be at least 0!",
                },
              ]}
            >
              <InputNumber size="large" className="w-100" />
            </Form.Item>
          </Space>

          <Space direction="vertical" size={0}>
            <Form.Item name="worksWithAudience" valuePropName="checked">
              <Checkbox
                checked={worksOnAudience}
                onChange={(e) => setWorksOnAudience(e.target.checked)}
              >
                Works with audiences
              </Checkbox>
            </Form.Item>
          </Space>

          {worksOnAudience && (
            <Space className="w-100" size={0} direction="vertical">
              <Text>Audience valid timespan</Text>
              <Form.Item name="AudienceCouponLifetime">
                <Select
                  options={[
                    {
                      label:"One month",
                      value:1
                    },
                    {
                      label:"Six months",
                      value:2
                    },
                    {
                      label:"One year",
                      value:3
                    },
                    {
                      label:"Forever",
                      value: 4
                    }
                  ]}
                  size="large"
                  className="w-100"
                  style={{ borderRadius: 20 }}
                />
              </Form.Item>
            </Space>
          )}

          <Flex style={{ marginTop: 10 }} align="center" gap={12}>
            <Button onClick={() => reset()} size="large">
              Reset defaults
            </Button>
            <Button
              onClick={() => form.submit()}
              loading={loading}
              size="large"
              type="primary"
              block
            >
              Save changes
            </Button>
          </Flex>
        </Space>
      </Form>
    </>
  );
};

export default CouponDetails;
