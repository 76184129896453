import { Children, createContext } from "react";
import { useState } from "react";

export const ImportContactContext = createContext({
  step: 1,
  setStep: () => {},

  fileUploadStep: {
    confirmed: false,
    file: null,
    data: [],
    columns: [],
    lines: 0
  },
  setFileUploadStep: () => {},

  dataMapStep: [],
  setDataMapStep: () => {},

  dataMapStepConfirmed: false,
  setDataMapStepConfirmed:() => {},

  tagsStep:{},
  setTagsStep: () => {},

  fileContainsHeader: false,
  setFileContainsHeader: () => {},

  doNotImportInvalid: false,
  setDoNotImportInvalid: () => {}
});

export const ImportContactContextProvider = ({ children }) => {
  const [fileContainsHeader, setFileContainsHeader] = useState(() => false);
  const [step, setStep] = useState(1);
  const [dataMapStep, setDataMapStep] = useState([])

  const [doNotImportInvalid, setDoNotImportInvalid] = useState(false)

  const [fileUploadStep, setFileUploadStep] = useState({
    confirmed: false,
    file: null,
    data: [],
    columns: [],
    lines: 0
  });

  const [dataMapStepConfirmed, setDataMapStepConfirmed] = useState(false)

  const [tagsStep, setTagsStep] = useState();

  return (
    <ImportContactContext.Provider
      value={{
        step,
        setStep,

        fileUploadStep,
        setFileUploadStep,

        dataMapStep,
        setDataMapStep,

        dataMapStepConfirmed,
        setDataMapStepConfirmed,

        tagsStep,
        setTagsStep,

        fileContainsHeader,
        setFileContainsHeader,
        
        doNotImportInvalid,
        setDoNotImportInvalid
      }}
    >
      {children}
    </ImportContactContext.Provider>
  );
};
