import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Typography,
  Grid,
  Pagination,
} from "antd";
import { useContext, useState } from "react";
import { DateFormat } from "../../dateformat";
import env from "../../env.json";
import CompanyOptions from "./CompanyOptions";
import TableLoading from "../../shared/TableLoading";
import AddCompanyModal from "../deals/Modals/AddCompanyModal";
import UpdateComapny from "./UpdateCompany";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../shared/NoDataFound";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { CompanyListContext } from "./CompanyListContext";
import CompanyStats from "./CompanyStats";
import CompanyAuthorFilter from "./Filters/CompanyAuthorFilter";
import CompanyFromDateFilter from "./Filters/CompanyFromDateFilter";
import CompanyToDateFilter from "./Filters/CompanyToDateFilter";
import CompanyOrderByFilter from "./Filters/CompanyOrderByFilter";
import CompanyOrderDirectionFilter from "./Filters/CompanyOrderDirectionFilter";
import ButtonIcon from "../../shared/ButtonIcon";
import { AuthorContext } from "../../context/AuthorsContext";
import { IoClose } from "react-icons/io5";
import { UserOutlined } from "@ant-design/icons";

const { useBreakpoint } = Grid;

const CompanyList = () => {
  const { authors } = useContext(AuthorContext);
  const { Title, Text } = Typography;

  const {
    page,
    setPage,
    setStats,
    stats,
    filters,
    setFilters,
    showFilters,
    setShowFilters,
    companies,
    setCompanies,
    loading,
  } = useContext(CompanyListContext);

  const [showAdd, setShowAdd] = useState(false);
  const navigate = useNavigate();

  const screens = useBreakpoint();

  const [showEdit, setShowEdit] = useState(false);
  const [current, setCurrent] = useState();
  const perPage = 7;

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, all: prev.all - 1 }));

    setCompanies((prev) => prev.filter((c) => c.id != id));
  };

  const onAdd = (e) => {
    setStats((prev) => ({ ...prev, all: prev?.all + 1 }));

    setCompanies((prev) => [...prev, e]);
  };

  const onUpdate = (e) => {
    setCompanies((prev) =>
      prev.map((c) =>
        c.id == e.id
          ? {
              ...c,
              name: e.name,
              phone: e.phone,
              owners: e.owners,
              industry: e.industry,
              website: e.website,
            }
          : c
      )
    );
  };
  return (
    <>
      <Space direction="vertical" size={24} className="w-100">
        <CompanyStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Companies
              </Title>
              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { gap: "10px", width: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  className="flex-button new-buttons"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filter
                  {!showFilters ? (
                    <ButtonIcon icon={<MdFilterAlt />} background={"#f1f1f1"} size="large"/>
                  ) : (
                    <ButtonIcon size="large"
                      background={"#f1f1f1"}
                      icon={<MdFilterAltOff />}
                    />
                  )}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="flex-button new-buttons"
                  onClick={() => setShowAdd(true)}
                >
                  New company
                  <ButtonIcon size="large"
                    icon={<FiPlus style={{ fontSize: 20 }} />}
                    background={"#5a6bc5"}
                  />
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <CompanyAuthorFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <CompanyFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <CompanyToDateFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item">
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>
          <Space>
            <CompanyOrderByFilter />
            <CompanyOrderDirectionFilter />
            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space direction="vertical" className="w-100">
          <Card size="small">
            <Row>
              <Col span={3}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={4}>
                <Text className="column-header">Deals</Text>
              </Col>
              <Col span={3}>
                <Text className="column-header">Owners</Text>
              </Col>
              <Col span={3}>
                <Text className="column-header">Industry</Text>
              </Col>
              <Col span={3}>
                <Text className="column-header">Phone</Text>
              </Col>
              <Col span={2}>
                <Text className="column-header">Date</Text>
              </Col>
              <Col span={4}>
                <Text className="column-header">Author</Text>
              </Col>
              <Col span={2}>
                <Flex align="center" justify="end">
                  <Text className="column-header">Tool</Text>
                </Flex>
              </Col>
            </Row>
          </Card>
          {loading ? (
            <TableLoading />
          ) : !companies || companies.length == 0 ? (
            <Card size="small">
              <NoDataFound
                addText="New Company"
                title="You have not created any companies yet"
                description="Click the new company button on Dealing/Companies to create a new company"
                onAdd={() => setShowAdd(true)}
              />
            </Card>
          ) : (
            <>
              {companies.map((c, i) => (
                <Card key={i} size="small">
                  <Row>
                    <Col span={3} className="center-table-column">
                      <Text>{c.name}</Text>
                    </Col>
                    <Col span={4} className="center-table-column">
                      <div>
                        {c.deals?.map((d, ind) => (
                          <Text
                            key={ind}
                            type="link"
                            onClick={() => navigate(`/Deals/Details/${c.id}`)}
                          >
                            {d.name}
                            {ind < c.deals?.length - 1 && ", "}
                          </Text>
                        ))}

                        {c.noDeals > 2 && (
                          <Text type="link">, +{c.noDeals - 2} more..</Text>
                        )}

                        {c.noDeals == 0 && <Text>-</Text>}
                      </div>
                    </Col>
                    <Col span={3} className="center-table-column">
                      <Avatar.Group maxCount={2}>
                        {c.owners?.map((d, ind) => (
                          <Avatar
                            key={d.ind}
                            src={`${env.fileUpload}${d.profileUrl}`}
                          >
                            {d?.firstname[0]}
                            {d?.lastname[0]}
                          </Avatar>
                        ))}
                      </Avatar.Group>
                    </Col>
                    <Col span={3} className="center-table-column">
                      <Text strong>{c.industry ?? "-"}</Text>
                    </Col>
                    <Col span={3} className="center-table-column">
                      <Text strong>{c.phone ?? "-"}</Text>
                    </Col>
                    <Col span={2} className="center-table-column">
                      <Text>
                        {new Date(c.createdAt).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}
                      </Text>
                    </Col>
                    <Col span={4} className="center-table-column">
                      <Flex align="center" justify="start" gap={6}>
                        <Avatar
                          src={`${env.fileUpload}${c.authorProfile}`}
                          icon={<UserOutlined style={{ color: "#5a6bc5" }} />}
                        />
                        <Text style={{ color: "#707070" }}>
                          {c.authorUserName}
                        </Text>
                      </Flex>
                    </Col>
                    <Col span={2}>
                      <Flex align="center" justify="end">
                        <CompanyOptions
                          onDelete={onDelete}
                          id={c.id}
                          setCurrent={setCurrent}
                          setShowEditing={setShowEdit}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Card size="small">
                <Flex align="center" justify="end">
                  <Pagination
                    current={page}
                    pageSize={perPage}
                    total={stats?.all}
                    onChange={(p) => setPage(p)}
                  />
                </Flex>
              </Card>
            </>
          )}
        </Space>
      </Space>

      <AddCompanyModal open={showAdd} setOpen={setShowAdd} onAdd={onAdd} />

      {showEdit && (
        <UpdateComapny
          show={showEdit}
          setShow={setShowEdit}
          id={current}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default CompanyList;
