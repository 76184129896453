import { Button, Flex, Result } from "antd";
import { useNavigate } from "react-router-dom";
const Missing = () => {
  const navigate = useNavigate();

  return (
    <Flex style={{width:"100%", height:"100vh"}} align="center" justify="center">

  <Result
    status="404"
    title="TAPZAP 404 - NOT FOUND"
    subTitle="Sorry, the page you visited has been moved or never existed in the first place."
    extra={<Button className="primary-gradient-background" size="large" type="primary" onClick={() => navigate(-1)}>Go back</Button>}
    />
    </Flex>
  );
};

export default Missing;
