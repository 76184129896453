import { Button, Dropdown, message } from "antd";
import { BsCloudDownloadFill, BsFillTrash3Fill, BsTrashFill } from "react-icons/bs";
import { IoMdArrowDropdown, IoMdMore } from "react-icons/io";
import { FaRegCopy } from "react-icons/fa";
import env from "../../env.json";
import CustomButton from "../../shared/CustomButton";
import { IoEllipsisHorizontal } from "react-icons/io5";

const MediaItemDropDown = ({ id, name, onDelete, onDownload }) => {
  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      message.success("Download link copied to clipborad!");
    } catch (err) {
      message.error(err);
    }
  };

  const items = [
    {
      label: "Download media",
      key: "1",
      icon: <BsCloudDownloadFill />,
      onClick: () => onDownload(id),
    },
    {
      label: "Copy download URL",
      key: "2",
      icon: <FaRegCopy />,
      onClick: () => handleCopy(`${env.fileUpload}${name}`),
    },
    {
      label: "Delete selected media",
      key: "3",
      danger: true,
      icon: <BsFillTrash3Fill />,
      onClick: () => onDelete(id),
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
         type="text"
         shape="circle"
         className="flex-button"
         icon={<IoEllipsisHorizontal style={{ fontSize: 26, color:"#7e7e7e" }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default MediaItemDropDown;
