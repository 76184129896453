import { useContext, useState } from "react";
import { Flex, Select, Typography } from "antd";
import { CampaignContext } from "../CampaignContext";
import { CampaignStatusType } from "../../../shared/enums";
import { MdArrowDropDown } from "react-icons/md";

const CampaignStatusSelect = ({ onChanged }) => {
  const { filters, setFilters } = useContext(CampaignContext);

  const { Text } = Typography;
  const [optons, setOptions] = useState([
    {
      value: CampaignStatusType.NotStarted,
      label: "Not started",
    },
    {
      value: CampaignStatusType.OnGoing,
      label: "On going",
    },
    {
      value: CampaignStatusType.Finished,
      label: "Finished",
    },
    {
      value: CampaignStatusType.Invalid,
      label: "Invalid",
    },
  ]);

  const handleChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      status: e,
    }));
  };
  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>Status</Text>
      <Select
        size="large"
        style={{ flexGrow: 1 }}
        className="order-by-select"
        mode="multiple"
        defaultValue={filters?.status}
        maxTagCount="responsive"
        placeholder="Select statuses to filter"
        onChange={(e) => handleChange(e)}
        options={optons}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
    </Flex>
  );
};

export default CampaignStatusSelect;
