import { Button, Card, Col, Flex, message, Row, Space, Typography } from "antd";
import "./ImportTemplate.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ImportTemplate = () => {
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();

  const [data, setData] = useState();

  const { id } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      const response = await axios.get(`/Templates/Email/Shared/${id}`, {
        signal: signal,
      });

      if (response.data.success) {
        setData(response.data);
      } else {
        message.error(response.data.message);
      }
    };

    fetch();

    return () => controller.abort();
  }, []);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async () => {

    setLoading(true)
    const response = await axios.post(`/Templates/Email/Import`,{
        importId: id
    });

    if(response.data.success){
        if(response.data.simple){
            navigate(`/Templates/EditSimpleEmail/${response.data.id}`)
        }else{
            navigate(`/Templates/Edit/${response.data.id}`)

        }
    }else{
        message.error(response.data.message);
    }
    setLoading(false)
  }
  return (
    <>
      <Flex align="center" justify="center" vertical>
        <Space direction="vertical" size={"large"} align="center">
          <Space direction="vertical" size={0} align="center">
            <Title className="zero-margin-padding">
              Do you want to import this template?
            </Title>
            <Text>
              By importing this template, you'll be able to use and/or modify it
              at your convenience. tmeplate importer
            </Text>
          </Space>
          <Button onClick={() => submit()} loading={loading} size="large" type="primary">
            Import this template
          </Button>

          <Row gutter={[30,30]}>
            <Col span={16}>
              <div className="browser">
                <div className="browser-header">
                  <div className="borwser-search"></div>
                  <div className="browser-header-icons">
                    <div className="browser-icon"></div>
                    <div className="browser-icon"></div>
                    <div className="browser-icon"></div>
                  </div>
                </div>
                <div className="borwser-header-seperator"></div>

                <iframe className="browser-frame" srcDoc={data?.html}></iframe>
              </div>
            </Col>
            <Col span={8}>
                <div class="phone">
                    <div className="phone-wrapper">

                <iframe className="phone-frame" srcDoc={data?.html}></iframe>
                    </div>
                </div>
            </Col>
          </Row>
        </Space>
      </Flex>
    </>
  );
};

export default ImportTemplate;
