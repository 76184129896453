import { Button, Col, Flex, message, Modal, Row, Space, Typography } from "antd";
import ProfileAvatar from "../../../components/ProfileAvatar";
import "./CallContactModal.css"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
const CallContactModal = ({ open, setOpen, fullName, id, email }) => {
  const { Text, Title } = Typography;
    
  const [loading, setLoading] = useState();

  const navigate = useNavigate();
    const axios = useAxiosPrivate();
    
  const submit = async() => {
    setLoading(true);

    const response = await axios.post("/Meeting-Room/Create", {
        contactId: id
    });

    if(response.data.success){
        navigate(`/call/${response.data.roomId}`)
    }else{
        message.error(response.data.message);
    }
    setLoading(false)
  }

  return (
    <>
      <Modal width={300} open={open} onCancel={() => setOpen(false)} footer={false}>
        <Flex align="center" justify="center" vertical gap={6}>
          <ProfileAvatar name={fullName} size={100} font={38} />
          <Space direction="vertical" size={0} align="center">
            <Title className="zero-margin-padding" level={3}>
              {fullName}
            </Title>

            <Text className="bold">{email}</Text>
          </Space>

        <Text className="text-center call-description-text">You're about to start a video call with your contact—get ready for a seamless face-to-face connection.</Text>
          <Flex gap={6} className="w-100 button-margin-top">
            <Button onClick={() => setOpen(false)} size="large" className="w-100 bold">
              Cancel
            </Button>
            <Button loading={loading} onClick={submit} type="primary" size="large" className="w-100 bold">
              Arrange Call
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default CallContactModal;
