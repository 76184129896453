import { Button, Card, Drawer, Flex, message, Space, Typography } from "antd";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { SmartTextType } from "../../../shared/enums";
import { MdOutlineClose } from "react-icons/md";

const UseAiDrawer = ({ open, setOpen, text, onSave }) => {
  const { Text } = Typography;

  const [tempText, setTempText] = useState();

  const submit = () => {
    onSave(tempText);
    reset();
  };

  const axios = useAxiosPrivate();

  const [fixLoading, setFixLoading] = useState(false);
  const [expandLoading, setExpandLoading] = useState(false);
  const [summarizeLoading, setSummarizeLoading] = useState(false);
  const [rephraseLoading, setRephraseLoading] = useState(false);
  const [friendlyLoading, setFriendlyLoading] = useState(false);
  const [formalLoading, setFormalLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);

  const [summarizedText, setSummarizedText] = useState();
  const [summarizedShow, setSummarizedShow] = useState(false);

  const toggleLoading = (type, load) => {
    if (type === SmartTextType.Fix) {
      setFixLoading(load);
    } else if (type === SmartTextType.Expand) {
      setExpandLoading(load);
    } else if (type === SmartTextType.Summarize) {
      setSummarizeLoading(load);
    } else if (type === SmartTextType.Rephrase) {
      setRephraseLoading(load);
    } else if (type === SmartTextType.Friendly) {
      setFriendlyLoading(load);
    } else if (type === SmartTextType.Formal) {
      setFormalLoading(load);
    }
  };

  const generate = async (type) => {
    setBlocked(true);

    toggleLoading(type, true);

    var response = await axios.post("/SmartThings/generate", {
      type: type,
      text: tempText ? tempText : text,
    });

    if (response.data.success) {
      if (type == SmartTextType.Summarize) {
        setSummarizedText(response.data.text);
        setSummarizedShow(true);
      } else {
        setTempText(response.data.text);
      }
    } else {
      message.error(response.data.message);
    }
    setBlocked(false);
    toggleLoading(type, false);
  };

  const reset = () => {
    setTempText();
    setFixLoading();
    setExpandLoading();
    setSummarizeLoading();
    setRephraseLoading();
    setFriendlyLoading();
    setFormalLoading();
    setBlocked();
    setSummarizedText();
    setSummarizedShow();
  };
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  return (
    <Drawer
      destroyOnClose
      open={open}
      onClose={handleClose}
      title="Use AI on your content"
    >
      <Flex
        vertical
        className="w-100 h-100"
        align="center"
        justify="space-between"
      >
        <Space className="w-100" direction="vertical" size="large">
          <Space className="w-100" direction="vertical" size={0}>
            <Text className="bold">Initial Text</Text>
            <Card size="small" className="w-100 no-shadow">
              <p
                className="zero-margin-padding"
                dangerouslySetInnerHTML={{ __html: text }}
              ></p>
            </Card>
          </Space>

          {tempText && (
            <Space className="w-100" direction="vertical" size={0}>
              <Text className="bold">New Text</Text>
              <Card size="small" className="no-shadow">
                <p
                  className="zero-margin-padding"
                  dangerouslySetInnerHTML={{ __html: tempText }}
                ></p>
              </Card>
            </Space>
          )}

          {summarizedShow && (
            <Space className="w-100" direction="vertical" size={0}>
              <Flex align="center" justify="space-between">
                <Text className="bold">Summarized Text</Text>

                <Button
                  icon={<MdOutlineClose />}
                  type="text"
                  onClick={() => setSummarizedShow(false)}
                ></Button>
              </Flex>
              <Card size="small" className="no-shadow">
                <p
                  className="zero-margin-padding"
                  dangerouslySetInnerHTML={{ __html: summarizedText }}
                ></p>
              </Card>
            </Space>
          )}

          <Space direction="vertical" size={0} className="w-100">
            <Text className="bold">AI Tools</Text>

            <Flex align="center" justify="start" gap={6} wrap>
              <Button
                disabled={blocked}
                loading={fixLoading}
                onClick={() => generate(SmartTextType.Fix)}
                size="large"
              >
                Fix Spelling & Grammar
              </Button>
              <Button
                disabled={blocked}
                loading={expandLoading}
                onClick={() => generate(SmartTextType.Expand)}
                size="large"
              >
                Expand Text
              </Button>
              <Button
                disabled={blocked}
                loading={summarizeLoading}
                onClick={() => generate(SmartTextType.Summarize)}
                size="large"
              >
                Summarize Text
              </Button>
              <Button
                disabled={blocked}
                loading={rephraseLoading}
                onClick={() => generate(SmartTextType.Rephrase)}
                size="large"
              >
                Rephrase Text
              </Button>
              <Button
                disabled={blocked}
                loading={friendlyLoading}
                onClick={() => generate(SmartTextType.Friendly)}
                size="large"
              >
                Make it Friendly
              </Button>
              <Button
                disabled={blocked}
                loading={formalLoading}
                onClick={() => generate(SmartTextType.Formal)}
                size="large"
              >
                Make it Formal
              </Button>
            </Flex>
          </Space>
        </Space>
        <Flex align="center" justify="end" gap={6} className="w-100">
          <Button size="large" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="large" type="primary" onClick={submit}>
            Approve changes
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default UseAiDrawer;
