import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Modal,
  Row,
  Space,
  Switch,
  TimePicker,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import useDealsDetailsCtx from "../../../hooks/useDealsDetailsCtx";
import moment from "moment";
import BrowserNotification from "../../../shared/BrowserNotification";
import TaskReminderSelection from "../TaskReminderSelection";

const CreateFollowUpTaskModal = ({ open, setOpen, id, onCreated }) => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(() => false);
  const [reminder, setReminder] = useState(() => false);

  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.post("/DealTasks/followup", {
      parentId: id,
      dueDate: e.dueDate,
      dueTime: e.dueTime,
      reminder: e.reminder,
      taskReminderType: e.taskReminderType
    });

    if (response) {
      onCreated(response)

      setOpen(false);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Title level={4} style={{ margin: 0, padding: 0 }}>
            Create follow-up task
          </Title>
        }
        footer={
          <Flex align="center" justify="end" gap={12}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              loading={loading}
              onClick={() => form.submit()}
              type="primary"
            >
              Save changes
            </Button>
          </Flex>
        }
      >
        <Divider style={{ margin: "5px 0px" }} />

        <Form onFinish={onSubmit} form={form}>
          <Row gutter={12}>
            <Col md={10}>
              <Space direction="vertical" className="w-100" size={0}>
                <Text>Due Date</Text>
                <Form.Item name={"dueDate"} style={{ flex: 1 }}>
                  <DatePicker
                    size="large"
                    className="w-100"
                    style={{ borderRadius: 15 }}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col md={10}>
              <Space direction="vertical" className="w-100" size={0}>
                <Text>Time</Text>
                <Form.Item name={"dueTime"} style={{ flex: 1 }}>
                  <TimePicker
                    style={{ width: "100%", borderRadius: 20 }}
                    size="large"
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col md={4}>
              <Space direction="vertical" size={0} className="w-100">
                <Text>Reminder</Text>
                <Form.Item name="reminder" valuePropName="checked">
                  <Switch onChange={(e) => setReminder(e)} />
                </Form.Item>
              </Space>
            </Col>
          </Row>
          {reminder && (
            <Space direction="vertical" className="w-100">
              <TaskReminderSelection name="taskReminderType"/>
              <BrowserNotification />
            </Space>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateFollowUpTaskModal;
