import { Button, Flex, Select, Typography } from "antd";
import { TaskStatus } from "../../../shared/enums";
import getTaskTypeIcon from "../../../shared/TaskTypeIcon";
import { useContext } from "react";
import { TaskContext } from "../TaskContext";

const TaskStatusFilter = () => {
  const { Text } = Typography;

  const {filters, setFilters} = useContext(TaskContext)

  const taskTypes = [
    {
      value: TaskStatus.ToDo,
      label: (
        <Flex align="center" justify="start" gap={6}>
          {getTaskTypeIcon(TaskStatus.ToDo)} To do
        </Flex>
      ),
    },
    {
        value: TaskStatus.Call,
        label: (
          <Flex align="center" justify="start" gap={6}>
            {getTaskTypeIcon(TaskStatus.Call)} Call
          </Flex>
        ),
      },
      {
        value: TaskStatus.Deadline,
        label: (
          <Flex align="center" justify="start" gap={6}>
            {getTaskTypeIcon(TaskStatus.Deadline)} Deadline
          </Flex>
        ),
      },
      {
        value: TaskStatus.Email,
        label: (
          <Flex align="center" justify="start" gap={6}>
            {getTaskTypeIcon(TaskStatus.Email)} Email
          </Flex>
        ),
      },
      {
        value: TaskStatus.Lunch,
        label: (
          <Flex align="center" justify="start" gap={6}>
            {getTaskTypeIcon(TaskStatus.Lunch)} Lunch
          </Flex>
        ),
      },
      {
        value: TaskStatus.Meeting,
        label: (
          <Flex align="center" justify="start" gap={6}>
            {getTaskTypeIcon(TaskStatus.Meeting)} Meeting
          </Flex>
        ),
      }
  ];
  return (
    <>
      <Text>Type</Text>
      <Select
        options={taskTypes}
        size="large"
        defaultValue={filters?.types}
        value={filters?.types}
        onChange={(e) =>setFilters(prev => ({...prev, types: e})) }
        maxTagCount="responsive"
        placeholder="Select task types"
        style={{ width: "100%" }}
        mode="multiple"
      />
    </>
  );
};

export default TaskStatusFilter;
