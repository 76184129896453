import { Outlet } from "react-router-dom";
import { TaskContextProvider } from "./TaskContext";

const TaskLayout = () => {
    return <>
        <TaskContextProvider>
            <Outlet/>
        </TaskContextProvider>
    </>
}

export default TaskLayout;