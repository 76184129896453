import { Button, Flex, Modal, Typography, Input, Space, message } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const SendEmailModal = ({ open, setOpen, email, contactId }) => {
  const { Text, Title } = Typography;
  
  const [subject, setSubject] = useState();
  const [text, setText] = useState();
  const axios = useAxiosPrivate()
  const [loading, setLoading] = useState();

  const submit = async () => {
    setLoading(true);

    const response = await axios.post('/Contact/Send-email', {
      subject: subject,
      html: text,
      contactId: contactId
    })

    if(response.data.success){
      message.success("Message sent");
      setOpen(false)
    }else{
      message.error(response.data.message);
    }
    setLoading(false)
  }
  const handleEditorChange = (e) => {
    setText(e)
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      width={1000}
      centered
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button size="large" onClick={() => setOpen(false)}>Cancel</Button>
          <Button size="large" type="primary" onClick={() => submit()}>
            Send Email
          </Button>
        </Flex>
      }
    >
      <Space className="w-100" direction="vertical">
        <Space className="w-100" direction="vertical" size={0}>
          <Text className="thick-lable">Subject</Text>
          <Input size="large" placeholder="Enter subject" className="w-100" value={subject} onChange={(e) => setSubject(e.target.value)} />
        </Space>

        <Space className="w-100" direction="vertical" size={0}>
          <Text className="thick-lable">Message</Text>
          <Editor initialValue="" onEditorChange={handleEditorChange}
            apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
            init={{
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
            }}
          />
        </Space>
      </Space>
    </Modal>
  );
};

export default SendEmailModal;
