import { createContext, useState } from "react";
import useDebounce from "../hooks/useDebounce";

export const SearchContext = createContext({
    search:"",
    setSearch: () => {}
})

export const SearchContextProvider = ({children}) => {
    const [search, setSearch] = useState(() => "");

    const debouncedValue = useDebounce(search);
    return (    
        <SearchContext.Provider value={{
            debouncedValue,
            setSearch
        }}>
            {children}
        </SearchContext.Provider>
    )
}