import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  message,
  Space,
  Typography,
  Upload,
  Flex,
  Popover,
  Input,
  Checkbox,
  InputNumber,
  Row,
  Col,
  Card,
} from "antd";
import { useState } from "react";
import { BsFiletypeCsv } from "react-icons/bs";
import Papa from "papaparse";
import { CopyOutlined } from "@ant-design/icons";
const { Dragger } = Upload;

const UploadCsv = ({ file, setFile, headers, setHeaders, hasHeader, setHasHeaders }) => {
  const { Text, Title } = Typography;
  const [totalImported, setTotalImported] = useState(0);
  const [fileList, setFileList] = useState([]);
 
  const props = {
    name: "file",
    accept: ".csv",
    multiple: false,
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    onChange(info) {
      const selectedFile = info?.fileList[0]?.originFileObj;

      if (selectedFile) {
        Papa.parse(selectedFile, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,

          complete: (result) => {
            const headers = result.meta.fields;
            setHeaders(
              headers.map((c, ind) => ({
                id: ind,
                canBeSend: false,
                selected: false,
                name: c,
                value: null,
                ind: ind
              }))
            );
            setTotalImported(result.data.length);
          },
        });

        setFile(selectedFile);
      } else {
        setFile(null);
        setTotalImported(0);
      }

      const { status } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  const copy = () => {
    message.success("Csv template copied");
    navigator.clipboard.writeText(
      "Csv header\njohn.doe@domain.com\njane.doe@domain.com\n..."
    );
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Dragger {...props}>
          <Space style={{ width: "100%" }} direction="vertical" size={0}>
            <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
              <InboxOutlined />
            </p>
            <Text className="ant-upload-text">
              Click or drag file to this area to upload
            </Text>
            <Text className="text-light">
              Support only for a single csv file.
            </Text>
          </Space>
        </Dragger>

        {headers&&
        
        <Card className="shadowless">
          <Row style={{ width: "100%" }} gutter={12}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            
                <Space
                  direction="vertical"
                  size={0}
                  align="center"
                  style={{ width: "100%" }}
                >
                  <Title style={{ margin: 0 }} level={4}>
                    1
                  </Title>
                  <Text className="text-light">Firstname</Text>
                </Space>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
              
                <Space
                  direction="vertical"
                  size={0}
                  align="center"
                  style={{ width: "100%" }}
                >
                  <Title style={{ margin: 0 }} level={4}>
                    2
                  </Title>
                  <Text className="text-light">Lastname</Text>
                </Space>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Space
                  direction="vertical"
                  size={0}
                  align="center"
                  style={{ width: "100%" }}
                >
                  <Title style={{ margin: 0 }} level={4}>
                    3
                  </Title>
                  <Text className="text-light">Email</Text>
                </Space>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
              
                <Space
                  direction="vertical"
                  size={0}
                  align="center"
                  style={{ width: "100%" }}
                >
                  <Title style={{ margin: 0 }} level={4}>
                    4
                  </Title>
                  <Text className="text-light">Phone</Text>
                </Space>
            </Col>
          </Row>

        <Flex style={{ width: "100%" }} gap={12} wrap="wrap">
          {headers &&
            headers.map((c, ind) => (
             
                <InputNumber
                  min={1}
                  key={`n_${ind}`}
                  style={{ marginTop: 12 }}
                  max={4}
                  value={headers[ind].value}
                  onChange={(e) =>
                    setHeaders((prev) =>
                      prev.map((item) =>
                        item.id === c.id ? { ...item, value: e } : item
                      )
                    )
                  }
                  disabled={!c.canBeSend}
                  addonBefore={
                    <Checkbox
                      disabled={
                        headers.filter((c) => c.canBeSend).length == 4 &&
                        !c.canBeSend
                      }
                      onChange={(d) =>
                        setHeaders((prev) =>
                          prev.map((item) =>
                            item.id === c.id
                              ? { ...item, canBeSend: d.target.checked }
                              : item
                          )
                        )
                      }
                    />
                  }
                  addonAfter={
                    <Text style={{ textTransform: "capitalize" }}>
                      {c.name}
                    </Text>
                  }
                />
            ))}
        </Flex>
        </Card>}

        <Flex align="center" justify="space-between">
          <Text>Found {totalImported} possible contacts</Text>
        
        <Checkbox value={hasHeader} onChange={(e) => setHasHeaders(e.target.checked) }>File contains header</Checkbox>
{/*
          <Popover
            content={
              <>
                <Space direction="vertical">
                  <Flex align="center" justify="space-between">
                    <Title style={{ margin: 0 }} level={5} mark>
                      Csv header
                    </Title>

                    <Button
                      type="link"
                      onClick={copy}
                      icon={<CopyOutlined />}
                      size="small"
                    />
                  </Flex>
                  <Text>
                    john.doe@domain.com <Text mark>\n</Text>
                  </Text>
                  <Text>
                    jane.doe@domain.com <Text mark>\n</Text>
                  </Text>
                  <Text>...</Text>
                </Space>
              </>
            }
            trigger="click"
          >
            <Button type="link" icon={<BsFiletypeCsv />}>
              View csv sample
            </Button>
          </Popover>
        */}
        </Flex>
      </Space>
    </>
  );
};

export default UploadCsv;
