import { Button, Card, Divider, Flex, Space, Typography } from "antd";
import background from "../../../assets/images/whatsapp_background.png";
import profile from "../../../assets/images/whatsapp_profilepicture.png";
import { PiMicrophoneFill } from "react-icons/pi";
import {
  MdOutlineEmojiEmotions,
  MdCameraAlt,
  MdContentCopy,
} from "react-icons/md";
import { IoMdArrowBack, IoMdMore } from "react-icons/io";
import { GrAttachment } from "react-icons/gr";
import { RiShareBoxFill } from "react-icons/ri";
import { FaPhoneAlt, FaListUl } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import { FaCirclePlay } from "react-icons/fa6";
import { FaRegImage } from "react-icons/fa6";
import { IoDocument, IoLocationSharp } from "react-icons/io5";
import { useContext, useEffect, useState } from "react";
import CreateWhatsAppTemplate from "./CreateWhatsAppTemplate";
import { WhatsAppTemplateContext } from "../../../context/WhatsAppTemplateContext";
const MessagePreview = () => {
  const [body, setBody] = useState();
  const { template } = useContext(WhatsAppTemplateContext);

  const { Text, Title } = Typography;

  useEffect(() => {
    const contentState = template?.body?.getCurrentContent();
    const text = contentState?.getPlainText();
    setBody(text);
  }, [template?.body]);

  function formatDate() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date();
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${months[monthIndex]} ${day}, ${year}`;
  }

  return (
    <Card
      className="shadowless zero-margin-padding"
      style={{ position: "sticky", top: 85 }}
    >
      <div
        style={{
          background: `URL(${background})`,
          minHeight: 500,
          backgroundSize: "cover",
          borderRadius:20,
        }}
      >
        <Flex
          justify="space-between"
          vertical
          style={{
            minHeight: 500,
            borderRadius:20,
          }}
        >
          <WhatsappHeader />

          <Flex vertical align="center" style={{ minHeight: "480px" }}>
            <div
              style={{
                background: "#f7f7f5",
                padding: "2px 10px",
                marginTop: 15,
                borderRadius: "10px",
                width: "150px",
                textAlign: "center",
              }}
            >
              <Text strong style={{ color: "#54656f" }}>
                {formatDate()}
              </Text>
            </div>

            <div className="chat-container">
              <div className="message-box friend-message">
                <p style={{ textAlign: "left" }}>
                  <WhatsappMessageHeader />

                  <Text
                    style={{
                      fontSize: 15,
                      color: "#000000 !important",
                      lineHeight: "17px",
                      opacity: "1",
                    }}
                  >
                    {body}
                  </Text>

                  <Text style={{ fontSize: 15 }}>
                    {template?.footer}
                  </Text>
                  <Text style={{ width: "100%", textAlign: "right" }}>
                    07:45
                  </Text>

                  <WhatsappButtons />
                </p>
              </div>
            </div>
          </Flex>
                
          <Flex
            align="center"
            justify="space-between"
            style={{
              height: "45px",
              marginBottom: 5,
              width: "100%",
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            }}
          >
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%" }}
              gap={12}
            >
              <Flex
                align="center"
                justify="space-between"
                style={{
                  width: "90%",
                  height: "35px",
                  background: "white",
                  borderRadius: "35px",
                  marginLeft: 5,
                }}
              >
                <div className="flex-button" style={{ marginLeft: 5 }}>
                  <MdOutlineEmojiEmotions
                    style={{ fontSize: 20, color: "#8596a0" }}
                  />
                  <Text style={{ fontSize: 16, color: "#8596a0" }}>
                    Message
                  </Text>
                </div>

                <div className="flex-button" style={{ marginRight: 5 }}>
                  <GrAttachment
                    style={{ fontSize: 16, color: "#8596a0", marginRight: 5 }}
                  />
                  <MdCameraAlt
                    style={{ fontSize: 20, color: "#8596a0", marginRight: 5 }}
                  />
                </div>
              </Flex>

              <div
                style={{
                  height: "35px",
                  width: "35px",
                  background: "#008069",
                  borderRadius: 40,
                  marginRight: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PiMicrophoneFill style={{ fontSize: 22, color: "white" }} />
              </div>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </Card>
  );
};

const WhatsappMessageHeader = () => {
  const { Text, Title } = Typography;
  const { template } = useContext(WhatsAppTemplateContext);

  return (
    <>
      {template?.header?.type == "2" && (
        <Title style={{ margin: 0 }} level={4}>
          {template?.header?.text}
        </Title>
      )}

      {template?.header?.type == "3" && (
        <>
          {template?.header?.fileType == "Image" && (
            <div
              style={{
                height: "150px",
                background: "#ccd0d5",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaRegImage style={{ color: "white", fontSize: 75 }} />
            </div>
          )}

          {template?.header?.fileType == "Video" && (
            <div
              style={{
                height: "150px",
                background: "#ccd0d5",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaCirclePlay style={{ color: "white", fontSize: 75 }} />
            </div>
          )}

          {template?.header?.fileType == "Document" && (
            <div
              style={{
                height: "150px",
                background: "#ccd0d5",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoDocument style={{ color: "white", fontSize: 75 }} />
            </div>
          )}

          {template?.header?.fileType == "Location" && (
            <div style={{ background: "#f5f5f5" }}>
              <div
                style={{
                  height: "150px",
                  background: "#ccd0d5",
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IoLocationSharp style={{ color: "white", fontSize: 75 }} />
              </div>

              <div style={{ padding: 5 }}>
                <Text
                  style={{
                    color: "#000",
                    fontSize: 12,
                    opacity: 1,
                    lineHeight: "12px",
                  }}
                  strong
                >
                  {"{{Location name}}"}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: 12,
                    opacity: 1,
                    lineHeight: "12px",
                  }}
                >
                  {"{{Address}}"}
                </Text>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const WhatsappButtons = () => {
  const { template } = useContext(WhatsAppTemplateContext);

  const [btns, setBtns] = useState(() => []);

  useEffect(() => {
    const buttons = []
    if(!template){
      return;
    }

    if(template.ctaButton.order == 0){
      template.ctaButton.buttons.forEach((button, ind) => {
        buttons.push(button)
      })

      if(buttons.length < 3){
       
        for (let i=0;i<(3 - template.ctaButton.buttons.length)&& (i < template.quickReplyButtons.buttons.length);i++){
          buttons.push(template.quickReplyButtons.buttons[i])
        }
      }
    }else{

      template.quickReplyButtons.buttons.forEach((button, ind) => {
        buttons.push(button)
      })

      if(buttons.length < 3){
        for(let i=0;i<(3 - template.quickReplyButtons.buttons.length) && (i < template.ctaButton.buttons.length);i++){
          
          buttons.push(template.ctaButton.buttons[i])
        }
      }
    }

    setBtns(buttons)

  }, [template?.ctaButton, template?.quickReplyButtons])
  const cta = () => {
    
  
    return <>{btns?.slice(0, template?.ctaButton?.buttons?.length + template?.quickReplyButtons?.buttons?.length > 3 ? 2 : 3)?.map((btn) => (
      <>
        <Divider style={{ padding: 0, margin: 0 }} />
        <Button
          block
          icon={
            btn.buttonType === "cpn" ? (
              <FaPhoneAlt className="whatsapp-link-icon" />
            ) : btn.buttonType === "coc" ? (
              <MdContentCopy className="whatsapp-link-icon" />
            ) : btn.buttonType == "" ? (
              <RiShareBoxFill className="whatsapp-link-icon" />
            ): btn.buttonType === "mou" ? (
              <TiArrowBack className="whatsapp-link-icon" />
            ) : (
              <TiArrowBack className="whatsapp-link-icon" />
            )
          }
          type="link"
          className="whatsapp-link"
        >
          {btn.text}
        </Button>
      </>
    ))}
    
    {(template?.ctaButton?.buttons?.length + template?.quickReplyButtons?.buttons?.length > 3)&&<Button
          block
          icon={<FaListUl/> }
          type="link"
          className="whatsapp-link"
        >
          See all options
        </Button>}
    </>
  };

  const quickReply = () => {
    const take = template?.quickReplyButtons.order == 0 ? 3 : 3 - template?.ctaButton?.buttons?.length;

    if(take < 0){
        take = 0;
    }

    return template?.quickReplyButtons?.buttons?.slice(0, take).map((btn) => (
      <>
        <Divider style={{ padding: 0, margin: 0 }} />
        <Button
          block
          icon={
            btn.buttonType === "cpn" ? (
              <TiArrowBack className="whatsapp-link-icon" />
            ) : (
              <TiArrowBack className="whatsapp-link-icon" />
            )
          }
          type="link"
          className="whatsapp-link"
        >
          {btn.text}
        </Button>
      </>
    ));
    
  };

  return (
    <>
    {cta()}
    </>
  );
};

const WhatsappHeader = () => {
  const { Text } = Typography;
  return (
    <>
      <Flex
        align="center"
        style={{ background: "#008069", height: "50px", borderTopRightRadius:20, borderTopLeftRadius:20 }}
        justify="space-between"
      >
        <Flex align="center" justify="start" gap={6}>
          <IoMdArrowBack style={{ color: "white", fontSize: 25 }} />
          <img src={profile} height={30} width={30} loading="lazy"/>

          <Text style={{ color: "white", fontSize: 16 }}>
            +1 (555) 012-5805
          </Text>
        </Flex>

        <IoMdMore style={{ color: "white", fontSize: 25 }} />
      </Flex>
    </>
  );
};
export default MessagePreview;
