import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import AssignedUsersSelect from "../FormItems/AssignedUsersSelect";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { PiBuildingsFill } from "react-icons/pi";

const AddCompanyModal = ({ open, setOpen, setOptions, onAdd }) => {
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  function onClose() {
    setOpen(false);
  }

  const onFinish = async (e) => {

    setLoading(true);
    const response = await axios.post("/Company", {
      name: e.name,
      phone: e.phone,
      ownerIds: e.owners,
      industry: e.industry,
      website:e.website
    });

    if (response.data.success) {
      if (setOptions) {
        setOptions((prev) => [
          ...prev,
          { label: <Flex align="center" justify="start" gap={5}> <PiBuildingsFill />{e.name}
          </Flex>, value: response.data.id },
        ]);
      }
      if (onAdd) {
        onAdd(response.data);
      }
      onClose();
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      footer={null}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Add New Company
        </Title>
      }
      onCancel={onClose}
      destroyOnClose
    >
      <Divider style={{ margin: "5px 0px" }} />
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Space direction="vertical" size={0} className="w-100">
          <Text>Company Name</Text>
          <Form.Item name={"name"}>
            <Input size="large" placeholder="Enter a company name"></Input>
          </Form.Item>
        </Space>
        <AssignedUsersSelect
          name="owners"
          setDefault={(e) => form.setFieldValue("owners", [e])}
        />

<Row gutter={12}>
<Col sm={24} md={12}>

        <Space size={0} direction="vertical" className="w-100">
          <Text>Industry</Text>
          <Form.Item name="industry">
            <Input placeholder="Enter industry" size="large" />
          </Form.Item>
        </Space>
</Col>
  <Col sm={24} md={12}>

        <Space direction="vertical" size={0} className="w-100">
          <Text>Phone Number</Text>
          <Form.Item name={"phoneNumber"}>
            <Input
              style={{ width: "100%" }}
              placeholder="Enter a phone number"
              size="large"
              />
          </Form.Item>
        </Space>
              </Col>
</Row>
        <Space direction="vertical" size={0} className="w-100">
          <Text>Website</Text>
          <Form.Item name="website">
            <Input placeholder="Enter website" size="large" />
          </Form.Item>
        </Space>
        
        <Flex align="center" justify="end" gap={10}>
          <Button onClick={onClose}>Cancel</Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Save company
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default AddCompanyModal;
