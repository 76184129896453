import { Card, Col, Row } from "antd";
import Stat from "../../shared/Stat";
import { FaFolderOpen, FaTrophy } from "react-icons/fa";
import { MdHandshake, MdOutlineEqualizer } from "react-icons/md";
import { AiFillSchedule } from "react-icons/ai";
import { RiGitCommitFill } from "react-icons/ri";
import { FaHandshakeSimple } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import { useContext } from "react";
import { DealListContext } from "./DealListContext";

const DealStats = () => {
  const { stats } = useContext(DealListContext);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            name="Total"
            value={stats?.total}
            loading={false}
            icon={<MdHandshake style={{ fontSize: 22 }} />}
          />
        </Col>

        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.new}
            name="New"
            loading={false}
            icon={<FaFolderOpen style={{ fontSize: 18 }} />}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.qualifying}
            name="Qualifying"
            loading={false}
            icon={
              <MdOutlineEqualizer style={{ fontSize: 22 }} />
            }
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.demoScheduled}
            name="Demo Scheduled"
            loading={false}
            icon={<AiFillSchedule style={{ fontSize: 22 }} />}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.pendingCommitment}
            name="Pending Commitment"
            loading={false}
            icon={
              <RiGitCommitFill style={{ fontSize: 22 }} />
            }
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.inNegotiation}
            name="In Negotiation"
            loading={false}
            icon={
              <FaHandshakeSimple style={{ fontSize: 22 }} />
            }
          />
        </Col>

        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.won}
            name="Won"
            loading={false}
            icon={<FaTrophy style={{ fontSize: 22 }} />}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            name="Lost"
            value={stats?.lost}
            loading={false}
            icon={
              <IoMdCloseCircle style={{ fontSize: 22 }} />
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default DealStats;
