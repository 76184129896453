import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import logo from "../../assets/images/favicon.png";
import background from "../../assets/images/tapzaplogin.png";
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const ResetPassword = () => {
    const { Title, Text } = Typography;
    const [loading, setLoading] = useState();
    
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
   
    const email = queryParams.get('email');
    const token = queryParams.get('token');
   

    const handleSubmit = async (e) => {
      setLoading(true);
     
       const response = await axiosPrivate.post(`/Account/reset-password`, {
            email: email,
            token: token,
            password: e.password
        })
        
        if(response && response.data.success){
            message.success("Password reseted! You may login with your new password.");
            navigate('/Login')
        }else{
            message.error(response.data.message)
        }
      setLoading(false)
    };

    return<Row>
    <Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
      <Flex
        justify="center"
        align="center"
        style={{
          height: "100vh",
          background: "#fff"
        }}
      >
        <Space direction="vertical" style={{width:"350px"}}>
          <div>
            <Space direction="vertical" size={0}>
              <img src={logo} width={20} loading="lazy"/>
              <Text strong className="text-light">TAPZAP</Text>
            </Space>
            <Title style={{ margin: 0 }}>Reset password</Title>
            <Text className="text-light">
              Enter your new password and confirm it to set it as a new password!
            </Text>
          </div>
          <Form onFinish={handleSubmit}>
            <div>
              <Text>Password</Text>
              <Form.Item
                name="password"
                rules={[
                  {
                    message: "Enter a valid password",
                    pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter your new password"
                  allowClear
                />
              </Form.Item>
            </div>
          
            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              block
              className="primary-gradient-background"
              size="large"
            >
              Reset password
            </Button>
          </Form>

          <Flex align="center" justify="center" gap={0}>
            <Text>Not registered yet? </Text>
            <Button type="link" onClick={() => navigate("/register")}>
              Create an account!
            </Button>
          </Flex>
        </Space>
      </Flex>
    </Col>

    <Col xs={24} sm={24} md={12} lg={9} xl={9} xxl={9}>
      <div
        className="shadow"
        style={{
          background: `url(${background})`,
          width: "100%",
          height: "100%",
          backgroundSize:'cover',
          backgroundRepeat:"no-repeat"
        }}
      >
        
      </div>
    </Col>
  </Row>
}   

export default ResetPassword;