import {
  Card,
  Col,
  Empty,
  Flex,
  Pagination,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import SetDefaultSender from "./SetDefaultSender";
import TableLoading from "../../../shared/TableLoading";
import { DateFormat } from "../../../dateformat";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { EmailSenderContext } from "./EmailSenderContext";
import NoDataFound from "../../../shared/NoDataFound";

const SenderTable = () => {
  const { loading, senders, setSenders, page, setPage, stats, setStats } =
    useContext(EmailSenderContext);

  const { Text } = Typography;

  const handleDefaultChanged = (id) => {
    setSenders((prev) =>
      prev.map((d) =>
        d.id == id ? { ...d, default: true } : { ...d, default: false }
      )
    );
  };

  const handleDeleted = (id, newDefault) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    if (senders.length - 1 == 0 && page > 1) {
      setPage(page - 1);
    } else {
      setSenders((prev) => prev.filter((c) => c.id != id));
      setSenders((prev) =>
        prev.map((c) => (c.id == newDefault ? { ...c, default: true } : c))
      );
    }
  };

  return (
    <>
      <Space direction="vertical" className="w-100">
        <Card size="small">
          <Row>
            <Col span={6}>
              <Text className="column-header">Name</Text>
            </Col>
            <Col span={6}>
              <Text className="column-header">Email</Text>
            </Col>
            <Col span={5}>
              <Text className="column-header">Nickname</Text>
            </Col>
            <Col span={2}>
              <Text className="column-header">Default</Text>
            </Col>
            <Col span={3}>
              <Text className="column-header">Date</Text>
            </Col>
            <Col span={2}>
              <Flex align="center" justify="end">
                <Text className="column-header">Tool</Text>
              </Flex>
            </Col>
          </Row>
        </Card>

        {loading ? (
          <TableLoading />
        ) : !senders || senders.length == 0 ? (
          <Card size="small">
            <NoDataFound
              addText="New sender"
              description="Click the New Sender button on the sender/email page to create a new email sender"
              title="You have not created any email senders yet"
            />{" "}
          </Card>
        ) : (
          <>
            {senders?.map((c, i) => (
              <Card size="small">
                <Row>
                  <Col span={6} className="center-table-column">
                    <Flex align="center" justify="start" gap={6}>
                      {!c.confirmed && (
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 25,
                            backgroundColor: "#e74a3b",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IoClose style={{ color: "white", fontSize: 18 }} />
                        </div>
                      )}
                      {c.confirmed && (
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 25,
                            backgroundColor: "#1cc88a",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FaCheck style={{ color: "white" }} />
                        </div>
                      )}
                      <Text>{c.fullName}</Text>
                    </Flex>
                  </Col>
                  <Col span={6} className="center-table-column">
                    <Text>{c.email}</Text>
                  </Col>
                  <Col span={5} className="center-table-column">
                    <Text>{c.nickName}</Text>
                  </Col>
                  <Col span={2} className="center-table-column">
                    <Flex align="center" justify="center">
                      <Text style={{ textAlign: "center" }}>
                        {c.default ? "Default" : "-"}
                      </Text>
                    </Flex>
                  </Col>
                  <Col span={3} className="center-table-column">
                    <Text strong>
                      {new Date(c.createdAt * 1000).toLocaleDateString(
                        "en-Us",
                        DateFormat
                      )}
                    </Text>
                  </Col>
                  <Col span={2}>
                    <Flex align="center" justify="end">
                      <SetDefaultSender
                        confirmed={c.confirmed}
                        handleDeleted={handleDeleted}
                        disabled={c.default}
                        id={c.id}
                        handleDefaultChanged={handleDefaultChanged}
                      />
                    </Flex>
                  </Col>
                </Row>
              </Card>
            ))}
            <Card size="small">
              <Flex align="center" justify="end">
                <Pagination
                  current={page}
                  onChange={(p) => setPage(p)}
                  pageSize={7}
                  total={stats?.total}
                />
              </Flex>
            </Card>
          </>
        )}
      </Space>
    </>
  );
};

export default SenderTable;
