import { useState, useEffect } from "react";
import { Select, Typography } from "antd";
const UserOrderByDirection = ({ onChanged }) => {
  const [direction, setDirection] = useState();
  const { Text } = Typography;
  useEffect(() => {
    setDirection([
      {
        value: "Asc",
        label: "Asc",
      },
      {
        value: "Dsc",
        label: "Dsc",
      },
    ]);
  }, []);

  const handleOrderByDirectionChanged = (e) => {
    onChanged(e);
  };
  return (
    <>
      <Text>Direction</Text>
      <Select
        options={direction}
        className="little-transparent-input"
        size="large"
        style={{ width: "100%" }}
        onChange={handleOrderByDirectionChanged}
      />
    </>
  );
};

export default UserOrderByDirection;
