import { useContext } from "react";
import { TemplateComponentContext } from "../TemplateComponentContext";
import OrderBySelect from "../../../shared/OrderBySelect";

const TemplateComponentOrderByFilter = () => {
    const { filters, setFilters } = useContext(TemplateComponentContext);
  
    const options = [
      {
        value: "created",
        label: "Design created date"
      },
      {
        value: "name",
        label: "Design name"
      }
    ];
  
    const handleChanged = (e) => {
      setFilters((prev) => ({ ...prev, orderBy: e }));
    };

    return (
        <OrderBySelect
          options={options}
          handleChanged={handleChanged}
          defaultValue={filters?.orderBy}
        />
      );

}

export default TemplateComponentOrderByFilter; 