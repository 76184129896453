import { Button, Dropdown } from "antd";
import { BsFillTrashFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { IoDuplicateSharp, IoEllipsisHorizontal } from "react-icons/io5";
import "./EmailTemplateOptions.css"

const EmailTemplateOptions = ({ id, simple, onShare, onDelete, handleUpdate, openPreview, duplicate }) => {
  const items = [
    {
      key: "3",
      label: <p className="drop-down-text">Duplicate</p>,
      icon: <IoDuplicateSharp className="drop-down-icon" />,
      onClick: () => duplicate(id)
    },
    {
      key: "4",
      onClick: () => onShare(id),
      label: <p className="drop-down-text">Share</p>,
      icon: <FaShareAlt className="drop-down-icon" />,
    },
    {
      label:<div className="drop-down-divider"></div>
    },
    {
      key: "2",
      label: <p className="drop-down-text">Delete</p>,
      danger: true,
      onClick: () => onDelete(id),
      icon: <BsFillTrashFill className="drop-down-icon"/>,
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          type="text"
          size="large"
          className="flex-button"
          icon={
            <IoEllipsisHorizontal className="template-options-icon" />
          }
        ></Button>
      </Dropdown>
    </>
  );
};

export default EmailTemplateOptions;
