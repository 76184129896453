import { Flex, Select, Typography } from "antd";
import { EmailSenderDefault } from "../../../../shared/enums";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";
import { MdArrowDropDown } from "react-icons/md";

const EmailSenderDefaultSelect = () => {
  const { Text } = Typography;

  const options = [
    {
      value: EmailSenderDefault.Default,
      label: "Default",
    },
    {
      value: EmailSenderDefault.NotDefault,
      label: "No default",
    },
  ];

  const { filters, setFilters } = useContext(EmailSenderContext);

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>Default</Text>
      <Select
        placeholder="Select sender default"
        size="large"
        mode="multiple"
        className="order-by-select"
        value={filters?.defaults}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
        defaultValue={filters?.defaults}
        onChange={(e) => setFilters((prev) => ({ ...prev, defaults: e }))}
        options={options}
      />
    </Flex>
  );
};

export default EmailSenderDefaultSelect;
