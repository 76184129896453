import { Card, Flex, Skeleton, Space } from "antd"

const TemplateSkeletonCard = () => {
    return (<>
        <Card cover={<Skeleton.Image className="w-100" active style={{height:"200px"}}/>
            }>
                <Space style={{width:"100%"}} direction="vertical">

            <Flex align="center" justify="space-between">
                <Space>
                    <Skeleton.Input active size="small"/>
                </Space>

                <Space>
                    <Skeleton.Button shape="circle" active/>
                    <Skeleton.Button shape="circle" active/>
                </Space>
            </Flex>

            <Space direction="vertical" style={{width:"100%"}}>    
                <Skeleton.Input active size="small" className="w-100"/>
                <Skeleton.Input active size="small" className="w-100"/>
            
                <Skeleton.Button active className="w-100" style={{borderRadius:"20px", padding:20}}/>
                </Space>
            </Space>
        </Card>
    </>)
}

export default TemplateSkeletonCard