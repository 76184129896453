import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import SideMenu from "./Navigation/SideMenu";
import useLocalStorage from "../hooks/useLocalStorage";
import { useState } from "react";

const FullHeightApplicationLayout = () => {
  const [collapseValue, setCollapseValue] = useLocalStorage("collapse", true);
  const [collapsed, setCollapsed] = useState(() => collapseValue);

  const updateCollapse = (e) => {
    setCollapseValue(e);
    setCollapsed(e);
  };

  return (
    <>
      <Layout style={{ height: "100vh", width: "100%" }}>
       
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Outlet />
        </div>
      </Layout>
    </>
  );
};
export default FullHeightApplicationLayout;
