import { useContext } from "react";
import { ProductListContext } from "../ProductListContext";
import { DatePicker, Flex, Typography } from "antd";
import { MdArrowDropDown } from "react-icons/md";

const DateToProductFilter = () => {
  const { filters, setFilters } = useContext(ProductListContext);
  const { Text } = Typography;
  return (
    <>
      <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>To</Text>
        <DatePicker
          size="large"
          placeholder="Select created date start"
          defaultValue={filters?.to}
          value={filters?.to}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              to: e,
            }))
          }
          className="dates-filter-select"
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
        />
      </Flex>
    </>
  );
};

export default DateToProductFilter;
