const removeButtonsFromHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const buttons = doc.querySelectorAll('button');
    buttons.forEach(button => button.remove());
    const serializer = new XMLSerializer();
    const serializedHtml = serializer.serializeToString(doc);
    return serializedHtml;
  } 


  export {
    removeButtonsFromHtml
  }