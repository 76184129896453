import axios from "../api/axios";
import useAuth from "./useAuth";
import useLocalStorage from "./useLocalStorage";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken");

  const refresh = async () => {
    const obj = JSON.parse(refreshToken);

    const response = await axios.post("/Account/refreshtoken", {
      Token: obj.refreshToken,
      Email: obj.email,
    });

    setAuth((prev) => {
      return {
        ...prev,
        id: parseInt(response?.data?.data?.id),
        roles: response.data.data.roles,
        accessToken: response.data.data.jwToken,
        emailConfirmed: response.data.data.isVerified,
        step: response.data.data.step,
        email: response.data.data.email,
      };
    });

    const data = response.data.data;
    const refToken = {
      refreshToken: data.refreshToken,
      email: data.email,
    };

    setRefreshToken(JSON.stringify(refToken));

    return response.data.data.jwToken;
  };
  return refresh;
};

export default useRefreshToken;
