import { Typography, Flex, Grid } from "antd";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Balance from "./Balance";
import Notifications from "./Notifications";
import UserProfile from "./UserProfile";
import LanguageSelector from "./LanguageSelector";

const UserProfileMenu = ({ collapsed, setCollapsed, showSearch = true }) => {
  const axios = useAxiosPrivate();

  const [user, setUser] = useState();

  const { setAuth } = useAuth();

  useEffect(async () => {
    const response = await axios.get("Users/CurrentUser");

    setUser(response.data.data);
    setAuth((prev) => ({
      ...prev,
      subType: response.data.data.subscriptionType,
    }));
  }, []);

  return (
    <>
      <Flex align="center" justify="end" gap={12}>
        <Balance />

        <LanguageSelector />

        <Notifications />

        <UserProfile />
      </Flex>
    </>
  );
};

export default UserProfileMenu;
