import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  message,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { MdClose, MdEmojiEmotions } from "react-icons/md";
import Iphone from "../../../shared/Iphone";
import "./Subject.css";
import ButtonIcon from "../../../shared/ButtonIcon";
import { FaCheck, FaUser } from "react-icons/fa";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useRef, useState } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import AddPersonalizationDrawer from "./AddPersonalizationDrawer";
import Picker from "@emoji-mart/react";
import UseAiDrawer from "./UseAIDrawer";
import { Editor } from "@tinymce/tinymce-react";
import { removeButtonsFromHtml } from "../../../shared/HtmlOps";

const Subject = ({ active, setStep }) => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const { subjectAndPreview, setSubjectAndPreview, data, setData } = useContext(
    CreateCampaignContext
  );

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(subjectAndPreview);
    form.setFieldValue("subject", subjectAndPreview?.subject);
    form.setFieldValue("preview", subjectAndPreview?.preview);
  }, [data]);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(
      "Campaign/Email/Assign/SubjectAndPreview",
      {
        subject: e.subject,
        previewText: e.preview,
        campaignId: data?.id,
      }
    );

    if (response.data.success) {
      setSubjectAndPreview({
        subject: e.subject,
        preview: e.preview,
      });

      setData((prev) => ({
        ...prev,
        subjectFinished: true,
        subject: e.subject,
        previewText: e.preview,
      }));

      setStep();
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };


  const [subjectCount, setSubjectCount] = useState(0);
  const [previewCount, setPreviewCount] = useState(0)

  const previewValidator = async (_, value) => {
    if (value && previewCount.length > 200) {
      return Promise.reject(
        new Error("Content can only be up to 200 characters long.")
      );
    }

    return Promise.resolve();
  };

  const subjectValidator = async (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a subject"));
    }

    if (subjectCount && subjectCount.length < 3) {
      return Promise.reject(
        new Error("Subject should be longer than 3 characters")
      );
    }

    if (subjectCount && subjectCount.length > 200) {
      return Promise.reject(
        new Error("Content can only be up to 200 characters long.")
      );
    }

    return Promise.resolve();
  };

  const tempSubject = "";

  return (
    <>

      <Card
        className={`step-card subject-card no-shadow ${
          active ? "active-step" : ""
        }`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            {data?.subjectFinished ? (
              <div className="step-evaluation-box-success">
                <FaCheck />
              </div>
            ) : (
              <div className="step-evaluation-box-default"></div>
            )}
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Subject
              </Title>
              {data?.subjectFinished ? (
                <Flex
                  align="center"
                  justify="start"
                  gap={2}
                  className="zero-margin-padding"
                >
                  <p
                    className="zero-margin-padding-children"
                    dangerouslySetInnerHTML={{
                      __html: `${removeButtonsFromHtml(subjectAndPreview?.subject)}`,
                    }}
                  ></p>
                  <p className="zero-margin-padding-children large-font">
                    &nbsp;•&nbsp;
                  </p>
                  <p
                    className="zero-margin-padding-children"
                    dangerouslySetInnerHTML={{
                      __html: `${removeButtonsFromHtml(subjectAndPreview.preview)}`,
                    }}
                  ></p>
                </Flex>
              ) : (
                <Text>Add a subject line for this campaign.</Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={() => setStep()}
              className="flex-button w-300"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <Button size="large" className="w-300" onClick={() => setStep(3)}>
              Add subject
            </Button>
          )}
        </Flex>

        {active && (
          <>
            <div className="subject-work-wrapper">
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Form form={form} onFinish={submit}>
                    <Space className="w-100" size={24} direction="vertical">
                      <Space className="w-100" direction="vertical" size={0}>
                        <Text className="thick-lable">Subject line*</Text>
                        <SubjectTextBox
                          form={form}
                          rules={[{ validator: subjectValidator }]}
                          fieldName="subject"
                          max={200}
                          setTextCount={setSubjectCount}
                        />
                      </Space>

                      <Space className="w-100" direction="vertical" size={0}>
                        <Text className="thick-lable">Preview text</Text>
                        <SubjectTextBox
                          form={form}
                          fieldName="preview"
                          max={200}
                          rules={[{ validator: previewValidator }]}
                          setTextCount={setPreviewCount}
                        />
                      </Space>
                    </Space>
                  </Form>
                </Col>
                <Col span={12}>
                  <Flex align="center" justify="center" vertical>
                    <Iphone
                      height={280}
                      children={
                        <Space direction="vertical" className="w-100">
                          <div className="w-100 inbox-wrapper">
                            <Text className="sender-inbox-text w-100">
                              Inbox
                            </Text>
                          </div>

                          <div className="sender-inbox-seperator"></div>
                          <Space
                            className="w-100 sender-sender-data-wrapper"
                            direction="vertical"
                            size={0}
                          >
                            <Flex align="center" justify="space-between">
                              <Text className="sender-sender-data-name">
                                Tretek
                              </Text>
                              <Text className="sender-sender-data-time">
                                11:40
                              </Text>
                            </Flex>
                            <Text className="sender-sender-data-subject">
                              {tempSubject?.subject
                                ? tempSubject?.subject
                                : "Message subject..."}
                            </Text>
                            <Text className="sender-sender-data-preview">
                              {tempSubject?.preview
                                ? tempSubject?.preview
                                : "Your preview text..."}
                            </Text>
                          </Space>
                          <div className="sender-inbox-seperator"></div>
                        </Space>
                      }
                    />
                    <Text className="warn-text">
                      Actual email preview may vary depending on the email
                      client.
                    </Text>
                  </Flex>
                </Col>
              </Row>

              <Flex align="center" justify="end" gap={6} className="margin-top">
                <Button
                  size="large"
                  onClick={() => setStep()}
                  className="flex-button new-buttons"
                >
                  Cancel
                  <ButtonIcon
                    size={"large"}
                    background={"#f2f2f2"}
                    icon={<MdClose />}
                  />
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => form.submit()}
                  loading={loading}
                  className="flex-button new-buttons"
                >
                  Save
                  <ButtonIcon
                    size={"large"}
                    background={"#5a6bc5"}
                    icon={<FaCheck />}
                  />
                </Button>
              </Flex>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

const SubjectTextBox = ({ form, fieldName, rules, max, setTextCount }) => {
  const { Text } = Typography;

  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);

  const [editorContent, setEditorContent] = useState(
    form.getFieldValue(fieldName) ? form.getFieldValue(fieldName) : ``
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  const [count, setCount] = useState(form.getFieldValue(fieldName)?.length ?? 0);

  const handleSelect = (emoji) => {
    setShowPicker(false);

    let fieldValue = form.getFieldValue(fieldName);

    if (fieldValue) {
      form.setFieldValue(fieldName, `${fieldValue} ${emoji.native}`);
      setEditorContent(`${fieldValue} ${emoji.native}`);
    } else {
      form.setFieldValue(fieldName, emoji.native);
      setEditorContent(emoji.native);
    }
  };

  const [aiOpen, setAiOpen] = useState(false);

  const handleAiSave = (e) => {
    setAiOpen(false);
    setEditorContent(e);
    form.setFieldValue(fieldName, e);
  };

  const handleEditorChange = (content, editor) => {
    form.setFieldValue(fieldName, content);
    form.validateFields([fieldName]);
    setEditorContent(form.getFieldValue(fieldName));

    const textContent = editor.getContent({ format: 'text' });
    setCount(textContent.length);
    setTextCount(textContent.length)
  };

  const handleEditorBlur = () => {
  };

  const [personalisationOpen, setPersonalisationOpen] = useState(false);

  const handlePersonalisation = (e) => {
    console.log(e)

    form.setFieldValue(fieldName, `${form.getFieldValue(fieldName)} ${e}`);
    form.validateFields([fieldName]);
    setEditorContent(form.getFieldValue(fieldName));
  }
  return (
    <>

<AddPersonalizationDrawer
        open={personalisationOpen}
        setOpen={setPersonalisationOpen}
        onAdd={handlePersonalisation}
      />

      <UseAiDrawer
        open={aiOpen}
        onSave={handleAiSave}
        setOpen={setAiOpen}
        text={editorContent}
      />

      <div className="subject-text-box-wrapper">
        <Form.Item
          rules={rules}
          name={fieldName}
          className="hidden-form-item"
        ></Form.Item>
        <Editor
          onBlur={handleEditorBlur}
          className="subject-text-box"
          apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
          onEditorChange={handleEditorChange}
          value={editorContent}
          init={{
            plugins: "",
            toolbar: false,
            menubar: false,
            height: 100,
            statusbar: false,
            extended_valid_elements: 'span[id|style|contenteditable],button[onclick]',
            allow_script_urls: true,
            setup: (editor) => {
              editor.on("keydown", (e) => {
                if (e.keyCode === 13) {
                  // keyCode for Enter
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }
              });
            },
          }}
        />

        <Flex className="margin-right" align="center" justify="end" gap={6}>
          <Button
            onClick={() => setAiOpen(true)}
            className="use-ai-button"
            size="small"
            type="text"
          >
            Use AI
          </Button>
          <div>
            <Tooltip title="Add emoji">
              <Button
                onClick={() => setShowPicker((prev) => !prev)}
                icon={<MdEmojiEmotions className="subject-icon emoji-icon" />}
                type="text"
                className="flex-button"
              ></Button>
            </Tooltip>
            {showPicker && (
              <div className="emoji-selector" ref={pickerRef}>
                <Picker
                  set="apple"
                  theme="light"
                  onEmojiSelect={handleSelect}
                />
              </div>
            )}
          </div>
          <Tooltip title="Add personalisation">
            <Button
              type="text"
              className="flex-button"
              onClick={() => setPersonalisationOpen(true)}
              icon={<FaUser className="subject-icon" />}
            ></Button>
          </Tooltip>
          <Text style={{ width: 60, textAlign: "right" }}>
            {count}/{max ?? 200}
          </Text>
        </Flex>
      </div>
      <Form.Item shouldUpdate className="no-error-show">
        {() => {
          const errors = form?.getFieldError(fieldName);
          return (
            errors?.length > 0 && (
              <div style={{ color: "#e74a3b", fontWeight: 500 }}>
                * {errors[0]}
              </div>
            )
          );
        }}
      </Form.Item>
    </>
  );
};
export default Subject;
