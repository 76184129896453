import { DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { MediaContext } from "../MediaContext";
import { MdArrowDropDown } from "react-icons/md";

export const CreatedFromDateFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(MediaContext);

  return (
    <>
      <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>From</Text>
        <DatePicker
          size="large"
          placeholder="Select created date start"
          value={filters.from}
          className="dates-filter-select"
          onChange={(e) => setFilters((prev) => ({ ...prev, from: e }))}
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
        />
      </Flex>
    </>
  );
};
