import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Popconfirm, message } from "antd";

const UnblockUser = ({ user, handleUnblocked }) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const unbanUser = async () => {
    setLoading(true);
    const response = await axios.post(`/Users/UnblockUser`, {
      id: user,
    });

    if (response && response.data.success) {
      message.success("Nice, user un-blocked.");
      handleUnblocked(user);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Popconfirm
      title="Un-block user"
      description="Are you sure you want to unblock the selected user?"
      okText="Yes, un-ban!"
      onConfirm={unbanUser}
    >
      Un-ban user
    </Popconfirm>
  );
};

export default UnblockUser;
