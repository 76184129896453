import { Outlet } from "react-router-dom";
import { TemplateComponentContextProvider } from "./TemplateComponentContext";

const TemplateComponentListLayout = () => {
  return (
    <>
      <TemplateComponentContextProvider>
        <Outlet />
      </TemplateComponentContextProvider>
    </>
  );
};

export default TemplateComponentListLayout;
