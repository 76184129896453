import {
  Button,
  Card,
  Flex,
  Modal,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const EmailQualityModal = ({ open, setOpen, email }) => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();

  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(`/EmailQuality/check/${email}`);

    setData(response.data);
    setLoading(false);
  }, []);

  return (
    <>
      <Modal
        onCancel={() => setOpen(false)}
        open={open}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Email validity check
          </Title>
        }
        footer={
          <Flex align="center" justify="end">
            <Button onClick={() => setOpen(false)}>Okay</Button>
          </Flex>
        }
      >
        {loading ? <Flex align="center" justify="center" style={{height:450}}><Spin spinning/></Flex> : <>
        <Flex align="center" justify="space-between">
          <Text>{data?.input}</Text>

          {data?.isReachable == "safe" ? (
            <Tag color="green" className="quality-tag">
              SAFE
            </Tag>
          ) : data?.isReachable == "risky" ? (
            <Tag className="quality-tag" color="red">
              RISKY
            </Tag>
          ) : data?.isReachable == "invalid" ? (
            <Tag className="quality-tag" color="orange">
              INVALID
            </Tag>
          ) : (
            <Tag className="quality-tag" color="gray">
              UNKNOWN
            </Tag>
          )}
        </Flex>
        <div
          style={{
            maxHeight: 400,
            overflow: "auto",
            padding: "0px 12px",
            marginTop: 12,
            marginRight: -20,
            marginLeft: -20,
          }}
        >
          <Space direction="vertical" className="w-100">
            <Card style={{ boxShadow: "none" }}>
              <Title level={4} style={{ margin: 0 }}>
                MISC
              </Title>
              <Space className="w-100" direction="vertical">
                <Flex align="center" justify="space-between">
                  <Text>Disposable</Text>
                  <Text strong>
                    {data?.misc?.isDisposable ? "True" : "False"}
                  </Text>
                </Flex>

                <Flex align="center" justify="space-between">
                  <Text>Role account</Text>
                  <Text strong>
                    {data?.misc?.isRoleAccount ? "True" : "False"}
                  </Text>
                </Flex>
              </Space>
            </Card>
            <Card style={{ boxShadow: "none" }}>
              <Title level={4} style={{ margin: 0 }}>
                MX
              </Title>
              <Space direction="vertical" className="w-100">
                <Flex align="center" justify="space-between">
                  <Text>Accepts mail</Text>
                  <Text strong>{data?.mx?.acceptsMail ? "True" : "False"}</Text>
                </Flex>

                <Flex align="start" justify="space-between">
                  <Text>Records</Text>
                  <div>
                    {data?.mx?.records.map((c) => (
                      <div>
                        <Text strong>{c}</Text>
                      </div>
                    ))}
                  </div>
                </Flex>
              </Space>
            </Card>
            <Card style={{ boxShadow: "none" }}>
              <Title level={4} style={{ margin: 0 }}>
                SMTP
              </Title>
              <Space direction="vertical" className="w-100">
                <Flex align="center" justify="space-between">
                  <Text>Can connect</Text>
                  <Text strong>
                    {data?.smtp?.canConnectSmtp ? "True" : "False"}
                  </Text>
                </Flex>
                <Flex align="center" justify="space-between">
                  <Text>Full inbox</Text>
                  <Text strong>
                    {data?.smtp?.hasFullInbox ? "True" : "False"}
                  </Text>
                </Flex>
                <Flex align="center" justify="space-between">
                  <Text>Catch all</Text>
                  <Text strong>
                    {data?.smtp?.isCatchAll ? "True" : "False"}
                  </Text>
                </Flex>
                <Flex align="center" justify="space-between">
                  <Text>Deliverable</Text>
                  <Text strong>
                    {data?.smtp?.isDeliverable ? "True" : "False"}
                  </Text>
                </Flex>
                <Flex align="center" justify="space-between">
                  <Text>Disabled</Text>
                  <Text strong>
                    {data?.smtp?.isDisabled ? "True" : "False"}
                  </Text>
                </Flex>
              </Space>
            </Card>
            <Card style={{ boxShadow: "none" }}>
              <Title level={4} style={{ margin: 0 }}>
                Syntax
              </Title>
              <Space direction="vertical" className="w-100">
                <Flex align="center" justify="space-between">
                  <Text>Address</Text>
                  <Text strong>{data?.syntax?.address}</Text>
                </Flex>
                <Flex align="center" justify="space-between">
                  <Text>Domain</Text>
                  <Text strong>{data?.syntax?.domain}</Text>
                </Flex>
                <Flex align="center" justify="space-between">
                  <Text>Valid syntax</Text>
                  <Text strong>
                    {data?.syntax?.isValidSyntax ? "True" : "False"}
                  </Text>
                </Flex>
                <Flex align="center" justify="space-between">
                  <Text>Username</Text>
                  <Text strong>{data?.syntax?.username}</Text>
                </Flex>
              </Space>
            </Card>
          </Space>
        </div></>}
      </Modal>
    </>
  );
};

export default EmailQualityModal;
