import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Segmented,
  Space,
  Typography,
  Grid,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DealsTabs } from "../../shared/enums";
import { DealsManagementContext } from "../../context/DealsManagementContext";
import { MdFilterAlt, MdFilterAltOff, MdTableRows } from "react-icons/md";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DealStats from "./DealStats";
import CardItemsFilter from "./Filters/CardItemsFilter";
import CompanyFilter from "./Filters/CompanyFilter";
import CreatedDateFromFilter from "./Filters/CreatedDateFromFilter";
import ProductFilter from "./Filters/ProductFilter";
import StatusFilter from "./Filters/StatusFilter";
import CreatedDateToFilter from "./Filters/CreatedDateToFilter";
import { DealListContext } from "./DealListContext";
import ButtonIcon from "../../shared/ButtonIcon";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../dateformat";

const { useBreakpoint } = Grid;

const Header = ({ activeTab, setActiveTab, setShowDrawer }) => {
  const { stats, setStats, filters, setFilters } = useContext(DealListContext);

  const screens = useBreakpoint();

  const [open, setOpen] = useState(() => ({
    total: 0,
    open: 0,
  }));

  const { setIsDrawerOpen } = useContext(DealsManagementContext);
  const axios = useAxiosPrivate();

  useEffect(async () => {
    const response = await axios.get("/Deal/open");

    setOpen({
      open: response.data.open,
      total: response.data.total,
    });
  }, []);

  useEffect(() => {
    if (screens.xs) {
      setActiveTab(DealsTabs.List);
    } else {
      setActiveTab(DealsTabs.Kanban);
    }
  }, [screens.xs, setActiveTab]);

  const { Title, Text } = Typography;
  return (
    <>
      <Space direction="vertical" className="w-100" size={24}>
        <DealStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title style={{ margin: 0 }} level={4}>
                Deal management
              </Title>
              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { width: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  className="flex-button new-buttons"
                  onClick={() => {
                    setFilters((prev) => ({ ...prev, shown: !prev.shown }));
                  }}
                >
                  Filter
                  {!filters?.shown ? (
                    <ButtonIcon size="large" icon={<MdFilterAlt />} background={"#f1f1f1"} />
                  ) : (
                    <ButtonIcon size="large"
                      background={"#f1f1f1"}
                      icon={<MdFilterAltOff />}
                    />
                  )}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="flex-button new-buttons"
                  onClick={() => setShowDrawer(true)}
                >
                  New Deal
                  <ButtonIcon size="large"
                    icon={<FiPlus style={{ fontSize: 20 }} />}
                    background={"#5a6bc5"}
                  />
                </Button>
              </Flex>
            </Flex>
          }
        >
          {filters?.shown && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col md={12}>
                <CompanyFilter />
              </Col>
              <Col md={12}>
                <ProductFilter />
              </Col>
              <Col md={5}>
                <CreatedDateFromFilter />
              </Col>
              <Col md={5}>
                <CreatedDateToFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Flex align="center" justify="space-between" gap={6} wrap="wrap">
          <Flex align="center" justify="start" gap={6} wrap="wrap">
          <CardItemsFilter />
          <StatusFilter />
          </Flex>
          <Segmented
            size="large"
            default="Kanban"
            defaultValue="Kanban"
            style={{
              background:"#eaeaf8"
            }}
            onChange={(e) => {
              if (e === "List") {
                setActiveTab(DealsTabs.List);
              } else {
                setActiveTab(DealsTabs.Kanban);
              }
            }}
            options={[
              {
                value: "List",
                icon: <MdTableRows />,
              },
              {
                value: "Kanban",
                icon: <BsFillGrid1X2Fill />,
              },
            ]}
          />
        </Flex>
      </Space>
    </>
  );
};

export default Header;
