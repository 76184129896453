import { Col, Skeleton } from "antd";

const NumberPriceCardSkeleion = () => {
  const items = [1, 1,1,1, 1,1,1, 1,1,1, 1,1];
  return (
    <>
      {items.map((c) => (
        <Col key={c.friendlyName} xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
          <Skeleton.Input active={true} style={{ marginTop: 15, height:150 }} className="w-100" />
        </Col>
      ))}
    </>
  );
};

export default NumberPriceCardSkeleion;
