import {
  Button,
  Card,
  Col,
  Flex,
  Grid,
  Row,
  Space,
  message,
  Typography,
  Tag,
  Pagination,
  Avatar,
  Tooltip,
} from "antd";
import NotificationTemplatesStats from "./NotificationTemplatesStats";
import { useContext, useState } from "react";
import { MdEdit, MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import NotificationTemplatesAuthorFilter from "./Filters/NotificationTemplatesAuthorFilter";
import NotificationTemplatesFromDateFilter from "./Filters/NotificationTemplatesFromDateFilter";
import NotificationTemplatesToDateFilter from "./Filters/NotificationTemplatesToDateFilter";
import NotificationTemplatesOrderByFilter from "./Filters/NotificationTemplatesOrderByFilter";
import NotificationTemplatesOrderDirectionFilter from "./Filters/NotificationTemplatesOrderDirectionFilter";
import { NotificationTemplatesContext } from "./NotificationTemplatesContext";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import { useNavigate } from "react-router-dom";
import NotificationTemplatresOptions from "./NotificationTemplatesOptions";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DateFormat } from "../../../dateformat";
import ButtonIcon from "../../../shared/ButtonIcon";
import { IoCheckmarkOutline, IoClose } from "react-icons/io5";
import env from "../../../env.json";
import { UserOutlined } from "@ant-design/icons";
import { AuthorContext } from "../../../context/AuthorsContext";
import "./NotificationTemplatesList.css";
import { AiFillEdit } from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import CreateNewNotificationTemplateModal from "./CreateNewNotificationTemplateModal";
import { RiNotificationBadgeFill } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import NotificationPreviewModal from "./NotificationTemplatePreviewModal";

const { useBreakpoint } = Grid;

const NotificationTemplatesList = () => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  const { Text, Title } = Typography;
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [current, setCurrent] = useState();

  const [showFilters, setShowFilters] = useState(false);
  const screens = useBreakpoint();
  const perPage = 7;
  const {
    loading,
    stats,
    page,
    setPage,
    groups,
    setGroups,
    setStats,
    filters,
    setFilters,
  } = useContext(NotificationTemplatesContext);

  const { authors } = useContext(AuthorContext);
  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    let response = await axios.delete(
      `/Notification/Notification-Template/${item}`
    );

    if (response.data.success) {
      setGroups((prev) => prev.filter((c) => c.id != item));
      setStats(prev => ({...prev, total: prev.total - 1}))
    } else {
      message.error(response.data.message);
    }
  };

  const [addOpen, setAddOpen] = useState(false);

  const handleDuplicate = async (id) =>{
    const response = await axios.post("/Notification/Template/Duplicate",{
      id: id
    });

    if(response.data.success){
      setStats(prev => ({...prev, total: prev.total + 1}))
      setGroups(prev => ([response.data, ,...prev]))
    }else{
      message.error(response.data.message);
    }
  }

  const [previewOpen, setPreviewOpen] = useState(false);

  const handleOpenPreview = (e, item)=>{
    setCurrent(item);
    setPreviewOpen(true)
  }
  return (
    <>
      <NotificationPreviewModal open={previewOpen} setOpen={setPreviewOpen} id={current}/>
      <CreateNewNotificationTemplateModal open={addOpen} setOpen={setAddOpen} />
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current?.id}
      />

      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <NotificationTemplatesStats />
        <Card size="small">
          <Flex
            align="center"
            justify="space-between"
            gap={12}
            wrap="wrap"
            style={{
              ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
            }}
          >
            <Title level={4} style={{ margin: 0 }}>
              Notification designs
            </Title>

            <Space>
              <Button
                size="large"
                block={screens.xs}
                className="flex-button new-buttons"
                onClick={() => setShowFilters(!showFilters)}
              >
                Filter
                {!showFilters ? (
                  <ButtonIcon
                    size="large"
                    icon={<MdFilterAlt />}
                    background={"#f1f1f1"}
                  />
                ) : (
                  <ButtonIcon
                    size="large"
                    background={"#f1f1f1"}
                    icon={<MdFilterAltOff />}
                  />
                )}
              </Button>
              <Button
                type="primary"
                size="large"
                block={screens.xs}
                onClick={() => setAddOpen(true)}
                className="flex-button new-buttons"
              >
                New design
                <ButtonIcon
                  size="large"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Space>
          </Flex>

          {showFilters && (
            <div style={{ margin: 24 }}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <NotificationTemplatesAuthorFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <NotificationTemplatesFromDateFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <NotificationTemplatesToDateFilter />
                </Col>
              </Row>
            </div>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c, i) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item" key={i}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
            <NotificationTemplatesOrderByFilter />
            <NotificationTemplatesOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={18}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={3}>
                <Text className="column-header">Status</Text>
              </Col>
              <Col span={3}>
                <Text className="column-header"></Text>
              </Col>
            </Row>
          </Card>
          {loading ? (
            <TableLoading />
          ) : !groups || groups.length == 0 ? (
            <Card size="small">
              <NoDataFound
                addText="New notification desing"
                onAdd={() => navigate("/Templates/Notification/create")}
                description="Click the New Design button on the Notification desing page to create a new notification template"
                title="You have not created any notification templates yet"
              />
            </Card>
          ) : (
            <>
              {groups?.map((c, ind) => (
                <Card size="small" key={ind}>
                  <Row gutter={[6, 6]} style={{ height: "100%" }}>
                    <Col span={18}>
                      <Flex align="center" justify="start" gap={12}>
                        <Avatar
                          icon={<RiNotificationBadgeFill />}
                          shape="square"
                          size={"large"}
                          src={`${env.fileUpload}${c.path}`}
                        />
                        <Space size={0} direction="vertical" className="w-100">
                          <Text
                            type="link"
                            onClick={() =>
                              navigate(`/Templates/Notification/Edit/${c.id}`)
                            }
                            className="notification-list-title"
                          >
                            {c.name}
                          </Text>
                          <Text className="notification-list-created-at">
                            {new Date(c.createdAt * 1000).toLocaleDateString(
                              "en-us",
                              DateFormat
                            )}
                            {c.lastEdit
                              ? ` • Last edited at ${new Date(
                                  c.lastEdit * 1000
                                ).toLocaleDateString("en-us", DateFormat)}`
                              : ""}
                          </Text>
                        </Space>
                      </Flex>
                    </Col>

                    <Col span={3}>
                      <Flex className="h-100" align="center" justify="start">
                        {c.saveStatus == 1 ? (
                          <Text className="notification-saved">
                            <IoMdCheckmark /> Done
                          </Text>
                        ) : (
                          <Text className="notification-draft">
                            <MdEdit /> Draft
                          </Text>
                        )}
                      </Flex>
                    </Col>
                    <Col span={3}>
                      <Flex
                        gap={12}
                        className="h-100"
                        align="center"
                        justify="end"
                      >
                        <Tooltip title='Edit'>

                        <Button
                          size="large"
                          className="flex-button"
                          type="text"
                          onClick={() =>
                            navigate(`/Templates/Notification/Edit/${c.id}`)
                          }
                          icon={
                            <AiFillEdit className="template-options-icon" />
                          }
                          ></Button>
                          </Tooltip>

                        <Tooltip title="Preview">

                        <Button
                          size="large"
                          className="flex-button"
                          onClick={(e) => handleOpenPreview(e, c.id)}
                          type="text"
                          icon={<FaEye className="template-options-icon" />}
                        ></Button>
                        </Tooltip>

                        <NotificationTemplatresOptions 
                          onDuplicate={handleDuplicate}
                          template={c}
                          setCurrent={setCurrent}
                          openEdit={() => setEditOpen(true)}
                          openDelete={() => setDeleteOpen(true)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Card size="small">
                <Flex align="center" justify="end" className="w-100">
                  <Pagination
                    current={page}
                    pageSize={perPage}
                    onChange={(page) => setPage(page)}
                    total={stats?.total}
                  />
                </Flex>
              </Card>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default NotificationTemplatesList;
