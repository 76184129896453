import { Button, Dropdown } from "antd";
import { IoMdMore } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import { PiNoteBlankFill } from "react-icons/pi";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DeleteList from "./DeleteList";

const ListOptions = ({ id, deal, onDelete, setWonModal, setLostModal, setCurrent }) => {
    const navigate = useNavigate();
  const items = [{
    label:"Add note",
    key:"2",
    icon:<PiNoteBlankFill />,
    onClick:() => navigate(`/Deals/Details/${id}#note`)
  },
  {
    label: "Move deal to won",
    key:3,
    icon:<FaThumbsUp />,
    onClick: () => {
        setWonModal(true);
        setCurrent(id)
    }
  },
  {
    label:"Move deal to lost",
    key:4,
    icon:<FaThumbsDown />,
    onClick:() => {
        setLostModal(true);
        setCurrent(id)
    }
  },
  {
    key:1,
    label: <DeleteList id={id} onDelete={onDelete} deal={deal}/>,
    danger:true,
    icon:<BsTrashFill/>

  }];
  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }} key={id}>
        <Button
          shape="circle"
          className="flex-button"
          type="text"
          icon={<IoMdMore style={{ fontSize: 20 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default ListOptions;
