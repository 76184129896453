import React from "react";

const ProfileAvatar = ({ name, size = 30, font = 14 }) => {
  const getInitials = (name) => {
    const namesArray = name.trim().split(" ");
    if (namesArray.length === 1) {
      return namesArray[0][0]?.toUpperCase();
    } else {
      return (
        namesArray[0][0]?.toUpperCase() +
        (namesArray[1] ? namesArray[1][0]?.toUpperCase() : "")
      );
    }
  };

  const getColor = (name) => {
    const colors = [
      "#FFB6C1",
      "#FF7F50",
      "#FFD700",
      "#90EE90",
      "#00CED1",
      "#87CEEB",
      "#9370DB",
    ];
    const hash = name
      .split("")
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[hash % colors.length];
  };

  const initials = getInitials(name);
  const backgroundColor = getColor(name);

  const avatarStyle = {
    backgroundColor,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${font}px`,
    fontWeight: "bold",
  };

  return (
    <div>
      <div style={avatarStyle}>{initials}</div>
    </div>
  );
};

export default ProfileAvatar;
