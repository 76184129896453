import { Space, Typography } from "antd";
import "./ErrorBox.css";

const ErrorBox = ({ title, text, children }) => {
  const { Text } = Typography;
  return (
    <div className="error-box">
      <Space className="w-100" direction="vertical" size="large">
        <Space direction="vertical" size={0}>
          <Text className="error-tittle">{title}</Text>
          <Text className="error-description">{text}</Text>
        </Space>
        {children}
      </Space>
    </div>
  );
};

export default ErrorBox;
