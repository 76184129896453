import { Button, Card, Flex, Space, Typography, message } from "antd";
import React, { useState } from "react";
import { DealActions, DealActionsHash } from "../../../../../shared/enums";
import AddNote from "./AddNote";
import { useLocation, useParams } from "react-router-dom";
import AddTaskDrawer from "../../../AddTaskDrawer";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import AddFile from "./AddFile";
import { FaFile, FaTimeline } from "react-icons/fa6";
import { PiNoteBlankFill } from "react-icons/pi";
import { GrTasks } from "react-icons/gr";
function getActionFromHash(hash) {
  if (hash === DealActionsHash.Note) return DealActions.Note;
  return null;
}
const DealDetailsActions = () => {
  const { hash } = useLocation();
  const { Text } = Typography;

  const { id } = useParams();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const [currentActiveAction, setCurrentActiveAction] = useState(
    getActionFromHash(hash)
  );

  const { setActivities, activites } = useDealsDetailsCtx();

  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.post(`/DealTasks/CreateTask`, {
      taskType: e.type,
      name: e.name,
      dueDate: e.dueDate,
      reminder: e.reminder,
      highPriority: e.taskPriority,
      notes: e.notes,
      ownerIds: e.assignedUser,
      dealId: id,
      dueTime: e.dueTime,
      reminderType: e.reminderType
    });

    if (response.data.success) {
      setActivities((prev) => [
        ...prev,
        {
          ind: activites.length,
          id: response.data.id,
          name: response.data.name,
          notes: response.data.notes,
          owners: response.data.owners,
          reminder: response.data.reminder,
          dueDate: e.dueDate,
          dueTime: e.dueTime,
          createdAt: response.data.createdAt,
          type: response.data.taskType,
          done: response.data.done,
          overDue: response.data.overDue,
        },
      ]);
      setCurrentActiveAction("");
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  return (
    <Card size="small">
      {(!currentActiveAction || currentActiveAction == DealActions.Task) && (
        <Flex align="center" gap={12} justify="end" style={{padding:5}}>
          <Space align="center" direction="vertical" size={0}>
            <Button
              style={{ paddingBottom: 0, marginBottom: 0 }}
              size="large"
              className="flex-button"
              onClick={() => setCurrentActiveAction(DealActions.Note)}
              icon={<PiNoteBlankFill style={{ fontSize: 20 }} />}
            ></Button>
            <Text>Note</Text>
          </Space>

          <Space align="center" direction="vertical" size={0}>
            <Button
              style={{ paddingBottom: 0, marginBottom: 0 }}
              size="large"
              type="link"
              className="flex-button"
              icon={<GrTasks style={{ fontSize: 20 }} />}
              onClick={() => setCurrentActiveAction(DealActions.Task)}
            ></Button>
            <Text>Task</Text>
          </Space>

          <Space align="center" direction="vertical" size={0}>
            <Button
              style={{ paddingBottom: 0, marginBottom: 0 }}
              size="large"
              type="link"
              className="flex-button"
              icon={<FaFile style={{ fontSize: 20 }} />}
              onClick={() => setCurrentActiveAction(DealActions.File)}
            ></Button>
            <Text>File</Text>
          </Space>
        </Flex>
      )}
      {currentActiveAction === DealActions.Note && (
        <AddNote setCurrentActiveAction={setCurrentActiveAction} />
      )}

      {currentActiveAction === DealActions.File && (
        <AddFile setCurrentActiveAction={setCurrentActiveAction} />
      )}

      <AddTaskDrawer
        open={currentActiveAction === DealActions.Task}
        setOpen={setCurrentActiveAction}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Card>
  );
};

export default DealDetailsActions;
