import { Outlet } from "react-router-dom";
import { AudienceContextProvider } from "./AudienceContext";

const ContactLayout = () => {
  return (
    <>
      <AudienceContextProvider>
        <Outlet />
      </AudienceContextProvider>
    </>
  );
};

export default ContactLayout;
