import { Outlet } from "react-router-dom";
import { NotificationTemplatesContextProvider } from "../../templates/Notifications/NotificationTemplatesContext";
import { CreateFollowupContextProvider } from "./CreateFollowupContext";

const CreateFollowUpStepLayout = () => {
  return (
    <>
      <NotificationTemplatesContextProvider>
          <CreateFollowupContextProvider>
            <Outlet />
          </CreateFollowupContextProvider>
      </NotificationTemplatesContextProvider>
    </>
  );
};

export default CreateFollowUpStepLayout;
