import { Flex, Input, Grid } from "antd";
import { useContext } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { SearchContext } from "../../../context/SearchContext";

const { useBreakpoint } = Grid;

const Search = () => {
  const { search, setSearch } = useContext(SearchContext);
  const screens = useBreakpoint();

  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        marginRight: 5,
        width: screens.xs ? "100%" :"250px",
        background:"#ffffff55",
        borderRadius:"10px"
            }}
    >
      <Flex align="center" justify="center" style={{ width: "40px" }}>
        <IoSearchOutline style={{ fontSize: 18 }} />
      </Flex>
      <Input onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder="Search..."
        size="large"
        style={{
          background: "transparent",
          border: "none",
        }}
      />
    </Flex>
  );
};

export default Search;
