import { Avatar, Flex, Typography } from "antd"
import ln from '../../../assets/icons/us.png'
const LanguageSelector = () =>{
    const {Text} = Typography

    return <Flex align="center" justify="start" gap={5}>
        <Avatar src={ln}/>
        <Text strong>En</Text>
    </Flex>
}

export default LanguageSelector