import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
export const CompanyStepsContext = createContext({
  currentStep: 1,
  setCurrentStep: () => {},

  basics: {
    firstName: "",
    lastName: "",
    companyName:"",
    companyWebsite: "",
    companyHasWebsite: false
  },
  setBasics: () => {},

  company: {
    address: "",
    zip:"",
    city: "",
    country: '',
    countryCode: ''
  },
  setCompany: () => {},

  organisation: {
    noTeam: null,
    noAudiences: null,
    onlineStore: null,
    recieveUpdates: null
  },
  setOrganisation: () => {},

  phone: null,
  setPhone: () => {},

  tenant: null,
  setTenant: () => {}
});

export const CompanyStepsContextProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [tenant, setTenant ]= useState();

  const [basics, setBasics] = useState({

  })

  const axios = useAxiosPrivate()

  useEffect(() => {
    const getCurrenyCompany = async () => {
      let response = await axios.get('/tenant/current');
      if(response.data.success){
        setTenant(response.data)
      } 
    } 

    getCurrenyCompany();
  }, [])

  const [company, setCompany] = useState()
  const [organisation, setOrganisation] = useState()

  const [phone, setPhone] = useState()

  return (
    <CompanyStepsContext.Provider value={{
      currentStep,
      setCurrentStep,

      basics,
      setBasics,

      company, 
      setCompany,

      organisation,
      setOrganisation,

      phone,
      setPhone,

      tenant,
      setTenant
    }}>
      {children}
    </CompanyStepsContext.Provider>
  );
};
