import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { OrderDirection } from "../../shared/enums";
import { SearchContext } from "../../context/SearchContext";
import { AuthorContext } from "../../context/AuthorsContext";
import QueryString from "qs";

export const AudienceListContext = createContext({
  stats: {
    total: 0,
    first: null,
    last: null,
    authorName: "",
    authorImage: "",
  },
  setStats: () => {},

  filters: {
    selectedAuthors: [],

    from: null,
    to: null,

    orderBy: null,
    orderDirection: null,
  },
  setFilters: () => {},

  audiences: [],
  setAudiences: () => {},

  loading: false,
  setLoading: () => {},

  take: 16,
  setTake: () => {},
});

export const AudienceListContextProvider = ({ children }) => {
  const { you } = useContext(AuthorContext);
  const { debouncedValue } = useContext(SearchContext);
  const axios = useAxiosPrivate();

  const [filters, setFilters] = useState(() => ({
    orderDirection: OrderDirection.Dsc,
    orderBy: "Created",
    selectedAuthors: [you],
  }));
  const [stats, setStats] = useState();

  const [take, setTake] = useState(16)

  const [loading, setLoading] = useState(false);
  const [audiences, setAudiences] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchAudiences = async () => {
        setLoading(true);
    
        try {
          const response = await axios.get("/TargetAudience/GetTargetAudiences", {
            params: {
                take: take,
              search: debouncedValue,
              authors: filters?.selectedAuthors,
              from: filters?.from ? new Date(filters?.from) : null,
              to: filters?.to ? new Date(filters.to) : null,
              sortDirection: filters?.orderDirection,
              orderBy: filters?.orderBy,
            },
            signal: signal,
            paramsSerializer: (params) => {
              return QueryString.stringify(params, {
                arrayFormat: "repeat",
                skipNulls: true,
              });
            },
          });
        
          setAudiences(response.data.items);
          
          setStats((prev) => ({
            ...prev,
            total: response.data.total,
            first: response.data.first,
            last: response.data.last,
            authorName: response.data.authorName,
            authorImage: response.data.authorProfile,
          }));
        } catch (err) {
        } finally {
          setLoading(false);
        }
      };

      fetchAudiences();

    return () => {
      controller.abort();
    };
  }, [filters, debouncedValue, take]);

  return (
    <AudienceListContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,

        take, 
        setTake,

        audiences,
        setAudiences,

        loading,
        setLoading,
      }}
    >
      {children}
    </AudienceListContext.Provider>
  );
};
