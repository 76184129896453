import { Avatar, Col, Row } from "antd";
import Stat from "../../shared/Stat";
import { useContext } from "react";
import { ProductListContext } from "./ProductListContext";
import { MdCampaign, MdFirstPage, MdLastPage } from "react-icons/md";
import env from '../../env.json'
import { DateFormat } from "../../dateformat";
import {UserOutlined} from '@ant-design/icons'
import { FaProjectDiagram } from "react-icons/fa";

const ProductStats = () => {
    const {stats} = useContext(ProductListContext)

    return <>
    <Row gutter={[24,24]}>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.total}
            name="Total"
            icon={<FaProjectDiagram style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={new Date(stats?.first).toLocaleDateString("en-US", DateFormat)}
            name="First"
            icon={<MdFirstPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={new Date(stats?.last).toLocaleDateString("en-US", DateFormat)}
            name="Last"
            icon={<MdLastPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.authorName}
            name="Most authored"
            icon={<Avatar icon={<UserOutlined/>} size="large" src={`${env.fileUpload}${stats?.authorImage}`} />}
            loading={false}
          />
        </Col>
      </Row>
    </>
}
export default ProductStats;