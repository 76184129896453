import { Input, Select, Space, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { WhatsAppTemplateContext } from "../../../../context/WhatsAppTemplateContext";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const Name = () => {
  const { Text } = Typography;
  const { template, setTemplate } = useContext(WhatsAppTemplateContext);

  const [numbers, setNumbers] = useState();
  const [languages, setLanguages] = useState();

  const axios = useAxiosPrivate();

  useEffect(() => {
    const fetchNumbers = async () => {
      const response = await axios.get(
        `/Whatsapp/numbers?Page=1&PageSize=9999`
      );

      setNumbers(
        response.data.data.map((c) => ({
          label: `${c.number} | ${c.name}`,
          value: c.id,
        }))
      );
    };

    const fetchLanguages = async () => {
      const response = await axios.get(`/Language`);
      setLanguages(
        response.data.data.map((c) => ({
          label: `${c.name} | ${c.code.toUpperCase()}`,
          value: c.id,
        }))
      );
    };

    fetchLanguages();
    fetchNumbers();
  }, []);

  return (
    <Space>
      <Space direction="vertical" size={0} style={{ width: "350px" }}>
        <Text>Template name</Text>
        <Input
          size="large"
          placeholder="Enter template name"
          value={template?.name}
          onChange={(e) =>
            setTemplate((prev) => ({ ...prev, name: e.target.value }))
          }
          count={{
            show: true,
            max: 512,
          }}
          allowClear
        />
      </Space>

      <Space direction="vertical" size={0} style={{ width: "350px" }}>
        <Text>Meta business</Text>
        <Select
          value={template?.senderId}
          onChange={(e) => setTemplate((prev) => ({ ...prev, senderId: e }))}
          size="large"
          style={{ width: "100%" }}
          options={numbers}
        />
      </Space>

      <Space direction="vertical" size={0} style={{ width: "350px" }}>
        <Text>Language</Text>
        <Select
          value={template?.languageId}
          onChange={(e) => setTemplate((prev) => ({ ...prev, languageId: e }))}
          size="large"
          style={{ width: "100%" }}
          options={languages}
        />
      </Space>
    </Space>
  );
};

export default Name;
