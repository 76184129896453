import { Button, Card, Flex, Modal, Result } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const AudiencePaymentDone = () => {
  const { type, amount, price } = useParams();
  const navigate = useNavigate();

  return <Modal open={true} footer={null} onCancel={() => navigate("/audience")}>

   <Flex
  align="center"
  justify="center"
  style={{ width: "100%" }}
>
  <Result
    status="success"
    title="Successfully Purchased Audience Subscription!"
    subTitle="Number configuration takes 1-5 minutes, please wait."
    extra={[
      <Button
        type="primary"
        key="console"
        size="large"
        onClick={() => navigate("/audience")}
      >
        Check audiences
      </Button>,
    ]}
  />
  </Flex>
  </Modal>

};

export default AudiencePaymentDone;
