import { Select } from "antd";
import { MdArrowDropDown } from "react-icons/md";
import { useContext } from "react";
import { AudienceContext } from "../AudienceContext";
import { OrderDirection } from "../../../shared/enums";

const ContactOrderDirectionFilter = () => {
  const { filters, setFilters } = useContext(AudienceContext);

  return (
    <Select
      size="large"
      default={filters?.orderDirection}
      defaultValue={filters?.orderDirection}
      style={{ width: "150px" }}
      className="order-by-select"
      suffixIcon={
        <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
      }
      onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
      options={[
        {
          value: OrderDirection.Asc,
          label: "A - Z",
        },
        {
          value: OrderDirection.Dsc,
          label: "Z - A",
        },
      ]}
    />
  );
};

export default ContactOrderDirectionFilter;
