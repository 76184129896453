import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext } from "react";
import { DealListContext } from "../DealListContext";
import { CardStatus } from "../../../shared/enums";

const DeleteList = ({ id, onDelete, deal}) => {
  const {stats, setStats} = useContext(DealListContext)

  const axios = useAxiosPrivate();

  const submit = async () => {
    const response = await axios.delete(`/Deal/${id}`);

    if (response.data.success) {
      
      if(deal.stage == CardStatus.New){
        setStats(prev => ({
            ...prev,
            total: prev.total - 1,
            new: prev.new - 1
        }))
    }else if(deal.stage ==  CardStatus.Qualifying){
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            qualifying: prev.qualifying -1
        }))
    }
    else if(deal.stage == CardStatus.DemoScheduled){
        setStats(prev => ({
            ...prev, 
            total: prev.total -1,
            demoScheduled: prev.demoScheduled -1
        }))
    }else if(deal.stage == CardStatus.PendingCommitment){
        setStats(prev => ({
            ...prev,
            total: prev.total-1,
            pendingCommitment: prev.pendingCommitment -1
        }));
    }else if(deal.stage == CardStatus.InNegotiation){
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            inNegotiation: prev.inNegotiation -1
        }));
    }else if(deal.stage == CardStatus.Won){
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            won: prev.won -1
        }));
    }else{
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            lost: prev.lost -1
        }));
    }


      onDelete(id);
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      <Popconfirm
        onConfirm={() => submit()}
        okText="Yes, delete it"
        title="Are you sure?"
        description="Are you sure you wan to delete the selected deal?"
      >
        Delete selected deal
      </Popconfirm>
    </>
  );
};

export default DeleteList;
