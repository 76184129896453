import { Button, Flex, Modal, Space, Typography } from "antd";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CallNumberModal = ({ open, setOpen, fullName, number }) => {
  const { Text, Title } = Typography;

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submit = () => {
    navigate(`/dial/number?number=${number}`)
  };

  return (
    <>
      <Modal
        width={300}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        <Flex align="center" justify="center" vertical gap={6}>
          <ProfileAvatar name={fullName} size={100} font={38} />
          <Space direction="vertical" size={0} align="center">
            <Title className="zero-margin-padding" level={3}>
            {number}
            </Title>

            <Text className="bold">{fullName}</Text>
          </Space>

          <Text className="text-center call-description-text">
            You're about to start a video call with your contact—get ready for a
            seamless face-to-face connection.
          </Text>
          <Space direction="vertical" className="w-100 button-margin-top">
            <Button
              loading={loading}
              onClick={submit}
              type="primary"
              size="large"
              style={{background:"#6fd474"}}
              className="w-100 bold"
            >
              Call
            </Button>
            <Button
              onClick={() => setOpen(false)}
              size="large"
              className="w-100 bold"
            >
              Cancel
            </Button>
          </Space>
        </Flex>
      </Modal>
    </>
  );
};

export default CallNumberModal;
