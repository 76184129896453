import {
  Button,
  Flex,
  Form,
  Modal,
  Typography,
  Input,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { WhatsappTemplateType } from "../../../shared/enums";

const CreateWhatsappTemplateModal = ({ open, setOpen }) => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const [languages, setLanguages] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchLangauges = async () => {

        const response = await axios.get(`/Language`, {
            signal: signal
        });

        setLanguages(response.data.data)
    }

    fetchLangauges();

    return () => controller.abort();
  },[])
  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post(`/Whatsapp/Templates`, {
        name: e.name,
        languageId: e.languageId,
        type:e.type
    });

    if (response.data.success) {
      navigate(`/Templates/Create/Whatsapp/${response.data.id}`);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title className="zero-margin-padding" level={4}>
          Create whatsapp desing
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button size="large" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Form onFinish={submit} form={form}>
        <Text className="bold">Name</Text>
        <Form.Item name="name" rules={[{
            min:3
        }, {max:128}]}>
          <Input
            size="large"
            count={{
              show: true,
              max: 128,
            }}
            allowClear
            placeholder="Enter a name you remember"
          />
        </Form.Item>

        <Text className="bold">Language</Text>
        <Form.Item name="languageId" rules={[{
            required:true,
            message:"Language is required"
        }]}>
          <Select
            size="large"
            count={{
              show: true,
              max: 128,
            }}
            options={languages?.map(c => ({
                label: c.name,
                value: c.id
            }))}
            filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            allowClear
            showSearch
            placeholder="Enter desing language"
          />
        </Form.Item>

        <Text className="bold">Type</Text>
        <Form.Item name="type" rules={[{
            required:true,
            message:"Template type is required"
        }]}>
          <Select
            size="large"
            placeholder="Enter desing type"
            allowClear
            options={[{
                value: WhatsappTemplateType.Utility,
                label: "Utility"
            }, {
                value: WhatsappTemplateType.Marketing,
                label: "Marketing"
            }]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateWhatsappTemplateModal;
