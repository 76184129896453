import { Card, Col, Row, Space, Steps } from "antd";
import { useState } from "react";
import PhoneNumber from "./PhoneNumber";
import FacebookAccount from "./FacebookAccount";
import BusinessDetails from "./BusinessDetails";

const NewWhatsAppSender = () => {
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: "Phone number",
      description: "Provide a unique phone number",
    },
    {
      title: "Facebook account",
      description: "Connect your facebook account with TAPZAP",
    },
    {
      title: "Business details",
      description: "Provide business details",
    },
  ];

  const [loading, setLoading] = useState(false);

  return (
    <Row gutter={12}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
        <Card title="Create WhatsApp sender">
          <Steps
            direction="vertical"
            items={steps}
            current={current}
            onChange={(c) => setCurrent(c)}
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
        <Card title={[steps[current].title]}>
          {current == 0 && (
            <PhoneNumber current={current} setCurrent={setCurrent} />
          )}
          {current == 1 && (
            <FacebookAccount current={current} setCurrent={setCurrent} />
          )}
          {current == 2 && (
            <BusinessDetails current={current} setCurrent={setCurrent} loading={loading} setLoading={setLoading}/>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default NewWhatsAppSender;
