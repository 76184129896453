import {
  Button,
  Card,
  Flex,
  Space,
  Table,
  Typography,
  message,
  Grid
} from "antd";
import { useContext, useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import SelectCountry from "./SelectCountry";
import { FiPlus } from "react-icons/fi";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import TableLoading from "../../../../shared/TableLoading";
import { DateFormat } from "../../../../dateformat";
import { FaCheck } from "react-icons/fa";
import SmsSenderOptions from "./SmsSenderOptions";
import useDebounce from '../../../../hooks/useDebounce'
import { SearchContext } from "../../../../context/SearchContext";
import EditSenderName from "./EditSenderName";

const { useBreakpoint } = Grid;

const SmsSenderList = () => {
  const {debouncedValue} = useContext(SearchContext)
  const { Title, Text } = Typography;
  const [addOpen, setAddOpen] = useState(() => false);
  const [editOpen, setEditOpen] = useState(() => false);
  const [current, setCurrent] = useState();

  const screens = useBreakpoint();

  const [loading, setLoading] = useState(() => false);
  const [numbers, setNumbers] = useState();

  const axios = useAxiosPrivate();
  const perPage = 10;
  const [page, setPage] = useState(() => 1);
  const [total, setTotal] = useState();
  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(
      `/PhoneNumbers/numbers/${page}/${perPage}?search=${debouncedValue}`
    );
    setLoading(false);

    setNumbers(response.data.data);
    setTotal(response.data.count);
  }, [page, debouncedValue]);

  const columns = [
    {
      title: "Number",
      render: (data, row, index) => <Text strong>{row.phoneNumber}</Text>
    },
    {
      title: "Name",
      responsive:['sm'],
      render: (data, row) => <Text strong>{row.name ?? '-'}</Text>
    },
    {
      title: "Default",
      responsive:['sm'],
      width: 120,
      render: (data, row, index) => (
        <>
          {row.default && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          )}
          {!row.default && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <MdOutlineClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
        </>
      ),
    },
    {
      title: "Region",
      responsive:['sm'],
      render: (data, row, index) => (
        <Flex align="center" justify="center" gap={10}>
          <img src={`https://flagsapi.com/${row?.countryIso}/flat/32.png`} />
        </Flex>
      ),
    },
    {
      title: "Last payment",
      responsive:['md'],
      render: (data, row, index) => (
        <Text strong>
          {row.lastPayment
            ? new Date(row.lastPayment).toLocaleDateString("en-US", DateFormat)
            : "-"}
        </Text>
      ),
    },
    {
      title: "Next payment",
      responsive:['md'],
      render: (data, row, index) => (
        <Text strong>
          {row.nextPayment
            ? new Date(row.nextPayment).toLocaleDateString("en-US", DateFormat)
            : "-"}
        </Text>
      ),
    },
    {
      title: "Paid",
      responsive:['lg'],
      width: 120,
      render: (data, row, index) => (
        <>
          {row.status == 2 && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          )}
          {row.status != 2 && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <MdOutlineClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
        </>
      ),
    },
    {
      title: "Date",
      responsive:['lg'],
      render: (data, row) => <Text strong>{new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}</Text>
    },
    {
      title: "",
      width: 10,
      render: (data, row, index) => (
        <Space>
          <SmsSenderOptions onSetDefault={onSetDefault}
            id={row.id}
            onDelete={onDelete}
            status={row.status}
            lat={row.lat}
            long={row.long}
            showEdit={setEditOpen}
            setCurrent={setCurrent} />
        </Space>
      ),
    },
  ];

  const onDelete = (id) => {
    setNumbers(prev => prev.map(c => c.id === id ? {
      ...c,
      status: 4
    } : c));
  }

  const onSetDefault = async (id) => {
    const response = await axios.put(`/PhoneNumbers/numbers/${id}/default`);

    if (response.data.success) {
      setNumbers((prev) =>
        prev.map((d) =>
          d.id == id
            ? {
              ...d,
              default: true,
            }
            : { ...d, default: false }
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const updateName = (id, name) => {
    setNumbers(prev => prev.map(c => c.id == id ? { ...c, name: name } : c))
  }

  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex align="center" justify="space-between" wrap="wrap"
              style={{
                ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
              }}>
              <Title level={4} style={{ margin: 0 }}>
                Senders
              </Title>

              <Button
                type="primary"
                size="large"
                block={screens.xs}
                icon={<FiPlus style={{ fontSize: 20 }} />}
                onClick={() => setAddOpen(true)}
                className="primary-gradient-background flex-button bold-button"
              >
                New sender
              </Button>
            </Flex>
          }
        ></Card>

        <Card className="zero-margin-padding">
          {loading && <TableLoading />}

          {!loading && (
            <Table
              columns={columns}
              dataSource={numbers}
              pagination={{
                pageSize: perPage,
                total: total,
                current: page,
                onChange: (page, pageSize) => {
                  setPage(page);
                },
              }}
            />
          )}
        </Card>
      </Space>

      <SelectCountry open={addOpen} setOpen={setAddOpen} />
      <EditSenderName onUpdate={updateName} open={editOpen} id={current} setOpen={setEditOpen} />
    </>
  );
};

export default SmsSenderList;
