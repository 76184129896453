import React from 'react'
import { MdEmail, MdLunchDining, MdMeetingRoom } from "react-icons/md";
import { FaFlag, FaPhone } from "react-icons/fa6";
import { TaskStatus } from './enums';
import { FaCheckCircle } from 'react-icons/fa';
const getTaskTypeIcon = (type,size,color) => {
    if(type == TaskStatus.ToDo) return <FaCheckCircle size={size} style={{color:color}}/>
    if(type == TaskStatus.Call) return <FaPhone size={size} style={{color:color}}/>
    if(type == TaskStatus.Deadline) return <FaFlag size={size} style={{color:color}}/>
    if(type == TaskStatus.Email) return <MdEmail  size={size} style={{color:color}}/>
    if(type == TaskStatus.Lunch) return <MdLunchDining size={size} style={{color:color}}/>
    if(type == TaskStatus.Meeting) return <MdMeetingRoom size={size} style={{color:color}}/>
 
}

export default getTaskTypeIcon