import { Button, Dropdown, message } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import DeleteWhatsAppTemplate from "./DeleteWhatsAppTemplate";

const WhatsAppTemplateOptions = ({id, onDelete}) => {

  const axios = useAxiosPrivate();

  const deleteTemplate = async (id) => {
    const response = await axios.delete(`/Whatsapp/template/${id}`);

    if(response.data.success){
      onDelete(id);
    }else{
      message.error(response.data.message)
    }
  };

    const items = [
        {
          label:<DeleteWhatsAppTemplate id={id} onSuccess={onDelete}/>,
          key: "3",
          danger: true,
          icon: <BsTrashFill />
        },
      ];

    return <>
    <Dropdown trigger={["click"]} menu={{ items }} type="link" >
        <Button 
          shape="circle"
          className="flex-button"
          type="text"
          icon={<IoMdMore  style={{ fontSize: 20 }} />}
        ></Button>
      </Dropdown>
    </>
}
export default WhatsAppTemplateOptions;