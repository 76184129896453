import { Button, Flex, Space, Typography } from "antd";
import {
  MdArrowBack,
  MdOutlineArrowForward,
} from "react-icons/md";
import React, { useContext } from "react";
import { WhatsAppTemplateContext } from "../../../../context/WhatsAppTemplateContext";
import SmartText from "../../../../components/SmartText";
import { Editor } from "@tinymce/tinymce-react";

const Body = ({ setCurrent }) => {
  const { template, setTemplate } = useContext(WhatsAppTemplateContext);

  const { Text, Title } = Typography;

  const characterCount = template?.body
    ?.getCurrentContent()
    ?.getPlainText("")?.length;

  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical">
        <Flex align="center" gap={10} justify="space-between">
          <Space style={{ width: "100%" }} direction="vertical" size={0}>
            <Title style={{ margin: 0 }} level={3}>
              Body
            </Title>
            <Text>
              Enter the text for your message in the language you've selected.
            </Text>
          </Space>

          <Space>
            <SmartText
              text={template.body}
              setText={(e) => {
                setTemplate((prev) => ({ ...prev, body: e }));
              }}
            />

            {/*  
            <EmojiSelect />
          
          */}
          </Space>
        </Flex>

        <Editor
          className="subject-text-box"
          apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
          onEditorChange={(e) => setTemplate((prev) => ({ ...prev, body: e }))}
          value={template.body}
          init={{
            plugins: "",
            toolbar: true,
            menubar: false,
            height: 100,
            statusbar: false,
            height: 400,
          }}
        />
        <Flex justify="space-between" align="center">
          <Text>Characters {characterCount}/1024</Text>
        </Flex>
        <Flex align="center" justify="space-between">
          <Button
            className="flex-button"
            type="link"
            icon={<MdArrowBack />}
            onClick={() => setCurrent("1")}
          >
            Back to header
          </Button>

          <Button
            type="primary"
            icon={<MdOutlineArrowForward />}
            className="flex-button"
            onClick={() => setCurrent("3")}
          >
            Continue to Footer
          </Button>
        </Flex>
      </Space>
    </>
  );
};
export default Body;
