import { Button, Flex, Result } from "antd";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Flex
      style={{ width: "100%", height: "100vh" }}
      align="center"
      justify="center"
    >
      <Result
        status="403"
        title="TAPZAP 403 - NOT AUTHORIZED"
        subTitle="Sorry, you currently dont have the required permissions to use this page."
        extra={
          <Button size="large" onClick={() => goBack()} type="primary">
            Back Home
          </Button>
        }
      />
    </Flex>
  );
};

export default Unauthorized;
