import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Typography,
  Flex,
  Card,
  Space,
  Avatar,
  Tag,
  Tooltip,
} from "antd";
import { MdEmail, MdHandshake, MdSms } from "react-icons/md";
import { RiTaskFill, RiWhatsappFill } from "react-icons/ri";
import { FaArrowRight, FaBuilding, FaProjectDiagram } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApexCharts from "react-apexcharts";
import { BiSolidFactory, BiSolidNotification } from "react-icons/bi";
import kFormatter from "../../shared/kFormat";
import { ReactComponent as FollowUpIcon } from "../../assets/icons/FollowUpIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as CampaignIcon } from "../../assets/icons/campaings.svg";
import { ReactComponent as DealsIcon } from "../../assets/icons/deals.svg";
import { ReactComponent as SendersIcon } from "../../assets/icons/senders.svg";
import { ReactComponent as MediaIcon } from "../../assets/icons/media.svg";
import { ReactComponent as AudienceIcon } from "../../assets/icons/audience.svg";
import { ReactComponent as DesignIcon } from "../../assets/icons/designs.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import cleanLogo from "../../assets/images/clean-logo.png";
import SideMenuIcon from "./SideMenuIcon";
import ButtonIcon from "../../shared/ButtonIcon";
import "./Sidemenu.css";

const SideMenu = ({ collapsed, setCollapsed }) => {
  const [current, setCurrent] = useState();
  const [currentChildren, setCurrentChildren] = useState();

  const { Title, Text } = Typography;

  const { Sider } = Layout;

  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxiosPrivate();

  const [tenant, setTenant] = useState();

  useEffect(() => {
    menuItems.forEach((c) => {
      if (c.key == location.pathname) {
        setCurrent(c);
        setCurrentChildren(c.children);
        return;
      }

      c.children?.forEach((ch) => {
        if (ch.key == location.pathname) {
          setCurrent(c);
          setCurrentChildren(ch);
          return;
        }
      });
    });

    const fetchTenant = async () => {
      const response = await axios.get(`/tenant/current`);
      setTenant(response.data);
    };

    fetchTenant();
  }, []);

  const menuItems = [
    {
      label: (
        <SideMenuIcon
          Icon={CampaignIcon}
          iconClass={"campaigns"}
          isActive={current?.key == "/Campaigns"}
        />
      ),
      key: "/Campaigns",
      text: "Campaigns",
      description: (
        <Text className="head-color">
          Check your campaign statistics daily, see all responses, ratings,
          clicks and reports here. You can edit and manage everything in the
          options panel.
        </Text>
      ),
    },
    {
      key: "3",
      label: (
        <SideMenuIcon
          iconClass={"design"}
          Icon={DesignIcon}
          isActive={current?.key == "3"}
        />
      ),
      text: "Designs",
      description: (
        <Text className="head-color">
          Tapzap desings allows you to create email, whatsapp, sms and
          notification templates for your campaign/followup needs.
        </Text>
      ),
      children: [
        {
          key: "/Templates/Email",
          label: "Email",
          icon: <MdEmail className="menu-icon icon-size" />,
        },
        {
          key: "/Templates/Whatsapp",
          label: "Whatsapp",
          disabled: true,
          icon: <RiWhatsappFill className="menu-icon icon-size" />,
        },
        {
          key: "/Templates/Sms",
          label: "Sms",
          icon: <MdSms className="menu-icon" />,
        },
        {
          key: "/Templates/Notification",
          label: "Notification",
          icon: <BiSolidNotification className="menu-icon" />,
        },
      ],
    },
    {
      key: "/audience",
      label: (
        <SideMenuIcon
          Icon={AudienceIcon}
          iconClass={"audience"}
          isActive={current?.key == "/audience"}
        />
      ),
      text: "Audiences",
      description: (
        <Text className="head-color">
          Unlock your potencial, Tapzap currently offers over 2 million +
          contacts to chose from, All available at the click of a fingertip
        </Text>
      ),
    },
    {
      key: "/Deals",
      label: (
        <SideMenuIcon
          iconClass="deal"
          Icon={DealsIcon}
          isActive={current?.key == "/Deals"}
        />
      ),
      text: "Leads",
      description: (
        <Text className="head-color">
          Our Deal Management page provides a streamlined interface designed to
          help you efficiently manage and track your business deals from
          initiation to closure. This page is your central hub for monitoring
          deal progress, collaborating with team members, and ensuring every
          opportunity is maximized.
        </Text>
      ),
      children: [
        {
          key: "/Deals/Management",
          label: "Deals",
          icon: <MdHandshake className="menu-icon icon-size" />,
        },
        {
          key: "/Deals/Tasks",
          label: "Tasks",
          icon: <RiTaskFill className="menu-icon icon-size" />,
        },
        {
          key: "/Deals/Products",
          label: "Products",
          icon: <FaProjectDiagram className="menu-icon icon-size" />,
        },
        {
          key: "/Deals/Companies",
          label: "Companies",
          icon: <FaBuilding className="menu-icon icon-size" />,
        },
      ],
    },
    {
      key: "/Followup",
      description: (
        <Text className="head-color">
          Tapzap followups allow you to track your client from singup all the
          way to end of thier jouney on your own application.
        </Text>
      ),
      label: (
        <SideMenuIcon
          iconClass="followUp"
          Icon={FollowUpIcon}
          isActive={current?.key == "/Followup"}
        />
      ),
      text: "Follow up",
    },
    {
      key: "/",
      label: (
        <SideMenuIcon
          iconClass="dashboard"
          Icon={DashboardIcon}
          isActive={current?.key == "/"}
        />
      ),
      text: "Dashboard",
      description: (
        <Space className="w-100" direction="vertical">
          <Card size="small" className="no-shadow">
            <Space className="w-100" size="large" direction="vertical">
              <Space size={0} direction="vertical" className="w-100">
                <Tooltip
                  title="Get started with flexible templates, drag and drop design
                  tools, and our built in, expert advice."
                >
                  <Text className="side-menu-menu-item-description zero-margin-padding" level={4}>
                    Design high-performace emails in minutes
                  </Text>
                </Tooltip>
              </Space>
              <Button
                type="primary"
                size="large"
                block
                onClick={() => navigate("/Campaigns")}
              >
                Email campaigns
              </Button>
            </Space>
          </Card>

          <Card size="small" className="no-shadow">
            <Space className="w-100" size="large" direction="vertical">
              <Space size={0} direction="vertical" className="w-100">
                <Tooltip title="Use our Smart Text to create your sms templates.">
                  <Text className="side-menu-menu-item-description zero-margin-padding" level={5}>
                    Send your first sms message
                  </Text>
                </Tooltip>
              </Space>
              <Button
               type="primary"
               size="large"
               block
               disabled
              >
                Sms campaigns
              </Button>
            </Space>
          </Card>

          <Card size="small" className="no-shadow">
            <Space className="w-100" size="large" direction="vertical">
              <Space size={0} direction="vertical" className="w-100">
                <Tooltip
                  title="Smart Text, drag and drop tools make your whatsapp experience
              better."
                >
                  <Text className="side-menu-menu-item-description pointer" level={5}>
                    Check out whatsapp features
                  </Text>
                </Tooltip>
              </Space>
              <Button
              type="primary"
              size="large"
              block
              disabled
              >
                Whatsapp
              </Button>
            </Space>
          </Card>
        </Space>
      ),
    },
  ];

  const media = {
    key: "/Media",
    label: (
      <SideMenuIcon
        Icon={MediaIcon}
        iconClass={"media"}
        isActive={current?.key == "/Media"}
      />
    ),
    text: "Media",
    description: (
      <Text className="head-color">
        Tapzap media secion allows you to upload, download and send media via
        email/whatsapp.
      </Text>
    ),
  };

  const senders = {
    key: "4",
    description: (
      <Text className="head-color">
        Tapzap senders allows you to have different senders for all your
        campaigns!
      </Text>
    ),
    label: (
      <SideMenuIcon
        Icon={SendersIcon}
        iconClass={"senders"}
        isActive={current?.key == "4"}
      />
    ),
    text: "Senders",
    children: [
      {
        key: "/Senders",
        label: "Email",
        icon: <MdEmail className="menu-icon icon-size" />,
      },
      {
        key: "/Senders/whatsapp",
        label: "Whatsapp",
        icon: <RiWhatsappFill className="menu-icon icon-size" />,
      },
      {
        key: "/Senders/sms",
        label: "Sms",
        icon: <MdSms className="menu-icon icon-size" />,
      },
    ],
  };

  const settings = {
    key: "/settings",
    label: (
      <SideMenuIcon
        Icon={SettingsIcon}
        iconClass={"settings"}
        isActive={current?.key == "/settings"}
      />
    ),
    text: "Settings",
    description: (
      <Text className="head-color">
        Settings is a space reserved for developers.
      </Text>
    ),
  };
  return (
    <>
      <Sider
        onMouseLeave={() => setCollapsed(true)}
        onMouseEnter={() => setCollapsed(false)}
        trigger={null}
        width={80}
        className="sider"
      >
        <Flex
          className="auto-overflow full-height"
          vertical
          justify="space-between"
          align="center"
        >
          <div>
            <Flex align="center" justify="center" className="top-margin-10">
              <img src={cleanLogo} className="logo" />
            </Flex>
            <Flex
              className="w-100 top-margin-20"
              align="center"
              vertical
              justify="center"
              gap={12}
            >
              {menuItems.map((c, i) => (
                <Tooltip placement="right" key={i} title={c.text}>
                  <Button
                    key={i}
                    onClick={() => {
                      setCurrent(c);
                      if (!c.children) {
                        navigate(c.key);
                      } else {
                        navigate(c.children[0].key);
                        setCurrentChildren(c.children[0]);
                      }
                    }}
                    className={`flex-button ${
                      current?.key == c.key ? "active-menu" : ""
                    } zero-margin-padding head-color`}
                    classNames="zero-margin-padding "
                    type="text"
                    size="large"
                    icon={c.label}
                  ></Button>
                </Tooltip>
              ))}
            </Flex>
          </div>

          <Flex vertical align="center" className="bottom-margin-12" gap={14}>
            <Tooltip placement="right" title={senders.text}>
              <Button
                key={"senders"}
                onClick={() => {
                  setCurrent(senders);
                  if (!senders.children) {
                    navigate(senders.key);
                  } else {
                    navigate(senders.children[0].key);
                    setCurrentChildren(senders.children[0]);
                  }
                }}
                className={`flex-button ${
                  current?.key == senders.key ? "active-menu" : ""
                } zero-margin-padding head-color`}
                type="text"
                size="large"
                icon={senders.label}
              ></Button>
            </Tooltip>
            <Tooltip placement="right" title={media.text}>
              <Button
                key={"media"}
                onClick={() => {
                  setCurrent(media);
                  if (!media.children) {
                    navigate(media.key);
                  } else {
                    navigate(media.children[0].key);
                    setCurrentChildren(media.children[0]);
                  }
                }}
                className={`flex-button ${
                  current?.key == media.key ? "active-menu" : ""
                } zero-margin-padding head-color`}
                type="text"
                size="large"
                icon={media.label}
              ></Button>
            </Tooltip>

            <Tooltip placement="right" title={settings.text}>
              <Button
                key={"settings"}
                onClick={() => {
                  setCurrent(settings);
                  if (!settings.children) {
                    navigate(settings.key);
                  } else {
                    navigate(settings.children[0].key);
                    setCurrentChildren(settings.children[0]);
                  }
                }}
                className={`flex-button ${
                  current?.key == settings.key ? "active-menu" : ""
                } zero-margin-padding head-color`}
                type="text"
                size="large"
                icon={settings.label}
              ></Button>
            </Tooltip>

            <Avatar
              size="large"
              shape="circle"
              className="company-avatar"
              icon={<BiSolidFactory />}
              src={`data:${tenant?.logoMimeType};base64,${tenant?.logoBase64}`}
            />
          </Flex>
        </Flex>
      </Sider>
      <div
        onMouseLeave={() => setCollapsed(true)}
        onMouseEnter={() => setCollapsed(false)}
      >
        <Sider
          width={240}
          trigger={null}
          collapsedWidth={0}
          collapsible
          collapsed={collapsed}
          className="sider"
        >
          <Flex
            vertical
            align="start"
            className="w-100 h-100"
            justify="space-between"
          >
            <Space direction="vertical" className="w-100">
              <div className="side-items-wrapper custom-margin-0-10">
                <Title level={3} className="head-color">
                  {current?.text}
                </Title>
              </div>

              <div className="custom-margin-0-10">{current?.description}</div>

              <Flex
                className="custom-margin-0-6"
                align="start"
                justify="start"
                gap={6}
                vertical
              >
                {current?.children?.map((c, i) => (
                  <Button
                    key={i}
                    disabled={c.disabled}
                    onClick={() => {
                      navigate(c.key);
                      setCurrentChildren(c);
                    }}
                    block
                    className={`${c.disabled ? 'disabled ' : ''} menu-child ${
                      currentChildren?.key == c.key ? "menu-child-active" : ""
                    }`}
                    type={currentChildren?.key == c.key ? "primary" : "text"}
                    size="large"
                    icon={c.icon}
                  >
                    {c.label}
                  </Button>
                ))}
              </Flex>
            </Space>
            <Flex align="center" justify="end" gap={12}>
              <div className="chart-card-wrapper w-100">
                <Flex align="center" justify="space-between" gap={6}>
                  <Flex align="center" justify="start" gap={6}>
                    <Space direction="vertical" size={0}>
                      <Title className="zero-margin-padding" level={5}>
                        {tenant?.name}
                      </Title>
                      <Text>{tenant?.userEmail}</Text>
                    </Space>
                  </Flex>
                </Flex>
              </div>
            </Flex>
          </Flex>
        </Sider>
      </div>
    </>
  );
};

export default SideMenu;
