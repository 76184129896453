import { Button, Popconfirm, message } from "antd";
import { useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DeleteWhatsAppTemplate = ({ id, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const handleDelete = async () => {
    setLoading(true);
    const response = await axios.delete(`/Whatsapp/template/${id}`);

    if (response.data.data) {
      message.success("Template was deleted!");
      onSuccess(id);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Popconfirm
      onConfirm={() => handleDelete()}
      title="Delete the template"
      okText="Yes, delete it"
      cancelText="No"
      description="Are you sure you want to delete this template?"
    >
      Delete selected template
    </Popconfirm>
  );
};

export default DeleteWhatsAppTemplate;
