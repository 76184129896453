import { useContext } from "react";
import { CompanyListContext } from "../CompanyListContext";
import { Flex, Select, Typography } from "antd";
import { AuthorContext } from "../../../context/AuthorsContext";
import { MdArrowDropDown } from "react-icons/md";

const CompanyAuthorFilter = () => {
  const { filters, setFilters } = useContext(CompanyListContext);
  const { Text } = Typography;
  const { authors } = useContext(AuthorContext);
  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      selectedAuthors: e,
    }));
  };

  return (
    <>
      <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>Authors</Text>
        <Select
           size="large"
           maxTagCount="responsive"
           className="order-by-select"
          value={filters?.selectedAuthors}
          defaultValue={filters?.selectedAuthors}
          placeholder="Select campaign filter authors"
          style={{ flexGrow:1 }}
          options={authors}
          mode="multiple"
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
          onChange={handleChanged}
        />
      </Flex>
    </>
  );
};

export default CompanyAuthorFilter;
