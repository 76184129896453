import env from "../../../env.json";
import { Dropdown, Avatar, Divider, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useLogout from "../../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { MdOutlineSettings, MdSecurity, MdLogout } from "react-icons/md";
import useAuth from "../../../hooks/useAuth";
import { FaUser } from "react-icons/fa";

const UserProfile = () => {
  const { Text } = Typography;
  const logout = useLogout();
  const navigate = useNavigate();
  const [user, setUser] = useState();

  const { setAuth } = useAuth();
  const axios = useAxiosPrivate();
  useEffect(async () => {
    const response = await axios.get("Users/CurrentUser");

    setUser(response.data.data);
    setAuth((prev) => ({
      ...prev,
      subType: response.data.data.subscriptionType,
    }));
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <div className="w-100">
          <p className="drop-down-text">
            👋 Hey, {user?.firstname} {user?.lastname}
          </p>
          <Divider style={{ margin: 0 }} />
        </div>
      ),
    },
    {
      key: "2",
      label: <p className="drop-down-text">Profile Settings</p>,
      icon: <MdOutlineSettings className="drop-down-icon"/>,
    },
    {
      key: "3",
      label: <p className="drop-down-text"  >Your Security</p>,
      icon: <MdSecurity className="drop-down-icon"/>,
    },
    {
      key: "4",
      danger: true,
      label: <p className="drop-down-text">Log Out</p>,
      icon: <MdLogout className="drop-down-icon"/>,
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key == 4) {
      logout();
    } else if (e.key == 2) {
      navigate("/Users/profile");
    } else if (e.key == 3) {
      navigate("/Users/Security");
    }
  };

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]}>
      <Avatar
        size="large"
        shape="circle"
        style={{ background: "#c6cfd6", color:"#607790", display:"flex", alignItems:"center", justifyContent:"center"}}
        icon={<FaUser  />}
        src={`${env.fileUpload}${user?.profileImagePath}`}
      />
    </Dropdown>
  );
};

export default UserProfile;
