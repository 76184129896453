import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { useState, useRef } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import LoginLogs from "./LoginLogs";
import { Button, Card, Col, Form, Row, Space, Typography, Input, message, Flex } from "antd";

const Security = () => {
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const navigate = useNavigate();

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }

    const response = await axiosPrivate.delete(`/Users/DeleteCurrentUser`);

    if (response && response.data.success) {
      navigate("/login");
    }
  };

  const handleChangePassword = async (e) => {
    setLoading(true);
    var response = await axiosPrivate.post("/Users/ChangePassword", {
      currentPassword: e.password,
      newPassword: e.newpassword,
      confirmPassword: e.confirm,
    });

    if (response && response.data.success && response.data.data) {
      message.success("Password was changed");
    } else {
      message.error(response.data.message)
    }
    setLoading(false);
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onDeleteConfirm={handleDeleteConfirm}
      />

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} xx={18}>
          <Space style={{ width: "100%" }} direction="vertical" size="large">
            <Card title="Change password">
              <Form onFinish={handleChangePassword}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Text>Current password</Text>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password size="large"/>
                  </Form.Item>

                  <Text>New password</Text>
                  <Form.Item
                    name="newpassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password size="large"/>
                  </Form.Item>

                  <Text>Confirm password</Text>
                  <Form.Item
                    name="confirm"
                    dependencies={["newpassword"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newpassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password size="large"/>
                  </Form.Item>
                    
                    <Flex align="center" justify="end">

                  <Button size="large" type="primary" htmlType="submit" loading={loading}>
                    Save changes
                  </Button>
                    </Flex>
                </Space>
              </Form>
            </Card>

            <LoginLogs />
          </Space>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6} xx={6}>
          <Card title="Delete account">
            <Space size="large" style={{ width: "100%" }} direction="vertical">
              <Text>
                Deleting your account is a permanent action and cannot be
                undone. If you are sure you want to <Text strong>delete</Text>{" "}
                your account, select the button below.
              </Text>
              <Button size="large" danger onClick={handleOpenModal}>
                I understand, delete my account
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Security;
