import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireConfirmEmail = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return auth?.emailConfirmed ? (
    <Outlet />
  ) : (
    <Navigate to="/confirm-email" state={{ from: location }} replace />
  );
};

export default RequireConfirmEmail;
