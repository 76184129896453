import { useContext } from "react";
import { TemplateContext } from "../TemplateContext";
import { DatePicker, Flex, Typography } from "antd";
import { MdArrowDropDown } from "react-icons/md";

const EmailTemplateToFilter = () => {
    const { filters, setFilters } = useContext(TemplateContext);
  const { Text } = Typography;
  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{color:"#a5b6d5"}}>To</Text>
      <DatePicker
        size="large"
        placeholder="Select created date start"
        defaultValue={filters?.to}
        value={filters?.to}
        className="dates-filter-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            to: e,
          }))
        }
      />
    </Flex>
  );
}

export default EmailTemplateToFilter