import { Button, DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { DealListContext } from "../DealListContext";
import { MdArrowDropDown, MdClear } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

const CreatedDateToFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(DealListContext);

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>To</Text>

      <DatePicker
        size="large"
        placeholder="Selec created end date"
        value={filters?.createdToDate}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            createdToDate: e,
          }))
        }
        className="dates-filter-select"
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
    </Flex>
  );
};

export default CreatedDateToFilter;
