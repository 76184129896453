import { useContext, useEffect, useState } from "react";
import { SubscribeContext } from "../../../context/SubscribeContext";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Result,
  Space,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const ApplyCouponModal = () => {
  const { showCoupon, setShowCoupon } = useContext(SubscribeContext);

  const [form] = Form.useForm();

  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();
  const [state, setState] = useState(() => "entry");
  const [successValues, setSuccessValues] = useState()

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.post(`/Coupon/submit`, {
      ...e,
      forAudience: true,
    });

    if (response.data.success) {
        setSuccessValues(response.data);
        setState("success");
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const resetState = () => {
    setLoading(false);
    setState("entry");
    form.setFieldValue("code", "");
  }
  return (
    <Modal
      open={showCoupon}
      onCancel={() => setShowCoupon(false)}
      title={(state == "entry")&&
        <Title level={4} style={{ margin: 0 }}>
          Apply subscription coupon
        </Title>
      }
      footer={(state == "entry")?
        <Flex align="center" justify="end" gap={12}>
          <Button onClick={() => setShowCoupon(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Submit
          </Button>
        </Flex> : null
      }
    >
      <Divider style={{ margin: "5px 0px" }}></Divider>

      {state == "entry" && (
        <Form form={form} onFinish={submit}>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Code</Text>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                },
                {
                  max: 120,
                  message: "Code should be 120 characters max",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter coupon code"
                count={{
                  show: true,
                  max: 120,
                }}
              />
            </Form.Item>
          </Space>
        </Form>
      )}

      {state == "success" && <SubSuccess resetState={resetState}
          amount={successValues.value}
          name={successValues.couponName}
          lifetime={successValues.audienceCouponLifetime}
          setIsOpen={setShowCoupon}/>}
    </Modal>
  );
};

const SubSuccess = ({amount, name, lifetime, setIsOpen, resetState}) => {
    const refresh = () => {
        window.location.reload();
    }
    return <>
     {<Result
        status="success"
        title={`Coupon ${name} worth ${amount}$ was successful submited into your account`}
        subTitle={`Horray you are now a TAPZAP PRO member, ${(lifetime == 1 ? "this subscription will end after ONE month" : lifetime == 2 ? "this subscription will end after SIX months" : lifetime == 3 ? "this subscription will end after ONE year" : "this subscription will never end")}.`}
        extra={[
          <Button
            size="large"
            onClick={() => {
              setIsOpen(false);
              resetState();
            }}
          >
            Continue
          </Button>,
          <Button onClick={() => refresh()} size="large" type="primary">
            Refresh application
          </Button>
        ]
    }
    />}
    </>
}

export default ApplyCouponModal;
