export const EmailEditorOptions = {
    apiKey: "1JIEPtRKTHWUcY5uMLY4TWFs2JHUbYjAcZIyd6ubblfukgU6XfAQkceYXUzI1DpR",
    projectId: 119381,
    customCSS: `
      .blockbuilder-content-tool{
        border-radius: 10px !important;
        font-weight: bold !important;
      }

      .blockbuilder-content-tool-name, .nav-icon, .nav-name {
        color: #1b254b !important;
        font-weight: bold !important;
      }

      .tab-content{
      background-color:white !important;
      }

      .blockbuilder-content-tool-icon{
        color: #7586e0 !important;
      }

      .blockbuilder-content-tool:hover{
        outline:3px solid #7586e0 !important;
        box-shadow: none !important;
        color:#7586e0 !important;
      }

      .blockbuilder-branding{
        display: none !important;
      }

      .nav-tabs-bg{
      background: #f9f9f9 !important;}


      .blockbuilder-placeholder .blockbuilder-placeholder-empty{
        background: #7586e055 !important;
        color: #7586e0 !important;
        font-size: 16px !important;
        border: 2px solid #7586e0 !important;
      border-radius:10px !important;
        }

      .sc-gfoqjT {
        background: #7586e0 !important;
        border-radius:10px !important;
        }

        .blockbuilder-layer-add-row, .blockbuilder-layer-add-row-top{
          background: #7586e0 !important;
        
        }
      .blockbuilder-layer-drag, .blockbuilder-layer-drag-rows{
        background:#7586e0 !important;
      }
         .blockbuilder-layer-selected::after{
          outline: 2px solid #7586e0 !important;
        }

        .text{
          background: #7586e0 !important;
        }

       .blockbuilder-layer-selectable:not(.blockbuilder-layer-selected) .blockbuilder-layer-selector:hover:after{
          outline: 2px solid #7586e066 !important;
          border-radius: 5px !important;
        }

        .blockbuilder-layer .blockbuilder-layer-selected > .blockbuilder-layer-selector:after{
          outline: 2px solid #7586e0 !important;
          border-radius: 5px !important;
        }

        .blockbuilder-layer-selectable .blockbuilder-layer-selector:after  {
          outline: 2px solid #7586e0 !important;
          border-radius: 5px !important;
        }
    `,

    branding: false,
  };

