import {
  Button,
  Card,
  Col,
  Form,
  Flex,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  Select,
  message,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { FaCheck, FaUser } from "react-icons/fa";
import { MdClose, MdEmojiEmotions } from "react-icons/md";
import { CreateFollowupContext } from "./CreateFollowupContext";
import Iphone from "../../../shared/Iphone";
import TextArea from "antd/es/input/TextArea";
import Picker from "@emoji-mart/react";
import { Option } from "antd/es/mentions";
import "./SubjectStep.css";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
const SubjectStep = ({ active, setStep }) => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();

  const [form] = Form.useForm();

  const { details, senders, setDetails } = useContext(CreateFollowupContext);

  const [tempSubject, setTempSubject] = useState("");
  const [subjectCount, setSubjectCount] = useState(0);

  function getDomainFromEmail(email) {
    const domain = email.split("@")[1];
    return domain;
  }

  useEffect(() => {

    form.setFieldValue('subject', details?.subject)
    form.setFieldValue('sender', details?.senderId)

    setSubjectCount(details?.subject?.length ?? 0)
    setTempSubject(details?.subject)
  }, [details?.senderId, details?.subject])

  const [loading, setLoading] = useState(false);
  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put("/Followup/Step/Assing/SubjectAndSender", {
      id: details?.id,
      senderId: e.sender,
      subject: e.subject,
    });

    if (response.data.success) {
        setStep();
        setDetails(prev => ({
            ...prev,
            subject: e.subject,
            senderId: e.sender,
            senderEmail: senders.find(c => c.id == e.sender)?.email
        }))
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Card
        size="small"
        className={`step-card subject-card no-shadow ${
          active ? "active-step" : ""
        }`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            {details?.subject && details?.senderId ? (
              <div className="step-evaluation-box-success">
                <FaCheck />
              </div>
            ) : (
              <div className="step-evaluation-box-default"></div>
            )}
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Subject & Sender
              </Title>
              {details?.subject ? (
                <Text>
                  {details?.subject} • {details?.senderEmail}
                </Text>
              ) : (
                <Text>Add a subject line for this step.</Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={() => setStep()}
              className="flex-button"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <Button size="large" onClick={() => setStep(4)}>
              Add subject
            </Button>
          )}
        </Flex>

        {active && (
          <>
            <div className="subject-work-wrapper">
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Space className="w-100" direction="vertical" size={0}>
                    <Form form={form} onFinish={submit}>
                      <Text className="thick-lable">Subject line*</Text>
                      <SubjectTextBox
                        form={form}
                        name="subject"
                        setCount={setSubjectCount}
                        onChange={(e) =>
                          setTempSubject((prev) => ({
                            ...prev,
                            subject: e,
                          }))
                        }
                        rules={[
                          {
                            required: true,
                            message: "Subject is required",
                          },
                          {
                            max: 200,
                            message: "Subject must be up to 200 characters",
                          },
                          {
                            min: 3,
                            message: "Subject must be more than 2 characters",
                          },
                        ]}
                        count={subjectCount}
                        max={200}
                      />

                      <Text className="thick-lable">Sender</Text>

                      <Form.Item
                        name="sender"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          className="w-100"
                          size="large"
                          optionLabelProp="label"
                          showSearch
                          dropdownRender={(menu) => <div>{menu}</div>}
                          placeholder="Select who will send the email"
                          filterOption={(input, option) =>
                            (option?.data ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {senders?.map((c) => (
                            <Option value={c.id} label={c.email} data={c.email}>
                              <Space
                                classNames="sender-email-wrapper"
                                className="w-100"
                                size={0}
                                direction="vertical"
                              >
                                <Text className="sender-email">{c.email}</Text>

                                <Space>
                                  <Flex align="center" justify="start" gap={6}>
                                    {c.confirmed ? (
                                      <div className="sender-confirmation confirmed-sender"></div>
                                    ) : (
                                      <div className="sender-confirmation not-confirmed-sender"></div>
                                    )}
                                    <Text className="sender-nickname-domain">
                                      {c.nickName} -{" "}
                                      {getDomainFromEmail(c.email)}
                                    </Text>
                                  </Flex>
                                </Space>
                              </Space>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Form>
                  </Space>
                </Col>

                <Col span={12}>
                  <Flex align="center" justify="center" vertical>
                    <Iphone
                      height={280}
                      children={
                        <Space direction="vertical" className="w-100">
                          <div className="w-100 inbox-wrapper">
                            <Text className="sender-inbox-text w-100">
                              Inbox
                            </Text>
                          </div>

                          <div className="sender-inbox-seperator"></div>
                          <Space
                            className="w-100 sender-sender-data-wrapper"
                            direction="vertical"
                            size={0}
                          >
                            <Flex align="center" justify="space-between">
                              <Text className="sender-sender-data-name">
                                Tretek
                              </Text>
                              <Text className="sender-sender-data-time">
                                11:40
                              </Text>
                            </Flex>
                            <Text className="sender-sender-data-subject">
                              {tempSubject?.subject
                                ? tempSubject?.subject
                                : "Message subject..."}
                            </Text>
                            <Text className="sender-sender-data-preview">
                              {tempSubject?.preview
                                ? tempSubject?.preview
                                : "Your preview text..."}
                            </Text>
                          </Space>
                          <div className="sender-inbox-seperator"></div>
                          <Space
                            className="w-100 sender-sender-data-wrapper"
                            direction="vertical"
                            size={3}
                          >
                            <Flex align="center" justify="space-between">
                              <Text className="sender-sender-data-name">
                                <Skeleton.Input active size="small" />
                              </Text>
                              <Skeleton.Input active size="small" />
                            </Flex>
                            <Text className="sender-sender-data-subject">
                              <Skeleton.Input
                                active
                                size="small"
                                className="w-100"
                              />
                            </Text>
                            <Text className="sender-sender-data-preview">
                              <Skeleton.Input
                                active
                                size="small"
                                className="w-100"
                              />
                            </Text>
                          </Space>
                        </Space>
                      }
                    />
                    <Text className="warn-text">
                      Actual email preview may vary depending on the email
                      client.
                    </Text>
                  </Flex>
                </Col>
              </Row>
              <Flex
                className="followup-step-subject-step-button"
                align="center"
                justify="end"
                gap={6}
              >
                <Button onClick={() => setStep()} size="large">
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="primary"
                  loading={loading}
                  onClick={() => form.submit()}
                >
                  Save changes
                </Button>
              </Flex>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

const SubjectTextBox = ({
  form,
  name,
  rules,
  max,
  onChange,
  onPersonalisation,
}) => {
  const { Text } = Typography;
  const [count, setCount] = useState(() => form?.getFieldValue(name)?.length);

  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  const handleSelect = (emoji) => {
    setShowPicker(false);

    let fieldValue = form.getFieldValue(name);

    if (fieldValue) {
      form.setFieldValue(name, `${fieldValue} ${emoji.native}`);
      onChange(`${fieldValue} ${emoji.native}`);
      setCount(`${fieldValue} ${emoji.native}`.length);
    } else {
      form.setFieldValue(name, emoji.native);
      onChange(emoji.native);
      setCount(emoji.native.length);
    }
  };

  const [aiOpen, setAiOpen] = useState(false);
  return (
    <>
      <div className="subject-text-box-wrapper">
        <Form.Item rules={rules} name={name} className="no-error-show">
          <TextArea
            onChange={(e) => {
              setCount(e.target.value.length);
              onChange(e.target.value);
            }}
            size="large"
            className="subject-text-box"
          ></TextArea>
        </Form.Item>

        <Flex className="margin-right" align="center" justify="end" gap={6}>
          <Button
            onClick={() => setAiOpen(true)}
            className="use-ai-button"
            size="small"
            type="text"
          >
            Use AI
          </Button>
          <div>
            <Tooltip title="Add emoji">
              <Button
                onClick={() => setShowPicker((prev) => !prev)}
                icon={<MdEmojiEmotions className="subject-icon emoji-icon" />}
                type="text"
                className="flex-button"
              ></Button>
            </Tooltip>
            {showPicker && (
              <div className="emoji-selector" ref={pickerRef}>
                <Picker
                  set="apple"
                  theme="light"
                  onEmojiSelect={handleSelect}
                />
              </div>
            )}
          </div>
          <Tooltip title="Add personalisation">
            <Button
              type="text"
              className="flex-button"
              onClick={() => onPersonalisation()}
              icon={<FaUser className="subject-icon" />}
            ></Button>
          </Tooltip>
          <Text>
            {count ?? 0}/{max ?? 200}
          </Text>
        </Flex>
      </div>
      <Form.Item shouldUpdate className="no-error-show">
        {() => {
          const errors = form?.getFieldError(name);
          return (
            errors?.length > 0 && (
              <div style={{ color: "red" }}>{errors[0]}</div>
            )
          );
        }}
      </Form.Item>
    </>
  );
};

export default SubjectStep;
