import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const AddProductModal = ({ setOptions, open, setOpen, onAdd }) => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.post("/Product", {
      name: e.name,
    });

    if (response.data.success) {
      setOptions((prev) => [
        ...prev,
        {
          label: response.data.name,
          value: response.data.id,
        },
      ]);

      setOpen(false);
      
      if(onAdd){
        onAdd(response.data.id)
      }
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <Modal
      title={
        <Title level={4} style={{ margin: 0, padding: 0 }}>
          Add new product
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Flex align="center" gap={12} justify="end">
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => form.submit()}
          >
            Save product
          </Button>
        </Flex>
      }
    >
      <Divider style={{ margin: "5px 0px" }} />
      <Form onFinish={submit} form={form}>
        <Space direction="vertical" size={0} className="w-100">
          <Text>Name</Text>
          <Form.Item name="name">
            <Input
              size="large"
              placeholder="Enter product name"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddProductModal;
