import { useContext, useEffect, useState } from "react";
import { AudienceContext } from "./AudienceContext";
import {
  Button,
  Drawer,
  Flex,
  Form,
  message,
  Select,
  Space,
  Typography,
} from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { FaCheck } from "react-icons/fa";
import ButtonIcon from "../../shared/ButtonIcon";
import { HiPlusSm } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import CreateTag from "./Create/CreateTag";

const AddTagToContactSider = ({ open, setOpen, contact, onUpdate }) => {
  const { tags, setTags } = useContext(AudienceContext);

  const { Text } = Typography;

  const [form] = Form.useForm();

  const axios = useAxiosPrivate();

  const [addingTag, setAddingTag] = useState(false);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(contact?.tags){

      form.setFieldValue(
        "tags",
        contact?.tags?.map((c) => c.id)
      );
    }
    else {
      form.setFieldValue(
        "tags",
        contact?.audiences?.map((c) => c.id)
      );
    }
  }, [contact]);

  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Contacts/Assign-To-Tag`, {
      contactId: contact.id,
      tagIds: e.tags,
    });

    if (response.data.success) {
      let newTags = [];

      e.tags.forEach((element) => {
        let tag = tags.find((c) => c.id == element);

        if (tag) {
          newTags.push(tag);
        }
      });

      onUpdate(contact.id, newTags);
      setOpen(false);

      
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };


  const handleAdd = (data) => {
    setTags(prev => [...prev, data])

    form.setFieldValue( "tags",[
      ...form.getFieldValue("tags"),
      data?.id
    ]
    )
  }
  
  return (
    <Form form={form} onFinish={onSubmit}>
      <Drawer
        title={`${contact?.firstname} ${contact?.lastname} tags`}
        open={open}
        onClose={() => setOpen(false)}
      >
<Flex
          style={{ height: "100%" }}
          vertical
          align="start"
          justify="space-between"
        >
          <Space direction="vertical" className="w-100">
            <Space direction="vertical" size={0} className="w-100">
              <Text>Tags</Text>

              <Form.Item name="tags">
                <Select
                  placeholder="Select contact tags"
                  options={tags?.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  className="w-100"
                  size="large"
                  mode="multiple"
                  defaultValue={contact?.tags?.map((c) => c.id)}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Space>

            {addingTag ? (
              <CreateTag setShow={setAddingTag} onAdd={handleAdd}/>
            ) : (
              <Button
                className="flex-button new-buttons"
                onClick={() => setAddingTag((prev) => !prev)}
              >
                Add tag
                <ButtonIcon background={"#f1f1f1"} icon={<HiPlusSm style={{ fontSize: 20 }} />} />
              </Button>
            )}
          </Space>

          <Flex className="w-100" align="center" justify="end" gap={6}>
            <Button
              className="flex-button new-buttons"
              onClick={() => setOpen(false)}
            >
              Cancel
              <ButtonIcon
                icon={<MdCancel style={{ fontSize: 16 }} />}
                background={"#fff"}
              />
            </Button>
            <Button
              className="flex-button new-buttons"
              onClick={() => {
                form.submit();
              }}
              type="primary"
              loading={loading}
            >
              Save changes
              <ButtonIcon
                icon={<FaCheck style={{ fontSize: 14 }} />}
                background={"#5a6bc5"}
              />
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </Form>
  );
};

export default AddTagToContactSider;
