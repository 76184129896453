import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";
import React from "react";
import { BalanceContextProvider } from "../../context/BalanceContext";
import { SearchContextProvider } from "../../context/SearchContext";
import { SubscribeContextProvider } from "../../context/SubscribeContext";


const PersistLogin = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

 
  
 
  return (
    <>
      {!isLoading && (
        <BalanceContextProvider>
          <SearchContextProvider>
            <SubscribeContextProvider>
              <Outlet/>
            </SubscribeContextProvider>
          </SearchContextProvider>
        </BalanceContextProvider>
      )}
    </>
  );
};

export default PersistLogin;
