import { MediaContext } from "../MediaContext";
import { useContext } from "react";
import OrderBySelect from "../../../shared/OrderBySelect";

const MediaOrderBy = () => {
  const { filters, setFilters } = useContext(MediaContext);

  const options = [
    {
      value: "Name",
      label: "Name",
    },
    {
      value: "Created",
      label: "Created",
    },
  ];

  const handleOrderByChanged = (e) => {
    setFilters((prev) => ({ ...prev, orderBy: e }));
  };

  return (
    <OrderBySelect
      options={options}
      defaultValue={filters?.orderBy}
      handleChanged={(e) => handleOrderByChanged(e)}
    />
  );
};

export default MediaOrderBy;
