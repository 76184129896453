import { Col, Row, Skeleton } from "antd";
import "./CardsLoading.css";

const CardsLoading = ({ num }) => {
  const numbers = Array.from({ length: num }, (_, index) => index);

  return (
    <>
      <Row gutter={[12, 12]}>
        {numbers.map((c, i) => (
          <Col span={8} key={i}>
            <Skeleton.Input active className="loading-card" />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default CardsLoading;
