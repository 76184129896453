import { Button, Flex, Form, Input, InputNumber, Modal, Select, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { MdArrowForward, MdLocalDining } from "react-icons/md";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const SelectCountry = ({ open, setOpen }) => {
    const {Text, Title} = Typography
  const [form] = Form.useForm();

  const axios = useAxiosPrivate();
  const [countries, setCountries] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get("/PhoneNumbers/countries");

    setCountries(
      response?.data?.items?.map((c) => ({
        label: <Space><Text strong>({c.code})</Text> - <Text>{c.country}</Text></Space>,
        value: c.code,
      }))
    );
    setLoading(false);
  }, []);

  const onSubmit = (e) => {
    
    if(e.areaCode){
      navigate(`/price/list/${e.country}/${e.areaCode}`)
    }else{
      navigate(`/price/list/${e.country}`)
    }

  };

  return (
    <Modal
      title={
        <Title style={{ margin: 0 }} level={4}>
          Add new phone number
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Flex align="center" justify="end" gap={10}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            type="primary"
            className="flex-button"
            icon={<MdArrowForward />}
            onClick={() => form.submit()}
          >
            Continue
          </Button>
        </Flex>
      }
    >
      <Form preserve={false} onFinish={onSubmit} form={form}>
        <Text>Country</Text>
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "Please select a country",
            },
          ]}
        >
          <Select size="large" options={countries} loading={loading} placeholder="Select country"/>
        </Form.Item>

        <Text>Area code</Text>
        <Form.Item name="areaCode">
          <InputNumber size="large" placeholder="Enter area code" style={{width:"100%"}}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectCountry;
