import { Button, Divider, Dropdown } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { FaChartPie, FaEye } from "react-icons/fa";

const CampaignOptions = ({ campaign, onDelete }) => {
  const items = [
    {
      label: <p className="drop-down-text">Performance</p>,
      key: "1",
      icon: <FaChartPie className="drop-down-icon" />,
      disabled: true
    
    },
    {
      label: <p className="drop-down-text">Preview</p>,
      key: "2",
      icon: <FaEye className="drop-down-icon" />,
      disabled: true
    },
    {
      label: <div className="drop-down-divider"></div>
    },
    {
      label: <p className="drop-down-text">Delete</p>,
      key: "3",
      danger: true,
      icon: <BsTrashFill className="drop-down-icon" />,
      onClick: () => onDelete(campaign.id),
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }} type="link">
        <Button
          className="flex-button"
          type="text"
          icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default CampaignOptions;
