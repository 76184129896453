import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Grid,
  Checkbox,
  message,
  Select,
  Avatar,
  Pagination,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { MdArrowDropDown, MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import TableLoading from "../../shared/TableLoading";
import { DateFormat } from "../../dateformat";
import UpdateProduct from "./UpdateProduct";
import AddProduct from "./AddProduct";
import ProductListOptions from "./ProductListOptions";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../shared/NoDataFound";
import { ProductListContext } from "./ProductListContext";
import AuthorProductFilter from "./Filters/AuthorProductFilter";
import DateToProductFilter from "./Filters/DateToProductFilter";
import DateFromProductFilter from "./Filters/DateFromProductFilter";
import OrderByProductFilter from "./Filters/OrderByProductFilter";
import OrderDirectionProductFilter from "./Filters/OrderDirectionProductFilter";
import ProductStats from "./Stats";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ButtonIcon from "../../shared/ButtonIcon";
import { AuthorContext } from "../../context/AuthorsContext";
import { IoClose } from "react-icons/io5";
import env from "../../env.json";
import { UserOutlined } from "@ant-design/icons";
import "./ProductList.css"

const { useBreakpoint } = Grid;

const ProductList = () => {
  const axios = useAxiosPrivate();

  const [followUps, setFollowUps] = useState();
  const { authors } = useContext(AuthorContext);
  const {
    stats,
    setStats,
    page,
    setPage,
    showFilters,
    setShowFilters,
    filters,
    setFilters,
    loading,
    products,
    setProducts,
  } = useContext(ProductListContext);

  const { Title, Text } = Typography;

  const navigate = useNavigate();
  const screens = useBreakpoint();

  const perPage = 7;

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [current, setCurrent] = useState();

  useEffect(async () => {
    let response = await axios.get(`/Followup/List`, {
      params: {
        pageSize: 9999,
        page: 1,
      },
    });

    setFollowUps(
      response.data.data?.map((c) => ({
        value: c.id,
        label: c.name,
      }))
    );
  }, []);

  const onAdd = (prod) => {
    setStats((prev) => ({ ...prev, total: prev.total + 1 }));

    setProducts((prev) => [prod, ...prev]);
  };

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    setProducts((prev) => prev.filter((c) => c.id != id));
  };

  const onUpdate = (prod) => {
    setProducts((prev) =>
      prev.map((c) =>
        c.id == prod.id
          ? {
              ...c,
              name: prod.name,
              autoFollowupEmails: prod.autoFollowupEmails,
              autoFollowupNotifications: prod.autoFollowupNotifications,
              owners: prod.owners,
            }
          : c
      )
    );
  };

  const handleUpateFollowups = async (id, emails, notifications) => {
    let response = await axios.put(`/Product/Update/FolloUps`, {
      id: id,
      emails: emails,
      notifications: notifications,
    });

    if (response.data) {
      setProducts((prev) =>
        prev.map((c) =>
          c.id == id
            ? {
                ...c,
                autoFollowupEmails: emails,
                autoFollowupNotifications: notifications,
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const handleFollowupUpdate = async (id, followUpId) => {
    let response = await axios.put("/Product/Update/Followup", {
      id: id,
      followUpId: followUpId,
    });

    if (response.data.success) {
      setProducts((prev) =>
        prev.map((c) =>
          c.id == id
            ? {
                ...c,
                followUpId: followUpId,
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <ProductStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Products
              </Title>
              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { flexBasis: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  className="flex-button new-buttons"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filter
                  {!showFilters ? (
                    <ButtonIcon
                      size={"large"}
                      icon={<MdFilterAlt />}
                      background={"#f1f1f1"}
                    />
                  ) : (
                    <ButtonIcon
                      background={"#f1f1f1"}
                      size={"large"}
                      icon={<MdFilterAltOff />}
                    />
                  )}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="flex-button new-buttons"
                  onClick={() => setShowAdd(true)}
                >
                  New product
                  <ButtonIcon
                    size={"large"}
                    icon={<FiPlus style={{ fontSize: 20 }} />}
                    background={"#5a6bc5"}
                  />
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <AuthorProductFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <DateFromProductFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <DateToProductFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item">
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>
          <Space>
            <OrderByProductFilter />

            <OrderDirectionProductFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space direction="vertical" className="w-100">
          <Card size="small">
            <Row>
              <Col span={4}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={2}>
                <Text className="column-header">Owners</Text>
              </Col>

              <Col span={3}>
                <Text className="column-header">Followup</Text>
              </Col>
              <Col span={5}>
                <Text className="column-header">Audience</Text>
              </Col>
              <Col span={8}>
                <Text className="column-header">Deals</Text>
              </Col>
            
              <Col span={2}>
                <Flex align="center" justify="end">
                  <Text className="column-header">Tool</Text>
                </Flex>
              </Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !products || products.length == 0 ? (
            <Card size="small">
              <NoDataFound
                title="You have not created any products yet"
                description="Click the new product button on Dealing/Product to create a new product"
                addText="New Product"
              />
            </Card>
          ) : (
            <>
              {products?.map((c, i) => (
                <Card size="small">
                  <Row gutter={[6, 6]}>
                    <Col span={4} className="center-table-column">
                    <Space size={0} className="w-100" direction="vertical">

                      <Text className="product-title">{c.name}</Text>
                      <Text className="product-dates">
                        {new Date(c.createdAt).toLocaleDateString("en-us", DateFormat)}
                        {c.lastEdit && ` • Last edit ${new Date(c.lastEdit).toLocaleDateString("en-us", DateFormat)}`}
                      </Text>
                    </Space>
                    </Col>
                    <Col span={2} className="center-table-column">
                      <Avatar.Group maxCount={2} size={"large"}>
                        {c.owners?.map((d, ind) => (
                          <Avatar size="large"
                            key={d.ind}
                            src={`${env.fileUpload}${d.profileUrl}`}
                          >
                            {d?.firstname[0]}
                            {d?.lastname[0]}
                          </Avatar>
                        ))}
                      </Avatar.Group>
                    </Col>
                    <Col span={3} className="center-table-column">
                    <Space direction="vertical" size={6}>

                          <Select
                        defaultValue={c.followUpId}
                        value={c.followUpId}
                        onChange={(e) => handleFollowupUpdate(c.id, e)}
                        options={followUps}
                        placeholder="Select followup"
                        className="w-100"
                        />
                        <Space>
                        <Checkbox size="small"
                          onChange={(e) =>
                            handleUpateFollowups(
                              c.id,
                              e.target.checked,
                              c.autoFollowupNotifications
                            )
                          }
                          checked={c?.autoFollowupEmails}
                          >Email</Checkbox>

                        <Checkbox
                          onChange={(e) =>
                            handleUpateFollowups(
                              c.id,
                              c.autoFollowupEmails,
                              e.target.checked
                            )
                          }
                          checked={c?.autoFollowupNotifications}
                          >Notifications</Checkbox>
                          </Space>

                         
                        </Space>
                    </Col>
                    <Col span={5} className="center-table-column">
                      <Text ellipsis>{c?.tagName ? c.tagName : "-"}</Text>
                    </Col>

                    <Col span={8} className="center-table-column">
                      <div>
                        {c.productDeals?.map((d, ind) => (
                          <Text className="deal-tag"
                            key={ind}
                            type="link"
                            onClick={() => navigate(`/Deals/Details/${d.id}`)}
                          >
                            {d.name}
                          </Text>
                        ))}

                        {c.subscribersCount > 2 && (
                          <Text type="link" className="deal-tag">
                            +{c.subscribersCount - 2} more
                          </Text>
                        )}
                      </div>
                    </Col>

                    <Col span={2}>
                      <Flex align="center" justify="end" className="h-100">
                        <ProductListOptions
                          id={c.id}
                          onDelete={onDelete}
                          setShowEditing={(e) => setShowEdit(e)}
                          setCurrent={(e) => setCurrent(e)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
              <Card size="small">
                <Flex align="center" justify="end">
                  <Pagination
                    pageSize={perPage}
                    total={stats?.total}
                    current={page}
                    onChange={(p) => setPage(p)}
                  />
                </Flex>
              </Card>
            </>
          )}
        </Space>
      </Space>

      <AddProduct show={showAdd} setShow={setShowAdd} onAdd={onAdd} />
      {showEdit && (
        <UpdateProduct
          show={showEdit}
          setShow={setShowEdit}
          id={current}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default ProductList;
