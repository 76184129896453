import React, { useState } from "react";
import Header from "../Header";
import Kanban from "../Kanban/Kanban";
import { DealsTabs } from "../../../shared/enums";
import List from "../List/List";
import { Space } from "antd";

const DealsManagement = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showAdd, setShowAdd] = useState(false);

  return (
    <Space className="w-100" size={24} direction="vertical">
      <Header
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setShowDrawer={setShowAdd}
      />
      {activeTab === DealsTabs.Kanban && (
        <Kanban showAdd={showAdd} setShowAdd={setShowAdd} />
      )}
      {activeTab === DealsTabs.List && (
        <List showAdd={showAdd} setShowAdd={setShowAdd} />
      )}
    </Space>
  );
};

export default DealsManagement;
