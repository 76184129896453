import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Result,
  Typography,
  message,
} from "antd";
import { useState } from "react";
import { TbMessageDollar } from "react-icons/tb";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const CreateApiKey = ({ isOpen, setIsOpen, setData }) => {
  const axios = useAxiosPrivate();
  const [form] = Form.useForm();
  const [created, setCreated] = useState(false);
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState();
  const [key, setKey] = useState();

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.post("/Api/CreateKey", {
      ...e,
    });

    if (response.data.success) {
      setCreated(true);
      setKey(response.data.data.key);
      setData(prev =>[...prev, response.data.data])
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Create api key
          </Title>
        }
        footer={
          created ? (
            <Button onClick={() => setIsOpen(false)}>Nice</Button>
          ) : (
            <Flex align="center" justify="end" gap={3}>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button
                loading={loading}
                type="primary"
                onClick={() => form.submit()}
              >
                Create
              </Button>
            </Flex>
          )
        }
      >
        {created && (
          <>
            <Result
              status="success"
              title="Successfully Created Api Key!"
              subTitle="Here is your api key, you may want to copy it since we do not keep a copy of it!"
            />
            <Card>
              <Text copyable style={{}}>
                {key}
              </Text>
            </Card>
          </>
        )}

        {!created && (
          <Form form={form} onFinish={submit}>
            <Text>Key name</Text>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please provide a name",
                },
              ]}
            >
              <Input size="large" placeholder="Enter key name" />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default CreateApiKey;
