import { Flex, Select, Space, Typography } from "antd";
import { useContext } from "react";
import { FollowupContext } from "../FollowupContext";
import { MdArrowDropDown } from "react-icons/md";

const FollowupAuthorFilter = () => {
  const { Text } = Typography;
  const { filters, setFilters, authorLoading } = useContext(FollowupContext);

  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      selectedAuthors: e,
    }));
  };

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{color:"#a5b6d5"}}>Authors</Text>
      <Select
        size="large"
        maxTagCount="responsive"
        className="order-by-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
        defaultValue={filters?.selectedAuthors}
        value={filters?.selectedAuthors}
        placeholder="Select follow up filter authors"
        options={filters?.authors}
        style={{flexGrow:1}}
        mode="multiple"
        onChange={handleChanged}
      />
    </Flex>
  );
};

export default FollowupAuthorFilter;
