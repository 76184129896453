import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Space } from "antd";
import { DealsDetailsContextProvider } from "../../../context/DealsDetailsContext";
import DealDetailsAside from "./DealDetailsAside/DealDetailsAside";
import DealDetailsMain from "./DealDetailsMain/DealDetailsMain";
import DealDetailsHeader from "./DealDetailsHeader/DealDetailsHeader";

const DealDetails = () => {
  const { id } = useParams();
  return (
    <DealsDetailsContextProvider>
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
          <DealDetailsHeader />
           <DealDetailsMain /> 
      </Space>
    </DealsDetailsContextProvider>
  );
};

export default DealDetails;
