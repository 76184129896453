import { Flex, Typography, Select, Space } from "antd";

const ContactCommucations = () => {
    const {Title,Text} = Typography

    return <>
    <Space className="w-100" direction="vertical">

    <Flex className="w-100" align="center" justify="space-between">

        <Title level={3} className="zero-margin-padding">All communications</Title>
    
        <Select size="large" placeholder="Show only"/>
    </Flex>

    
    </Space>
    </>
}

export default ContactCommucations;