import {
  Button,
  Card,
  Col,
  Flex,
  Grid,
  message,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import TaskStats from "./TaskStats";
import { FiPlus } from "react-icons/fi";
import { MdFilterAlt, MdFilterAltOff, MdOutlineClose } from "react-icons/md";
import { useContext, useState } from "react";
import { TaskContext } from "./TaskContext";
import TaskOrderByFilter from "./FIlters/TaskOrderByFilter";
import TaskOrderByDirectionFilter from "./FIlters/TaskOrderByDirectionFilter";
import TaskFromDateFilter from "./FIlters/TaskFromDateFilter";
import TaskToDateFilter from "./FIlters/TaskToDateFilter";
import TaskStatusFilter from "./FIlters/TaskStatusFilter";
import TaskPriorityFilter from "./FIlters/TaskPriorityFilter";
import TableLoading from "../../shared/TableLoading";
import NoDataFound from "../../shared/NoDataFound";
import { DateFormat } from "../../dateformat";
import TaskOptions from "./TaskOptions";
import getTaskTypeIcon from "../../shared/TaskTypeIcon";
import { FaCheck, FaDotCircle } from "react-icons/fa";
import AvatarGroup from "../deals/Kanban/AvatarGroup";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { TaskStatus } from "../../shared/enums";
import CreateFollowUpTaskModal from "../deals/CreateDealDrawer/CreateFollowUpTaskModal";
import AddTaskDrawer from "../deals/AddTaskDrawer";
import { IoClose } from "react-icons/io5";
import { TbCircleDotFilled } from "react-icons/tb";

const { useBreakpoint } = Grid;

const TaskList = () => {
  const axios = useAxiosPrivate();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [followUpOpen, setFollowUpOpen] = useState(false);

  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const screens = useBreakpoint();

  const [addTaskOpen, setAddTaskOpen] = useState(false);

  const {
    filters,
    setFilters,
    tasks,
    page,
    setPage,
    stats,
    setStats,
    setTasks,
    loading,
  } = useContext(TaskContext);

  const columns = [
    {
      title: "Title",
      render: (row) => (
        <Flex align="center" justify="start" gap={6}>
          {getTaskTypeIcon(row.taskType)}
          <Text strong>
            {row.isFollowUp ? <Tag>Follow up</Tag> : ""}
            {row.name}
          </Text>
        </Flex>
      ),
    },
    {
      title: "Priority",
      responsive: ["sm"],
      render: (row) => (
        <Text strong>{row.highPriority ? "High" : "Normal"}</Text>
      ),
    },
    {
      title: "Due date",
      responsive: ["sm"],
      render: (row) => (
        <>
          <Flex align="center" justify="start" gap={6}>
            {row.dueDate ? (
              <>
                {new Date(row.dueDate) < new Date() ? (
                  <>
                    <TbCircleDotFilled style={{ color: "#e74a3b", fontSize:18}} />
                    <Text strong>
                      {new Date(row.dueDate).toLocaleDateString(
                        "en-US",
                        DateFormat
                      )}
                    </Text>
                  </>
                ) : (
                  <Text strong>
                    {new Date(row.dueDate).toLocaleDateString(
                      "en-US",
                      DateFormat
                    )}
                  </Text>
                )}
              </>
            ) : (
              "-"
            )}
          </Flex>
        </>
      ),
    },
    {
      title: "Done",
      responsive: ["sm"],
      width:10,
      render: (row) => (
        <Flex align="center" justify="center">
          {row.done ? (
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          ) : (
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
        </Flex>
      ),
    },
    {
      title: "Deal",
      responsive: ["sm", "md", "lg", "xl"],
      render: (row) =>
        row.deal ? (
          <Text
            strong
            type="link"
            onClick={() => navigate(`/Deals/Details/${row.deal.id}`)}
          >
            {row.deal?.name}
          </Text>
        ) : (
          <Text strong>-</Text>
        ),
    },
    {
      title: "Owner",
      responsive: ["sm", "md", "lg", "xl"],
      render: (row) => (
        <AvatarGroup
          items={row.owners?.map((c) => ({
            profileUrl: c.profilePath,
            name: c.name,
            lastName: c.lastname,
          }))}
        />
      ),
    },
    {
      width: 10,
      title: "",
      render: (row) => (
        <TaskOptions
          onMarkTaskAsDone={onMarkTaskAsDone}
          taskDone={row.done}
          isFollowUp={row.isFollowUp}
          id={row.id}
          onDelete={(e) => {
            setDeleteOpen(true);
            setCurrentItem(e);
          }}
          onReopenTask={onReopenTask}
          onCreateFollowUp={onCreateFollowUp}
        />
      ),
    },
  ];

  const onMarkTaskAsDone = async (id) => {
    const response = await axios.post("/DealTasks/MarkTaskAsDone", {
      taskId: id,
    });

    if (response.data.success) {
      setTasks((prev) =>
        prev.map((c) => (c.id == id ? { ...c, done: true } : c))
      );
    } else {
      message.error(response.data.message);
    }
  };

  const onCreateFollowUp = (id) => {
    setCurrentItem(id);
    setFollowUpOpen(true);
  };

  const onReopenTask = async (id) => {
    const response = await axios.put(`/DealTasks/reopen/${id}`, {});

    if (response.data.success) {
      setTasks((prev) =>
        prev.map((c) => (c.id == id ? { ...c, done: false } : c))
      );
    } else {
      message.error(response.data.message);
    }
  };

  const handleDelete = async (item, deleted) => {
    if (!deleted) {
      return;
    }

    const response = await axios.delete(`/DealTasks/${item}`);

    if (response.data.success) {
      setTasks((prev) => prev.filter((c) => c.id != item));

      var task = tasks.find((c) => c.id == item);

      if (task.taskType == TaskStatus.ToDo) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          toDo: prev.toDo - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Email) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          email: prev.email - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Call) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          call: prev.call - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Meeting) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          meeting: prev.meeting - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Lunch) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          lunch: prev.lunch - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          deadline: prev.deadline - 1,
          overdue:
            task.dueDate && new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      }
    } else {
      message.error(response.data.message);
    }
  };

  const onFollowupCreated = (task) => {
    setTasks((prev) => [{ ...task.data, isFollowUp: true }, ...prev]);

    if (task.data.taskType == TaskStatus.ToDo) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        toDo: prev.toDo + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Email) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        email: prev.email + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Call) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        call: prev.call + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Meeting) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        meeting: prev.meeting + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Lunch) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        lunch: prev.lunch + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        deadline: prev.deadline + 1,
        overdue:
          task.data.dueDate && new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    }
  };

  const [addTaskLoading, setAddTaskLoading] = useState(false)

  const onSubmit = async (e) => {

    setAddTaskLoading(true);

    const response = await axios.post(`/DealTasks/CreateTask`, {
      taskType: e.type,
      name: e.name,
      dueDate: e.dueDate,
      reminder: e.reminder,
      highPriority: e.taskPriority,
      notes: e.notes,
      ownerIds: e.assignedUser,
      dealId: e.dealId,
      dueTime: e.dueTime,
      reminderType: e.reminderType,
    });

    if (response.data.success) {
      setTasks((prev) => [
        response.data,
        ...prev
      ]);
      setAddTaskLoading(false);
    } else {
      message.error(response.data.message);
    }

    setAddTaskLoading(false);
    setAddTaskOpen(false)
  }

  return (
    <>
      <AddTaskDrawer
        open={addTaskOpen}
        setOpen={setAddTaskOpen}
        selectDeals={true}
        onSubmit={onSubmit}
        loading={addTaskLoading}
      />
      <CreateFollowUpTaskModal
        open={followUpOpen}
        setOpen={setFollowUpOpen}
        id={currentItem}
        onCreated={onFollowupCreated}
      />
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={currentItem}
      />

      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <TaskStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Tasks
              </Title>
              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { flexBasis: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  block={screens.xs}
                  className="flex-button"
                  icon={filters?.show ? <MdFilterAltOff /> : <MdFilterAlt />}
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, show: !prev.show }))
                  }
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  onClick={() => setAddTaskOpen(true)}
                  className="primary-gradient-background flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                >
                  New task
                </Button>
              </Flex>
            </Flex>
          }
        >
          {filters?.show && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <TaskStatusFilter />
              </Col>
              <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                <TaskPriorityFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <TaskFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <TaskToDateFilter />
              </Col>

              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <TaskOrderByFilter />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <TaskOrderByDirectionFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Card className="zero-margin-padding">
          {loading ? (
            <TableLoading />
          ) : (
            <Table
              columns={columns}
              dataSource={tasks}
              locale={{
                emptyText: (
                  <NoDataFound
                    addText="New Task"
                    title="You have not created any tasks yet"
                    description="Click the New Task button on Dealing/Tasks to create a new task"
                  />
                ),
              }}
              pagination={{
                current: page,
                total: stats?.total,
                onChange: (p) => {
                  setPage(p);
                },
              }}
            />
          )}
        </Card>
      </Space>
    </>
  );
};

export default TaskList;
