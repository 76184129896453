import { Button, Flex, Input, Space, Typography, message } from "antd";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { useState } from "react";
import { BiPencil } from "react-icons/bi";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const EditableName = ({ id, name, setName }) => {
  const axios = useAxiosPrivate();
  const [temp, setTemp] = useState();
  const { Title, Text } = Typography;

  const [loading, setLoading] = useState(false);
  const edit = (e) => {
    e.stopPropagation();
    setTemp(name)
  };

  const cancelEditing = (e) => {
    e.stopPropagation();
    setName(temp)
  };

  const save = async (e) => {
    e.stopPropagation();

    const response = await axios.post("/TargetAudience/UpdateName", {
      id: id,
      name: name,
    });

    if (response.data.success) {
      message.success("Name changed!");
    } else {
      message.error(response.data.message);
      setName(name);
    }
  };

  return (<Space className="w-100" direction="vertical" >
    <Space className="w-100" direction="vertical" size={0}>

      <Text>Name</Text>
      <Input
        placeholder="Enter a name for your audience"
        size="large"
        value={name}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setName(e.target.value)}
      />
    </Space>
    <Flex align="center" justify="end">

      <Button
        type="primary"
        size="small"
        className="flex-button"
        loading={loading}
        onClick={save}
      >Save</Button>
    </Flex>
  </Space>
  );
};

export default EditableName;
