import { Layout } from "antd";
import useLocalStorage from "../hooks/useLocalStorage";
import { useState } from "react";
import SideMenu from "./Navigation/SideMenu";
import { Outlet } from "react-router-dom";
import AppFooter from "./Navigation/Footer/Footer";
import SubscriptionModal from "../pages/audience/subscription/SubscriptionModal";
import ApplyCouponModal from "../pages/audience/subscription/ApplyCouponModal";
import Header from "./Navigation/Header/Header";
import { AuthorContextProvider } from "../context/AuthorsContext";

const ApplicationLayout = () => {

  const { Content } = Layout;

  const [collapseValue, setCollapseValue] = useLocalStorage("collapse", false);
  const [collapsed, setCollapsed] = useState(() => collapseValue);

  const updateCollapse = (e) => {
    setCollapseValue(e);
    setCollapsed(e);
  };
  
  return (
    <AuthorContextProvider>
      <Layout style={{ minHeight: "100vh" }}>
        <SideMenu collapsed={collapsed} setCollapsed={updateCollapse} />

        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Header collapsed={collapsed} setCollapsed={updateCollapse} />

          <Content style={{ margin: "24px" }}>
            <Outlet />
          </Content>
          <AppFooter />
        </Layout>

        <SubscriptionModal />
        <ApplyCouponModal />
      </Layout>
    </AuthorContextProvider>
  );
};

export default ApplicationLayout;
