import { IoIosBatteryFull, IoIosMore, IoIosWifi } from "react-icons/io";
import "./Iphone.css";
import { Col, Row, Space } from "antd";
const Iphone = ({ children, height }) => {
  return (
    <div
      style={{
        height: `${height}px`,
        width: "80mm",
        display:'flex',
        justifyContent:'center',
        overflowY:"hidden"
      }}
    >
      <div
        style={{
          width: "76.7mm",
          position: "relative",
        }}
      >
        <div className="iphone-buttons-left">
          <Space direction="vertical">
            <div className="iphone-small-button"></div>
            <div className="iphone-button"></div>
            <div className="iphone-button"></div>
          </Space>
        </div>

        <div className="iphone-buttons-right">
          <div className="iphone-button-large"></div>
        </div>

        <div className="iphone-wrapper">
          <div className="iphone-inner-wrapper">
            <Row className="w-100 iphone-nav-row">
              <Col span={8}>
                <div className="iphone-time-wrapper">
                  <p className="iphone-time">9:33</p>
                </div>
              </Col>
              <Col span={8}>
                <div className="dynamic-island-wrapper">
                  <div className="dynamic-island"></div>
                </div>
              </Col>
              <Col span={8}>
                <div className="iphone-icons-wrapper">
                  <IoIosMore className="iphone-icon" />
                  <IoIosWifi className="iphone-icon" />
                  <IoIosBatteryFull className="iphone-icon" />
                </div>
              </Col>
            </Row>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iphone;
