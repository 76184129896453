import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, Typography, message } from "antd";
import { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { IoMdMore } from "react-icons/io";

const ApiKeyOperations = ({id, setData}) => {
  const { Text } = Typography;

  const axios = useAxiosPrivate();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);

  const handleDelete = async () => {
    setDeleteLoading(true);
    const response = await axios.post("/Api/DeleteKey", {
        id: id
    });

    if (response.data.success) {
        message.success("Key deleted")
        setData(prev => prev.filter(d => d.id != id))
    } else {
      message.error(response.data.message);
    }

    setDeleteLoading(false);
  };

  const items = [
    {
      key: "1",
      label: "Disable key",
    },
    {
      key: "2",
      label: "Delete key",
      danger: true,
    },
  ];

  const handleMenuClick = async (e) => {
   
    if(e.key == "1"){
         
    }
    if(e.key == "2"){
        await handleDelete();
    }
  }

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]}>
      <Button type="text" shape="circle"
        icon={<IoMdMore style={{ fontSize: 20 }} />}/>
    </Dropdown>
  );
};
export default ApiKeyOperations;
