import { Button, Flex, Form, Input, Modal, Typography, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const CreateSignature = ({ open, setOpen, onSuccess }) => {
  const {Title} = Typography
  const axios = useAxiosPrivate();
const [form] = Form.useForm();
  const onSubmit = async (e) => {
    const response = await axios.post("/Signature", { ...e });

    if (response.data.success) {
      message.success("Signature added!");
      onSuccess(response.data.data)   
      setOpen(false);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <Modal
      title={
        <Title style={{ margin: 0 }} level={4}>
          Create your signature
        </Title>
      }
      open={open}
      footer={<Flex align="center" justify="end" gap={6}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>Save signature</Button>

      </Flex>}
      onCancel={() => setOpen(false)}
      onOk={() => setOpen(false)}
    >
      <Form onFinish={onSubmit} form={form}>
        <Form.Item
          name="name"
          rules={[
            {
              max: 50,
              message: "Name should contain at most 50 characters",
            },
            {
                required: true,
                message:"Name is a required filed"
            }
          ]}
        >
          <Input
            type="text"
            placeholder="Enter signature name"
            allowClear
            size="large"
            count={{
              show: true,
              max: 50,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSignature;
