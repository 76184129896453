import { Button, Dropdown } from "antd";
import { BsTrash3Fill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import { RiPassValidFill } from "react-icons/ri";
import RemoveEsEmail from "../CRUD/RemoveEsEmail";

const EmailOptions = ({email, id, setCurrent, setOpen, onDelete}) => {
      
  const items = [
    {
      key: 1,
      label: "Check validity",
      icon:<RiPassValidFill/>,
      onClick: () => {
        setCurrent(email);
        setOpen(true);
      }
    },
    {
      key: 2,
      label: <RemoveEsEmail id={id} onDelete={onDelete}/>,
      danger: true,
      icon: <BsTrash3Fill />,
    },
  ];
  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Button
          type="text"
          icon={<IoMdMore style={{ fontSize: 20 }} />}
          shape="circle"
          className="flex-button"
        ></Button>
      </Dropdown>
    </>
  );
};

export default EmailOptions;
