import { Typography } from "antd";
import { useContext } from "react";
import { CompanyListContext } from "../CompanyListContext";
import OrderBySelect from "../../../shared/OrderBySelect";

const CompanyOrderByFilter = () => {
  const { filters, setFilters } = useContext(CompanyListContext);

  const options = [
    {
      value: "Created",
      label: "Date created",
    },
    {
      value: "Name",
      label: "Name",
    },
  ];

  const handleChanged = (e) => {
    setFilters((prev) => ({ ...prev, orderBy: e }));
  };
  return (
    <>
      <OrderBySelect
        options={options}
        defaultValue={filters?.orderBy}
        handleChanged={(e) => handleChanged(e)}
      />
    </>
  );
};

export default CompanyOrderByFilter;
