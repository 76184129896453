import { Flex, Select, Typography } from "antd";
import { EmaiLSenderConfirmation } from "../../../../shared/enums";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";
import { MdArrowDropDown } from "react-icons/md";

const EmailSenderConfirmationSelect = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(EmailSenderContext);

  const options = [
    {
      value: EmaiLSenderConfirmation.Confirmed,
      label: "Confirmed",
    },
    {
      value: EmaiLSenderConfirmation.NotConfirmed,
      label: "Not confirmed",
    },
  ];

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>Status</Text>
      <Select
        defaultValue={filters?.statuses}
        options={options}
        value={filters?.statuses}
        onChange={(e) => setFilters((prev) => ({ ...prev, statuses: e }))}
        style={{ flexGrow: 1 }}
        className="order-by-select"
        size="large"
        mode="multiple"
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
    </Flex>
  );
};

export default EmailSenderConfirmationSelect;
