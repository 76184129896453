import { Button, Flex, Form, Input, Modal, Typography, message } from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const EditSenderName = ({ open, setOpen, id, onUpdate }) => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(() => false);

  useEffect(async () => {
    if(!id) return;
    setLoading(true);
    const response = await axios.get(`/PhoneNumbers/numbers/${id}`);

    if(response.data.success){
        form.setFieldValue("name", response.data.name)
    }else{
        setOpen(() => ({open:false, id:id}));
        message.error(response.data.message)
    }

    setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/PhoneNumbers/numbers/name`,{
        name: e.name,
        id: id  
    });

    if(response.data.success){
        onUpdate(id, e.name);
        setOpen(false);
    }else{
        message.error(response.data.message);
    }

    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Edit sms sender name
          </Title>
        }
        footer={
          <Flex align="center" justify="end" gap={6}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()} loading={loading}>
              Save changes
            </Button>
          </Flex>
        }
      >
        <Form preserve={false} form={form} onFinish={handleSubmit}>
          <Text>Name</Text>
          <Form.Item
            name="name"
            rules={[
              {
                max: 100,
                message: "Name length should be 100 or less",
              },
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input
              placeholder="Enter number name"
              size="large"
              count={{
                show: true,
                max: 100,
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditSenderName;
