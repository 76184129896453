import { createContext, useContext, useEffect, useState } from "react";
import { OrderDirection } from "../../shared/enums";
import { SearchContext } from "../../context/SearchContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { AuthorContext } from "../../context/AuthorsContext";
import QueryString from "qs";

export const AudienceContext = createContext({
  filters: {
    selectedAuthors: [],

    from: null,
    to: null,

    orderBy: "",
    orderDirection: "",
    tags: [],
  },
  setFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    authorName: null,
    authorProfile: null,
  },
  setStats: () => {},

  page: 1,
  setPage: () => {},

  loading: false,
  setLoading: () => {},

  contacts: [],
  setContacts: () => {},

  tags: [],
  setTags: () => {},
});

export const AudienceContextProvider = ({ children }) => {
  const { you } = useContext(AuthorContext);
  const axios = useAxiosPrivate();
  const [filters, setFilters] = useState(() => ({
    orderDirection: OrderDirection.Dsc,
    orderBy: "Created",
    selectedAuthors: [you],
  }));

  const { debouncedValue } = useContext(SearchContext);

  const [tags, setTags] = useState([])
  const [stats, setStats] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState();

  const perPage = 7;

  useEffect(async () => {
    const tagResponse = await axios.get("Contacts/Tags");
    
    setTags(tagResponse.data.items)
  }, [])

  useEffect(async () => {
    setLoading(true)
    const response = await axios.get("/Contacts/list", {params: {
      search:debouncedValue,
      orderBy: filters?.orderBy,
      sortDirection: filters?.orderDirection,
      authors: filters?.selectedAuthors,
      from: filters?.from ? new Date(filters?.from) : null,
      to: filters?.to ? new Date(filters?.to) : null,
      page: page,
      pageSize:perPage,
      tags: filters?.tags,
    },  paramsSerializer: params => {
      return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true})
    }});
    setContacts(response.data.items)

    setStats(prev => ({
      total: response.data.total,
      first: response.data.first,
      last: response.data.last,
      authorName: response.data.authorName,
      authorProfile: response.data.authorProfile
    }))

    setLoading(false)
  }, [debouncedValue, filters, page])

  return (
    <AudienceContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,

        page,
        setPage,

        loading,
        setLoading,

        contacts,
        setContacts,

        tags,
        setTags
      }}
    >
      {children}
    </AudienceContext.Provider>
  );
};
