import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import "./CreateNotificationTemplate.css";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { GiTestTubes } from "react-icons/gi";
import ButtonIcon from "../../../../shared/ButtonIcon";
import { MdCheck } from "react-icons/md";
import HeaderStep from "./HeaderStep";
import UrlStep from "./UrlStep";
import MessageStep from "./MessageStep";
import { useContext, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { AiFillEdit } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { CreateNotificationTemplateContext } from "./CreateNotificationTemplateContext";
import IconStep from "./IconStep";
import { useStyleRegister } from "antd/es/theme/internal";
import NotificationPreviewModal from "../NotificationTemplatePreviewModal";

const CreateNotificationTemplate = () => {
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const { data, setData } = useContext(CreateNotificationTemplateContext);

  const { id } = useParams();

  const [step, setStep] = useState();

  const axios = useAxiosPrivate();

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    const response = await axios.put("/Notification/Save", {
      id: data?.id,
    });

    if (response.data.success) {
      navigate(-1);
    } else {
      message.error(response.data.message);
    }
    setSaving(false);
  };

  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <>
      <NotificationPreviewModal
        id={id}
        open={previewOpen}
        setOpen={setPreviewOpen}
      />
      <Space direction="vertical" className="w-100" size={24}>
        <Card size="small">
          <Flex align="center" justify="space-between">
            <Flex align="center" justify="start" gap={6}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                icon={<FaArrowLeft />}
                className="flex-button"
              ></Button>
              <EditableName
                id={id}
                name={data?.name}
                onUpdate={(e) => setData((prev) => ({ ...prev, name: e }))}
              />
            </Flex>

            <Flex align="center" justify="end" gap={6}>
              <Button
                size="large"
                className="flex-button new-buttons"
                onClick={() => setPreviewOpen(true)}
              >
                Preview & Test{" "}
                <ButtonIcon
                  size="large"
                  icon={<GiTestTubes className="icon-size" />}
                  background={"#f3f3f3"}
                />
              </Button>
              <Button
                size="large"
                type="primary"
                loading={saving}
                onClick={save}
                className="flex-button new-buttons"
                disabled={
                  !data?.header || !data?.url || !data?.template || !data?.path
                }
              >
                Save
                <ButtonIcon
                  size="large"
                  icon={<MdCheck className="icon-size" />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Flex>
          </Flex>
        </Card>
        <Card>
          <Flex align="center" justify="center">
            <Space direction="vertical" size={0} className="step-wrapper">
              <HeaderStep setStep={setStep} active={step == 1} />
              <UrlStep setStep={setStep} active={step == 2} />
              <MessageStep setStep={setStep} active={step == 3} />
              <IconStep setStep={setStep} active={step == 4} />
            </Space>
          </Flex>
        </Card>
      </Space>
    </>
  );
};

const EditableName = ({ id, name, onUpdate }) => {
  const { Title } = Typography;

  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const handleEditChange = (show) => {
    setEdit(show);
    form.setFieldValue("name", name);
  };

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Notification/Update-name`, {
      name: e.name,
      id: id,
    });

    if (response.data.success) {
      setEdit(false);
      onUpdate(e.name);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <>
      {edit ? (
        <>
          <Form form={form} onFinish={submit}>
            <Flex align="center" justify="start" gap={6}>
              <Form.Item
                name="name"
                className="no-error"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 128,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter name"
                  count={{
                    show: true,
                    max: 128,
                  }}
                />
              </Form.Item>

              <Button
                loading={loading}
                onClick={() => form.submit()}
                type="text"
                className="flex-button icon"
                icon={<MdCheck />}
              ></Button>
              <Button
                type="text"
                onClick={() => handleEditChange(false)}
                className="flex-button icon"
                icon={<IoMdClose />}
              ></Button>
            </Flex>
          </Form>
        </>
      ) : (
        <>
          <Title className="zero-margin-padding" level={4}>
            {name}
          </Title>

          <Button
            size="large"
            type="link"
            onClick={() => handleEditChange(true)}
            className="flex-button"
            icon={<AiFillEdit className="edit-icon" />}
          ></Button>
        </>
      )}
    </>
  );
};

export default CreateNotificationTemplate;
