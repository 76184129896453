import EmailEditor from "react-email-editor";
import { EmailEditorOptions } from "../../shared/Options";
import {
  Button,
  Dropdown,
  Flex,
  Form,
  message,
  Typography,
  Input,
  Tag,
  Space,
  Card,
} from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useAsyncError, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AiFillEdit } from "react-icons/ai";
import { MdCheck } from "react-icons/md";
import env from "../../env.json";
import "./CreateTemplateComponent.css";
import { GrNext } from "react-icons/gr";
const CreateTemplateComponent = () => {
  const navigate = useNavigate();
  const [selectedComponentId, setSelectedComponentId] = useState(null);

  const axios = useAxiosPrivate();

  const { id } = useParams();

  const [name, setName] = useState();
  const emailEditorRef = useRef(null);
  const [templateStyle, setTemplateStyle] = useState("");
  const [templateStyleJson, setTemplateStyleJson] = useState("");

  const [loaded, setLoaded] = useState(false);

  const [type, setType] = useState();

  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      const response = await axios.get(`/Template-Component/${id}`, {
        signal: signal,
      });

      setName(response.data.name);

      setTemplateStyle(response.data.style);
      setTemplateStyleJson(
        response.data.style ? JSON.parse(response.data.style) : "{}"
      );
      setFetched(true);

      setType(response.data.type);
    };

    fetch();

    return () => controller.abort();
  }, []);

  const handleReady = async () => {
    if (loaded) {
      return;
    }
    setLoaded(true);
    emailEditorRef.current.editor.loadDesign(templateStyleJson);
  };

  const handleFileUpload = async (file, done) => {
    try {
      const formData = new FormData();
      formData.append("file", file.attachments[0]);

      const response = await axios.post(
        "/Templates/Email/Upload-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response.data.success) {
        throw new Error("Upload failed");
      }
      const imageUrl = response.data.path;

      done({ progress: 100, url: `${env.fileUpload}${imageUrl}` });
    } catch (error) {
      console.error("Image upload failed:", error);
      done({ progress: 100, error: "Image upload failed" });
    }
  };

  const onLoad = () => {
    emailEditorRef.current.editor.registerCallback("image", (file, done) => {
      handleFileUpload(file, done);
    });

    emailEditorRef.current.editor.addEventListener('design:updated', (data) => {
      console.log(data)
      console.log(data.item.id)

      var iFrame = document.querySelector("iframe");
      console.log("hello world")
      var iframeDoc = iFrame.contentDocument || iFrame.contentWindow.document;

      var elementInIframe = iframeDoc.querySelector(`#${data.item.id}`); // Use your specific selector

      // Now you can manipulate the element as needed
      if (elementInIframe) {
        elementInIframe.addEventListener("click", () => {
          console.log("hello world")
        })
      } else {
        console.log('Element not found in iframe.');
      }
    })
  };

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);

    await emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      var formData = new FormData();

      formData.append("Html", html);
      formData.append("Id", id);
      formData.append("Style", JSON.stringify(design));

      var response = await axios.post("/Template-Component/Update", formData);

      if (!response || !response.data.success) {
        return;
      }

      setLoading(false);

      navigate(`/Template/Components`);
    });

    setLoading(false);
  };

  const [showPlaceholders, setShowPlaceholders] = useState(false);

  return (
    <>
      <div className="create-advanced-template-wrapper">
        <Flex
          className="create-advanced-template-inner-wrapper"
          align="center"
          justify="space-between"
          gap={6}
        >
          <Flex align="center" justify="start" gap={6}>
            <Button
              onClick={() => navigate(-1)}
              size="large"
              className="bold flex-button margin-left-10"
              icon={<FaArrowLeft />}
            ></Button>

            <EditableName name={name} id={id} onUpdate={(e) => setName(e)} />

            <div className="create-component-type">

            {type == 1 ? "User" : type == 2 ? "Product" : "Component"}
            </div>
          </Flex>

          <Flex align="center" justify="start">
            {!showPlaceholders && (
              <Button
                className="bold"
                size="large"
                onClick={() => setShowPlaceholders(true)}
              >
                Show placeholders
                <GrNext />
              </Button>
            )} 

            {showPlaceholders && (
              <>
                {" "}
                {type == 2 ? (
                  <Card
                    size="small"
                    className="no-shadow"
                    bodyStyle={{ padding: 5, margin: 0 }}
                  >
                    <Flex align="center" justify="start" gap={2}>

                      <Button className="template-placeholder-tag">Name</Button>
                      <Button className="template-placeholder-tag">Price</Button>
                      <Button className="template-placeholder-tag">Description</Button>
                      <Button className="template-placeholder-tag">Image</Button>
                      <Button className="template-placeholder-tag">Url</Button>

                      <Button
                        onClick={() => setShowPlaceholders(false)}
                        className="flex-button"
                        danger
                        type="primary"
                      ><IoMdClose className="icon" /></Button>
                    </Flex>
                  </Card>
                ) : (
                  ""
                )}
              </>
            )}
          </Flex>
          <Flex align="center" justify="end" gap={6}>
            <Dropdown
              size="large"
              className="bold"
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <p className="create-template-options-text">
                        Export HTML code
                      </p>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <p className="create-template-options-text">
                        Export JSON
                      </p>
                    ),
                  },
                ],
              }}
            >
              <Button size="large" className="bold">
                Export
              </Button>
            </Dropdown>

            <Button
              size="large"
              type="primary"
              style={{
                marginRight: 10,
              }}
              className="bold"
              onClick={submit}
              loading={loading}
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </div>

      {fetched && (
        <EmailEditor
          ref={emailEditorRef}
          design={templateStyleJson}
          onReady={handleReady}
          onLoad={onLoad}
          options={EmailEditorOptions}
          style={{ flex: 1 }}
        />
      )}
    </>
  );
};

const EditableName = ({ id, name, onUpdate }) => {
  const { Title } = Typography;

  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const handleEditChange = (show) => {
    setEdit(show);
    form.setFieldValue("name", name);
  };

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.put(`/Template-Component/Update-name`, {
      name: e.name,
      id: id,
    });

    if (response.data.success) {
      setEdit(false);
      onUpdate(e.name);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <>
      {edit ? (
        <>
          <Form form={form} onFinish={submit}>
            <Flex align="center" justify="start" gap={6}>
              <Form.Item
                name="name"
                className="no-error"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 128,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter name"
                  count={{
                    show: true,
                    max: 128,
                  }}
                />
              </Form.Item>

              <Button
                loading={loading}
                onClick={() => form.submit()}
                type="text"
                className="flex-button icon"
                icon={<MdCheck />}
              ></Button>
              <Button
                type="text"
                onClick={() => handleEditChange(false)}
                className="flex-button icon"
                icon={<IoMdClose />}
              ></Button>
            </Flex>
          </Form>
        </>
      ) : (
        <>
          <Title className="zero-margin-padding" level={4}>
            {name}
          </Title>

          <Button
            size="large"
            type="link"
            onClick={() => handleEditChange(true)}
            className="flex-button"
            icon={<AiFillEdit className="edit-icon" />}
          ></Button>
        </>
      )}
    </>
  );
};

export default CreateTemplateComponent;
