import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import {
  Button,
  Card,
  Flex,
  Table,
  Typography,
  message,
} from "antd";
import { BsFillTrashFill } from "react-icons/bs";
import TableLoading from "../../../shared/TableLoading";
import { MdOutlineClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
const LoginLogs = () => {
  const axiosPrivate = useAxiosPrivate();

  const { Text, Title } = Typography;
  const [logs, setLogs] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const [selectedLogs, setSelectedLogs] = useState([]);

  const handleRowSelected = (rows) => {
    setSelectedLogs(rows.selectedRows.map((r) => r.id));
  };

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
    setItemToDelete(item);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setItemToDelete(null);
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }
    var response;
    if (item && item.length > 0) {
      response = await axiosPrivate.post(`/Users/DeleteLoginLogs`, {
        ids: selectedLogs,
      });
    } else {
      response = await axiosPrivate.post(`/Users/DeleteLoginLogs`, {
        ids: [item],
      });
    }

    if (response && response.data.success) {
      message.success("Done");
      removeSuccessLogs(item);
      setSelectedLogs([]);
    } else {
      message.error(response.data.message);
    }
  };

  const removeSuccessLogs = (item) => {
    if (item && item.length > 1) {
      setLogs(logs.filter((l) => !item.includes(l.id)));
    } else {
      setLogs(logs.filter((l) => l.id != item));
    }
  };

  const fetchLogs = async (page) => {
    setLoading(true);

    const response = await axiosPrivate.get(
      `/Users/GetLoginLogs?PageSize=${perPage}&Page=${page}`
    );

    setLogs(
      response.data.data.map((c) => {
        return { ...c, key: c.id };
      })
    );
    setTotalRows(response.data.count);
    setLoading(false);
  };

  useEffect(() => {
    fetchLogs(page);
  }, [page, perPage]);

  const columns = [
    {
      title:"Location",
      render:(row) => <Text strong>
        {row.city} - {row.country}
      </Text>
    },
    {
      title: "Ip address",
      render: (row) => <Text strong>{row.ip}</Text>,
    },
    {
      title: "Time",
      render: (row) => (
        <Text strong>{new Date(row.loginTime).toLocaleDateString()}</Text>
      ),
    },
    {
      title: "Login",
      render: (row) => (
        <>
          {!row.loggedIn && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MdOutlineClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
          {row.loggedIn && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          )}
        </>
      ),
    },
    {
      name: "",
      width: "70px",
      render: (row) => (
        <>
          <Button
            shape="circle"
            type="text"
            className="flex-button"
            danger
            onClick={() => handleOpenModal(row.id)}
            icon={<BsFillTrashFill />}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <DeleteConfirmationModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onDeleteConfirm={handleDeleteConfirm}
        item={itemToDelete}
      />

      <Card  className="zero-margin-padding"
        title={
          <Flex align="center" justify="space-between">
            <Title level={5} style={{ margin: 0 }}>
              Recent activity
            </Title>
            <Button
              disabled={selectedLogs.length == 0}
              danger
              className="flex-button"
              icon={<BsFillTrashFill />}
              shape="circle"
              onClick={() => handleOpenModal(selectedLogs)}
            ></Button>
          </Flex>
        }
      >
        {loading&&<TableLoading/>}
        {!loading&&
        <Table style={{marginTop:1}}
        columns={columns}
        pagination={{
          current: page,
          pageSize: perPage,
          onChange: (page, pageSize) => {
            setPage(page);
            setPerPage(pageSize);
            },
            total: totalRows,
          }}
          rowSelection={{
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedLogs(selectedRowKeys);
            },
          }}
          dataSource={logs}
        />
      }
      </Card>
    </>
  );
};

export default LoginLogs;
