import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { RiMapPinFill } from "react-icons/ri";
import { FaArrowRight, FaInfo, FaMap } from "react-icons/fa";
import NumberDetails from "./NumberDetails";
import emails from "../../../../assets/images/backgroundprices1.png";
import { FaCartShopping } from "react-icons/fa6";
import NumberPriceCardSkeleion from "./NumberPriceCardSkeletion";
import { loadStripe } from '@stripe/stripe-js';
import PayButton from "./PayButton";

const NumberPriceList = () => {
 
  const { Title, Text, Link } = Typography;
  const [numbers, setNumbers] = useState();
  const [prices, setPrices] = useState();

  const { ios, areaCode } = useParams();

  const axios = useAxiosPrivate();

  useEffect(async () => {
    const response = await axios.get(`/PhoneNumbers/countries/${ios}/prices`);

    setPrices(response.data);

    setLoading(true);
    var numbersResponse = null

    if(areaCode){
      numbersResponse = await axios.get(
        `/PhoneNumbers/${ios}/${areaCode}/numbers`
        );
    }else{
      numbersResponse = await axios.get(
        `/PhoneNumbers/${ios}/numbers`
        );
    }

    setNumbers(numbersResponse?.data?.list);
    setLoading(false);
  }, []);

 

  const [showDetails, setShowDeatails] = useState(false);
  const [current, setCurrent] = useState();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical">
        <CreateEmailView />
        <Card
          className="no-body-card zero-margin-padding"
          title={
            <Flex align="center" justify="space-between">
              <Title level={4} style={{ margin: 0, padding: 0 }}>
                <Flex align="center" gap={5}>
                  Available numbers ({prices?.country})
                </Flex>
              </Title>
              <img
                src={`https://flagsapi.com/${prices?.isoCountry}/flat/32.png`}
              />
            </Flex>
          }
        ></Card>
        <Row gutter={12} wrap>
          {loading && <NumberPriceCardSkeleion />}
          {!loading &&
            numbers?.map((c, ind) => (
              <Col
                key={c.friendlyName}
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={6}
                xxl={6}
              >
                <Card
                  style={{ marginTop: 15 }}
                  actions={[
                    <Flex
                      align="center"
                      justify="end"
                      gap={12}
                      style={{ marginRight: 10 }}
                    >
                      <Button
                        icon={<FaMap />}
                        className="flex-button"
                        shape="circle"
                        disabled={c.latitude == null || c.longitude == null}
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${c.latitude},${c.longitude}`,
                            "_blank"
                          )
                        }
                      ></Button>
                      <Button
                        icon={<FaInfo />}
                        onClick={() => {
                          setShowDeatails(true);
                          setCurrent(c);
                        }}
                        className="flex-button"
                        shape="circle"
                      ></Button>
                      <PayButton prices={prices} phoneNumber={c.phoneNumber} lat={c.latitude} long={c.longitude} region={`${c.region ?? ''}  ${(c.locality ? `- ${c.locality}` : "")}`}/>
                    </Flex>,
                  ]}
                >
                  <Flex align="center" justify="space-between">
                    <Space direction="vertical" size={0}>
                      <Title style={{ margin: 0 }} level={5}>
                        {ind + 1}. {c.friendlyName}
                      </Title>
                      <Text className="text-light icon-flex">
                        <RiMapPinFill />
                        {c.region} {c.locality && `- ${c.locality}`}
                      </Text>
                    </Space>

                    <Title style={{ margin: 0 }} level={3}>
                      {prices?.phoneNumberPrices
                        ?.find((c) => c.type == "toll free")
                        ?.currentPrice?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </Title>
                  </Flex>
                </Card>
              </Col>
            ))}
        </Row>

        {!loading && (!numbers || numbers.length == 0) && (
          <Empty 
            description="No numbers found with this configuration"
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            />
        )}
      </Space>

      <NumberDetails
        open={showDetails}
        setOpen={setShowDeatails}
        number={current}
        prices={prices}
      />
    </>
  );
};

const CreateEmailView = () => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          height: "120px",
          background: `url(${emails}) no-repeat`,
          borderRadius: 20,
          backgroundSize: "cover",
        }}
      >
        <Flex align="center" justify="space-between" style={{ height:"120px", padding:20}}>
          
            <Space size={0} direction="vertical">
            <Title level={3} style={{ margin: 0, color: "white" }}>
              Phone number shop
            </Title>
              <Text style={{ color: "white" }}>
                Check your campaign statistics daily, see all responses,
                ratings, clicks and reports here. <br />
                You can edit and manage everything in the options panel.
              </Text>
                </Space>
              
                <Button
                  icon={<FaArrowRight />}
                  size="large"
                  className="flex-button"
                  style={{ width: "200px" }}
                  onClick={() => navigate("/Senders/sms")}
                >
                  View your numbers
                </Button>
            </Flex>
      </div>
    </>
  );
};

export default NumberPriceList;
