import { Col, Skeleton } from "antd";

const LoadingSubscriptionCard = () => {
    return <>
    <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Skeleton.Input active className="w-100" style={{height:"128px", marginTop:12}}/>
          </Col>
    </>
}

export default LoadingSubscriptionCard;