import DashboardNumericStats from "./Dashboards/DashboardNumericStats";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { Col, Row, Space, Typography } from "antd";
import ContactReachedPerMonthChart from "./Charts/ContactReachedPerMonthChart";
import { MdOutlineCallMade } from "react-icons/md";
import { TbMailOpenedFilled, TbBounceLeftFilled } from "react-icons/tb";
import { PiCursorClickFill } from "react-icons/pi";
import kFormatter from "../../shared/kFormat";
import Top5Campaigns from "./Campaigns/Top5Campaigns";
import SmsCampaigns from "./Campaigns/SmsCampaigns";
import CampaignTypes from "./Campaigns/CampaignTypes";

const Home = () => {
  const { Title, Text } = Typography;

  const [stats, setStats] = useState({
    requests: 0,
    opened: 0,
    clicked: 0,
    delivered: 0,
    bounces: 0,
    reports: 0,
    requestsLoading: true,
    openLoading: true,
    clickLoading: true,
    deliveredLoading: true,
    bounceLoading: true,
    reportsLoading: true,
  });

  const axions = useAxiosPrivate();

  useEffect(async () => {
    const statuses = ["processed", "open", "click", "bounce", "reports"];

    for (const status of statuses) {
      const response = await axions.get(
        `/Campaign/GetCampaingsStats?status=${status}`
      );

      if (status == statuses[0]) {
        setStats((prev) => ({
          ...prev,
          requests: response.data.data.count,
          requestsLoading: false,
        }));
      } else if (status == statuses[1]) {
        setStats((prev) => ({
          ...prev,
          opened: response.data.data.count,
          openLoading: false,
        }));
      } else if (status == statuses[2]) {
        setStats((prev) => ({
          ...prev,
          clicked: response.data.data.count,
          clickLoading: false,
        }));
      } else if (status == statuses[3]) {
        setStats((prev) => ({
          ...prev,
          bounces: response.data.data.count,
          bounceLoading: false,
        }));
      } else {
        setStats((prev) => ({
          ...prev,
          reports: response.data.data.count,
          reportsLoading: false,
        }));
      }
    }
  }, []);

  return (
    <Space style={{ width: "100%" }} direction="vertical" size={24}>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <DashboardNumericStats
            name="Requests"
            danger="primary"
            icon={<MdOutlineCallMade style={{ fontSize: 20 }} />}
            loading={stats.requestsLoading}
            value={stats.requests}
          />
        </Col>

        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <DashboardNumericStats
            name="Opened"
            loading={stats.openLoading}
            icon={<TbMailOpenedFilled style={{ fontSize: 20 }} />}
            value={stats.opened}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <DashboardNumericStats
            name="Clicked"
            loading={stats.clickLoading}
            value={stats.clicked}
            icon={<PiCursorClickFill style={{ fontSize: 20 }} />}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <DashboardNumericStats
            name="Bounces"
            color="#4F200D"
            danger="warning"
            loading={stats.bounceLoading}
            value={stats.bounces}
            icon={<TbBounceLeftFilled style={{ fontSize: 20 }} />}
          />
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <ContactReachedPerMonthChart
            title={"Delivered & Processed"}
            statuses={["delivered", "processed"]}
            type="line"
            children={
              <Space direction="vertical" size={0} style={{ width: 100 }}>
                <Title style={{ margin: 0, padding: 0 }} level={2}>
                  {kFormatter(stats.requests)}
                </Title>
                <Text className="text-light">Total sent</Text>
                <Text strong style={{ color: "#1cc88a" }}>
                  {(
                    (isNaN(stats.delivered / stats.requests)
                      ? 0
                      : stats.delivered / stats.requests) * 100
                  ).toFixed(2)}
                  %
                </Text>
              </Space>
            }
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <ContactReachedPerMonthChart
            title={"Bounce & Clicked & Dropped"}
            statuses={["click", "bounce", "dropped"]}
            type="bar"
          />
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Top5Campaigns />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <SmsCampaigns />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <CampaignTypes />
            </Col>
          </Row>
        </Col>
      </Row>
    </Space>
  );
};

export default Home;
