import { icon } from "@fortawesome/fontawesome-svg-core";
import { Button, Dropdown } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { FaBookmark, FaLockOpen } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import { RiChatFollowUpFill } from "react-icons/ri";

const TaskOptions = ({isFollowUp, id, onDelete, taskDone, onMarkTaskAsDone, onCreateFollowUp, onReopenTask }) => {
  const items = [
    {
      label: "Create follow-up task",
      key: 4,
      icon: <RiChatFollowUpFill />,
      disabled: !taskDone,
      onClick: () => onCreateFollowUp(id)
    },
    {
      icon: <FaLockOpen />,
      label: "Reopen task",
      key: 3,
      disabled: !taskDone,
      onClick: () => onReopenTask(id)
    },
    {
      icon: <FaBookmark />,
      label: "Mark task as done",
      key: 2,
      disabled: taskDone,
      onClick:() => onMarkTaskAsDone(id)
    },
    {
      label: "Delete selected task",
      key: 1,
      icon: <BsTrashFill />,
      danger: true,
      onClick: () => onDelete(id),
    },
  ];
  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          shape="circle"
          className="flex-button black-color-tag"
          type="text"
          icon={<IoMdMore style={{ fontSize: 20, color: "#1b254b" }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default TaskOptions;
