import { Typography } from "antd";
import OrderBySelect from "../../../shared/OrderBySelect";
import { useContext } from "react";
import { AudienceContext } from "../AudienceContext";

const ContactOrderByFilter = () => {
    const { filters, setFilters } = useContext(AudienceContext);
  
    const options = [
      {
        value: "created",
        label: "Contact created date"
      },
      {
        value: "name",
        label: "Contact firstname"
      },
      {
        value: "lastname",
        label: "Contact lastname"
      },
      {
        value: "email",
        label: "Contact email"
      },
    ];
  
    const handleChanged = (e) => {
      setFilters((prev) => ({ ...prev, orderBy: e }));
    };

    return (
        <OrderBySelect
          options={options}
          handleChanged={handleChanged}
          defaultValue={filters?.orderBy}
        />
      );
}

export default ContactOrderByFilter;