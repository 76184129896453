import {
  CompanyStepsContext,
} from "./CompanyStepsContext";
import { useContext } from "react";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import SetupStep from "./SetupStep";

const CompanyStepsLayout = () => {
  

  const { currentStep, setCurrentStep } = useContext(CompanyStepsContext);

  return <>
      { currentStep == 1 ? (
        <ThirdStep />
      ) : currentStep == 2 ? (
        <FourthStep />
      ) : currentStep == 3 ? (
        <FifthStep />
      ) : currentStep == 4 ? (
        <SixthStep />
      ) : <SetupStep/>
    }
    </>
};

export default CompanyStepsLayout;
