import { Outlet, useNavigate } from "react-router-dom";
import {
  CompanyStepsContext,
  CompanyStepsContextProvider,
} from "./CompanyStepsContext";
import { Button, Col, Grid, Row, Space } from "antd";
import background from "../../../../assets/images/tapzaplogin.png";
import CompanyStepsLayout from "./CompanyStepsLayout";
const { useBreakpoint } = Grid;

const CompanyStepLayout = () => {
    const navigate = useNavigate();
  const screens = useBreakpoint();
  return (
    <CompanyStepsContextProvider>
      <Row style={{ height: "100vh" }}>
        <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
          <CompanyStepsLayout />
        </Col>
        {(screens.lg || screens.xl || screens.xxl) && (
          <Col lg={9} xl={9} xxl={9}>
            <div
              className="shadow"
              style={{
                background: `url(${background})`,
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
              }}
            >
              <div style={{ position: "relative", top: "95%", left: "10%" }}>
                <Space>
                  <Button type="link" className="white-link-button">
                    Support
                  </Button>
                  <Button
                    type="link"
                    className="white-link-button"
                    onClick={() => navigate("/policy/terms-of-usage")}
                  >
                    Terms of use
                  </Button>
                  <Button
                    type="link"
                    className="white-link-button"
                    onClick={() => navigate("/policy/prviacy-policy")}
                  >
                    Privacy policy
                  </Button>
                </Space>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </CompanyStepsContextProvider>
  );
};

export default CompanyStepLayout;
