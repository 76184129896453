import { Col, Row} from "antd";
import CreateWhatsApptemplateData from "./CreateWhatsAppTemplateData";
import MessagePreview from "./MessagePreview";
import { WhatsAppTemplateContextProvider } from "../../../context/WhatsAppTemplateContext";
import { useParams } from "react-router-dom";

const CreateWhatsAppTemplate = () => {
  const { id } = useParams();

  return (
    <>
      <WhatsAppTemplateContextProvider>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}>
            <CreateWhatsApptemplateData />
          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
            <MessagePreview />
          </Col>
        </Row>
      </WhatsAppTemplateContextProvider>
    </>
  );
};

export default CreateWhatsAppTemplate;
