import { Select, Button, Col, Flex, Row, Typography, Card } from "antd";
import "./DialerLayout.css";
import { useState } from "react";
import { FaArrowLeft, FaCog } from "react-icons/fa";
import { MdCall, MdInfo } from "react-icons/md";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import AudioSettingsDrawer from "./AudioSettingsDrawer";

const DialerLayout = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [number, setNumber] = useState(() => urlParams.get("number"));

  const { Text, Title } = Typography;
  const navigate = useNavigate();

  const updateNumber = (num) => {
    if (number) {
      setNumber((prev) => `${prev}${num}`);
    } else {
      setNumber((prev) => `${num}`);
    }
  };

  const clearLast = () => {
    if (number.length > 0) {
      setNumber((prev) => prev.substring(0, prev.length - 1));
    }
  };

  const addPlus = () => {
    if (number.length == 0) {
      return;
    }

    if (number[0] == "+") {
      setNumber("+");
    }

    setNumber((prev) => `+${prev}`);
  };

  const [settingsOpen, setSettingsOpen]  = useState(false);


  const credential = { login_token: 'YOUR_LOGIN_TOKEN' };
  const options = {
    ringtoneFile: './ringtone.mp3',
    ringbackFile: './ringback.mp3',
  };


  return (
    <>
    
    <AudioSettingsDrawer open={settingsOpen} setOpen={setSettingsOpen}/>
      <Flex
        className="w-100 h-100"
        align="center"
        justify="space-between"
        vertical
      >
        <Card size="small" className="w-100 no-shadow">
          <Row>
            <Col span={8}>
              <Flex align="center" justify="start" gap={12}>
                <Button
                  onClick={() => navigate(-1)}
                  type="text"
                  icon={<FaArrowLeft />}
                  className="flex-button"
                ></Button>
                <img src={logo} width={40} />
                <Title className="zero-margin-padding phone-dialer-title">Phone</Title>
              </Flex>
            </Col>

            <Col span={8}>
              <Select
                className="w-100"
                size="large"
                placeholder="Select your number"
              />
            </Col>

            <Col span={8}>
              <Flex align="center" justify="end">
                <Button
                  shape="circle"
                  size="large"
                  className="flex-button"
                  icon={<FaCog />}
                  onClick={() => setSettingsOpen(true)}
                ></Button>
              </Flex>
            </Col>
          </Row>
        </Card>

        <Flex className="w-100" align="center" justify="center">
          <div className="dialer-wrapper">
            <div className="dialer-number-preview">
              <p>{number}</p>
            </div>
            <Row gutter={[6, 6]}>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(1)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  1
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(2)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  2
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(3)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  3
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(4)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  4
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(5)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  5
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(6)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  6
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(7)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  7
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(8)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  8
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(9)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  9
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                  onClick={addPlus}
                >
                  +
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => updateNumber(0)}
                  size="large"
                  className="bold dialer-button"
                  shape="circle"
                >
                  0
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  onClick={clearLast}
                  size="large"
                  disabled={number?.length == 0}
                  className="bold dialer-button"
                  shape="circle"
                >
                  <FaArrowLeft className="dialer-icon" />
                </Button>
              </Col>
            </Row>
          </div>
        </Flex>

        <Flex align="center" justify="start" className="w-100" gap={6}>
          <div className="dialer-info-icon">
            <MdInfo />
          </div>
          <Text className="dialer-info-text">Keep this page open to recieve incoming calls.</Text>
        </Flex>
      </Flex>

      <div className="call-button-wrapper">
        <Button className="class-button-dialer">
          <MdCall />
        </Button>
      </div>
    </>
  );
};

export default DialerLayout;
