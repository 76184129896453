import { Button, DatePicker, Flex, Typography } from "antd"
import { useContext } from "react"
import { TaskContext } from "../TaskContext"

const TaskToDateFilter = () => {
    const {Text} = Typography

    const {filters, setFilters} = useContext(TaskContext);

    return <>
    <Text>To</Text>
        <DatePicker size="large"
            defaultValue={filters?.to}
            value={filters?.to} showWeek
            onChange={(e) => setFilters(prev => ({...prev, to: e}))}
            placeholder="Select created date start"
            style={{ width: "100%", borderRadius: 15 }} />

    </>
}
export default TaskToDateFilter