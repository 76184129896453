import CampaignSteps from "./CampaignSteps";
import { CreateCampaignContextProvider } from "../../../context/CreateCampaignContext";
import { AudienceListContextProvider } from "../../audience/AudienceListContext";
import { Outlet } from "react-router-dom";
const CreateCampaign = () => {
  return (
    <CreateCampaignContextProvider>
      <AudienceListContextProvider>
        <Outlet/>
      </AudienceListContextProvider>
    </CreateCampaignContextProvider>
  );
};

export default CreateCampaign;
