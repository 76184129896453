import { Button, Flex, List, Space, Typography } from "antd";
import { FaLock } from "react-icons/fa";
import { RiPassValidFill } from "react-icons/ri";
import { FaSquarePhone } from "react-icons/fa6";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FacebookLogin from "../../../../components/Authentication/FacebookLogin";

const BusinessDetails = ({ current, setCurrent, loading, setLoading}) => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const listItems = [
    {
      title: "Legal details",
      icon: <FaLock />,
      description:
        "You must have your busines details at hand : your company's legal name and address, as well as your  business account as you want to display it.",
      link: "",
    },
    {
      title: "Website",
      icon: <FaSquarePhone />,
      description: "You must have a valid busines website",
      link: "",
    },
    {
      title: "Verification",
      icon: <RiPassValidFill />,
      description:
        "It is required that you verify your account with Meta as soon as possible. A verified business account can start to send 1k messages /day. If you do not verify your account, Meta will disable your account",
      link: "",
    },
  ];

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      <Title level={4}>Prepare your business details.</Title>

      <List
        bordered={true}
        dataSource={listItems}
        renderItem={(item, ind) => (
          <>
            <List.Item>
              <Flex>
                <Space style={{ width: "100%" }} direction="vertical" size={0}>
                  <Flex align="center" justify="start" gap={10}>
                    {item.icon}
                    <Title level={4} style={{ margin: 0 }}>
                      {item.title}
                    </Title>
                  </Flex>

                  <Text>{item.description}</Text>
                </Space>
              </Flex>
            </List.Item>
          </>
        )}
      ></List>

      <Flex align="center" justify="space-between">
        <Button
          className="flex-button"
          size="large"
          type="link"
          onClick={() => navigate(-1)}
        >
          <IoMdArrowRoundBack /> Back to whatsapp senders
        </Button>

        <FacebookLogin/>
      </Flex>
    </Space>
  );
};

export default BusinessDetails;
