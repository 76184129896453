import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import Card from "antd/es/card/Card";
import {
  Button,
  Col,
  Flex,
  Modal,
  Pagination,
  Result,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import TableLoading from "../../shared/TableLoading";
import CampaignOptions from "./CampaignOptions";
import NoDataFound from "../../shared/NoDataFound";
import { CampaignContext } from "./CampaignContext";
import { MdClose, MdDone, MdEdit } from "react-icons/md";
import { FaEye, FaHourglassEnd, FaPlay } from "react-icons/fa";
import { BsFillSendCheckFill } from "react-icons/bs";
import "./ListCampaigns.css";
import { AiFillEdit } from "react-icons/ai";
import { HiDuplicate } from "react-icons/hi";

const ListCampaigns = () => {
  const {
    campaigns,
    stats,
    setCampaings,
    page,
    setPage,
    campaignsLoading,
    setStats,
  } = useContext(CampaignContext);

  const { Title, Text, Link } = Typography;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
    setItemToDelete(item);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setItemToDelete(null);
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }

    await handleDeleteCampaing(item);
  };

  const handleDeleteCampaing = async (id) => {
    var response = await axiosPrivate.delete(`/Campaign/${id}`);

    if (response.data && response.data.success) {
      setCampaings(campaigns.filter((c) => c.id != id));
      setStats((prev) => ({ ...prev, all: prev.all - 1 }));
    }
  };

  const [addOpen, setAddOpen] = useState(false);

  const [duplicate, setDuplicate] = useState({
    loading: false,
    duplicateId: null,
    message: null,
  });
  const handleDuplicate = async (id) => {
    setDuplicate({
      loading: true,
      duplicateId: null,
      type: null,
      message: null,
    });

    const response = await axiosPrivate.post(`/Campaigns/Duplicate`, {
      id: id,
    });

    if (response) {
      const campaign = await axiosPrivate.get(
        `/Campaigns/Email/${response.data.id}`
      );

      setDuplicate({
        loading: false,
        duplicateId: response.data.id,
        type: response.data.type,
      });

      setCampaings((prev) => [campaign.data, ...prev]);
    } else {
      setDuplicate({
        loading: false,
        duplicateId: null,
        message: response.data.message,
      });
    }
  };

  return (
    <>
      <DuplicateModal duplicate={duplicate} setDuplicate={setDuplicate} />

      <DeleteConfirmationModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onDeleteConfirm={handleDeleteConfirm}
        item={itemToDelete}
      />

      <Space direction="vertical" className="w-100">
        <Card size="small">
          <Row>
            <Col span={7}>
              <Text className="column-header">Name</Text>
            </Col>
            <Col span={3}>
              <Text className="column-header">Status</Text>
            </Col>
            <Col span={3}>
              <Text className="column-header">Created</Text>
            </Col>
            <Col span={8}>
              <Text className="column-header">Performance</Text>
            </Col>
            <Col span={3}>
            </Col>
          </Row>
        </Card>

        {campaignsLoading ? (
          <TableLoading />
        ) : !campaigns || campaigns.length == 0 ? (
          <Card>
            <NoDataFound
              addText="New campaign"
              onAdd={() => setAddOpen(true)}
              description="Click the New Campaign button on the campaigns/email page to create a new email campaign"
              title="You have not created any email campaigns yet"
            />
          </Card>
        ) : (
          <>
            {campaigns.map((c, i) => (
              <Card size="small" key={i}>
                <Row>
                  <Col span={7} className="center-table-column">
                    <Space className="w-100" direction="vertical" size={0}>
                      <Text
                        type="link"
                        className="campaign-title"
                        onClick={() => {
                          if (c.campaignStaus == 2) {
                            navigate(`/Campaigns/Details/${c.id}`);
                          } else {
                            navigate(
                              `/Campaigns/Edit/${c.id}/${c.campaignType}`
                            );
                          }
                        }}
                      >
                        {c.name}
                      </Text>
                      <Space>
                        <Text className="campaign-recipients">
                          {c.totalReached ?? 0} Recipients
                        </Text>

                        {c.senderEmail && (
                          <Text className="campaign-recipients">
                            • {c.senderEmail}
                          </Text>
                        )}
                      </Space>
                    </Space>
                  </Col>
                  <Col span={3} className="center-table-column">
                    {c.saveStatus == 0 ? (
                      <Text strong>
                        <Flex align="center" gap={6}>
                          <MdEdit style={{ fontSize: 18 }} />
                          DRAFT
                        </Flex>
                      </Text>
                    ) : c.campaignStaus == 0 ? (
                      <Text strong>
                        <Flex align="center" gap={6}>
                          <MdEdit style={{ fontSize: 18 }} />
                          DRAFT
                        </Flex>
                      </Text>
                    ) : c.campaignStaus == 1 ? (
                      <Text style={{ color: "#1cc88a" }} strong>
                        <Flex align="center" gap={6}>
                          <FaPlay style={{ fontSize: 18 }} />
                          ACTIVE
                        </Flex>
                      </Text>
                    ) : c.campaignStaus == 2 ? (
                      <Text style={{ color: "#1cc88a" }} strong>
                        <Flex align="center" gap={6}>
                          <MdDone style={{ fontSize: 18 }} />
                          DONE
                        </Flex>
                      </Text>
                    ) : (
                      "Invalid"
                    )}
                  </Col>
                  <Col span={3} className="center-table-column">
                    <Text className="campaign-created-date">
                      {new Date(c.createdAt * 1000).toLocaleDateString()}
                    </Text>
                  </Col>
                  <Col span={8} className="center-table-column">
                    {c.campaignStaus == 2 ? (
                      <Row gutter={[12, 12]} className="w-100">
                        <Col span={4}>
                          <PerformanceTag
                            value={c.requests}
                            background={"#a1a1a1"}
                            icon={<FaHourglassEnd style={{ color: "#fff", fontSize:18 }} />}
                          />
                        </Col>
                        <Col span={4}>
                          <PerformanceTag
                            value={c.delivered}
                            background={"#422afb"}
                            icon={
                              <BsFillSendCheckFill style={{ color: "#fff", fontSize:18 }} />
                            }
                          />
                        </Col>
                        <Col span={4}>
                          <PerformanceTag
                            value={c.opens}
                            background={"#1cc88a"}
                            icon={<FaEye style={{ color: "#fff", fontSize:18 }} />}
                          />
                        </Col>
                        <Col span={4}>
                          <PerformanceTag
                            value={c.bounced}
                            background={"#e74a3b"}
                            icon={
                              <MdClose
                                style={{ fontSize: 16, color: "#fff", fontSize:18 }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Col>
                  <Col span={3} className="center-table-column">
                    <Flex
                      className="w-100"
                      align="center"
                      justify="end"
                      gap={6}
                    >
                      <Tooltip title="Edit">
                        <Button
                          icon={
                            <AiFillEdit className="campaign-options-icon" />
                          }
                          size="large"
                          disabled={c.saveStatus != 0}
                          className="flex-button"
                          type="text"
                          onClick={() =>
                            navigate(
                              `/Campaigns/Edit/${c.id}/${c.campaignType}`
                            )
                          }
                        ></Button>
                      </Tooltip>
                      <Tooltip title="Duplicate">
                        <Button
                          icon={
                            <HiDuplicate className="campaign-options-icon" />
                          }
                          size="large"
                          className="flex-button"
                          type="text"
                          onClick={() => handleDuplicate(c.id)}
                        ></Button>
                      </Tooltip>
                      <CampaignOptions
                        campaign={c}
                        onDelete={(id) => handleOpenModal(id)}
                      />
                    </Flex>
                  </Col>
                </Row>
              </Card>
            ))}

            <Card size="small">
              <Flex align="center" justify="end">
                <Pagination
                  current={page}
                  pageSize={7}
                  total={stats?.all}
                  onChange={(page, pageSize) => {
                    setPage(page);
                  }}
                />
              </Flex>
            </Card>
          </>
        )}
      </Space>
    </>
  );
};

const DuplicateModal = ({ duplicate, setDuplicate }) => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  return (
    <Modal
      open={duplicate.loading || duplicate.duplicateId}
      onCancel={(e) =>
        setDuplicate({
          loading: false,
          duplicateId: null,
          message: null,
        })
      }
      footer={null}
    >
      {duplicate.loading && (
        <Flex align="center" justify="center" style={{ height: 140 }}>
          <Spin size="large" />
        </Flex>
      )}
      {!duplicate.loading && duplicate.duplicateId && (
        <Result
          status="success"
          title="Successfully Duplicated Campaign!"
          subTitle="The requested campaign was successfuly duplicated, you may now edit its details as required!"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() =>
                navigate(
                  `/Campaigns/Edit/${duplicate?.duplicateId}/${duplicate?.type}`
                )
              }
            >
              Edit audience
            </Button>,
            <Button
              key="buy"
              onClick={() => {
                setDuplicate({
                  loading: false,
                  duplicateId: null,
                  message: null,
                });
              }}
            >
              Cancel
            </Button>,
          ]}
        />
      )}
    </Modal>
  );
};

const PerformanceTag = ({ value, background, icon }) => {
  const { Text } = Typography;
  return (
    <Tag color={`${background}`} className="w-100 campaign-performace-tag">
      <Flex align="center" gap={6} justify="space-between">
        <Flex
          align="center"
          justify="center"
        >
          {icon}
        </Flex>

        <Text style={{ color: "#fff", fontSize: 18 }}>
          {value}
        </Text>
      </Flex>
    </Tag>
  );
};

export default ListCampaigns;
