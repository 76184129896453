import { useContext } from "react";
import { RiDraftFill } from "react-icons/ri";
import { Col, Row } from "antd";
import { MdCampaign } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { BsFillClockFill } from "react-icons/bs";
import Stat from "../../shared/Stat";
import { CampaignContext } from "./CampaignContext";

const Stats = () => {
  const { stats } = useContext(CampaignContext);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.all}
            name="Total"
            icon={<MdCampaign style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.completed}
            name="Completed"
            icon={<FaCalendarCheck style={{ fontSize: 20 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.draft}
            name="Draft"
            icon={<RiDraftFill style={{ fontSize: 20 }} />}
            loading={false}
          />
        </Col>
     
        <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.scheduled}
            name="Scheduled"
            icon={<BsFillClockFill style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default Stats;
