import { DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";
import { MdArrowDropDown } from "react-icons/md";

const EmailSenderCreatedDateToFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(EmailSenderContext);
  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{color:"#a5b6d5"}}>To</Text>
      <DatePicker
        size="large"
        defaultValue={filters.to}
        value={filters?.to}
        onChange={(e) => setFilters((prev) => ({ ...prev, to: e }))}
        placeholder="Select created date end"
        className="dates-filter-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
      />
    </Flex>
  );
};

export default EmailSenderCreatedDateToFilter;
