import {
  Button,
  Card,
  Flex,
  List,
  Modal,
  Result,
  Space,
  Typography,
} from "antd";
import { IoPricetags } from "react-icons/io5";
import { RiContactsBook2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const SenderCreated = ({ open, setOpen }) => {
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const items = [
    {
      label:
        "You can initiate up to 250 conversations today! To increase the sending limit you will need to increase your message quality rating.",
      icon: <RiContactsBook2Fill />,
    },
    {
      label:
        "First 0 conversations this month are free. Learn more about pricing.",
      icon: <IoPricetags />,
    },
  ];
  return (
    <Modal open={open} onCancel={() => {navigate('/Senders/whatsapp')}} style={{ width: 600 }} footer={null}>
      <Result
        status="success"
        title={
          <Title style={{ margin: 0 }} level={4}>
            You can now use your WhatsApp Business account with TAPZAP
          </Title>
        }
        subTitle={
          <Flex align="center" justify="center">
            <List
              style={{ maxWidth: 600 }}
              dataSource={items}
              renderItem={(item, ind) => (
                <List.Item>
                  <Space size={0}>
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        fontSize: 26,
                        borderRadius: 15,
                      }}
                      className="flex-button"
                    >
                      {item.icon}
                    </div>
                    <Text>{item.label}</Text>
                  </Space>
                </List.Item>
              )}
            ></List>
          </Flex>
        }
        extra={[
          <Button
            size="large"
            type="primary"
            onClick={() => navigate("/Senders/whatsapp")}
          >
            Go to your senders
          </Button>,
          <Button size="large" onClick={() => navigate("/Campaigns")}>
            Check campaigns
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default SenderCreated;
