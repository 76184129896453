import { DatePicker, Flex, Typography } from "antd";
import { useContext, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { FollowupContext } from "../FollowupContext";

const FollowupToDateFilter = () => {
  const { Text } = Typography;

  const {filters, setFilters} = useContext(FollowupContext)

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{color:"#a5b6d5"}}>To</Text>
      <DatePicker
        size="large"
        placeholder="Select created date end"
        className="dates-filter-select"
        value={filters?.to}
        onChange={e => setFilters(prev => ({...prev, to: e}))}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
    </Flex>
  );
};

export default FollowupToDateFilter;
