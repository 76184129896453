import { InboxOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Space, Typography, Upload } from "antd";
import { useState, useTransition } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
const AddFileModal = ({ open, setOpen }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState();

  const axios = useAxiosPrivate();

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    onChange(info) {
      const selectedFile = info?.fileList[0]?.originFileObj;

      if (selectedFile) {
        setFile(selectedFile);
      } else {
        setFile(null);
      }

      const { status } = info.file;
    },
    onDrop(e) {},
  };

  const handleSubmit = async () => {
    setLoading(true);
    const form = new FormData();

    form.append("File", file);
    form.append("DealId", id);

    var response = await axios.post("/DealFile", form);

    setLoading(false);
    setOpen(false)

    if (!response || !response.data.success) {
      return;
    }
  };

  const { Text, Title } = Typography;

  return (
    <Modal
      title={
        <Title style={{ margin: 0 }} level={4}>
          Upload file for deal
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type="primary" loading={loading} onClick={() => handleSubmit()}>
            Save changes
          </Button>
        </Flex>
      }
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Strictly prohibited from uploading company data or other banned
            files.
          </p>
        </Dragger>
      </Space>
    </Modal>
  );
};

export default AddFileModal;
