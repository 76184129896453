import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Collapse, Flex, Row, Space, Tag, Typography } from "antd";
import { FaDotCircle } from "react-icons/fa";
import { DateFormat } from "../../../../../dateformat";
import {
  StepExecutionConditionBridge,
  StepExecutionType,
} from "../../../../../shared/enums";
import FollowupStepOptions from "../../../../followup/create/FollowupStepOptions";

const DealDetailsStepsList = ({ setFollowUp }) => {
  const axios = useAxiosPrivate();
  const { id } = useParams();
  const { Text, Title } = Typography;

  const navigate = useNavigate();

  const [data, setData] = useState();

  useEffect(async () => {
    const response = await axios.get(`/Followup/Step/Execution/${id}`);

    setData(response.data.items);
    setFollowUp({
      id: response.data.followUpId,
      name: response.data.followUpName,
    });
  }, []);
  return (
    <>
      {data && data.length > 0 ? (
        <Space direction="vertical" className="w-100">
          {data.map((c, i) => (
            <CollapseHeader data={c} />
          ))}
        </Space>
      ) : (
        <Card style={{ boxShadow: "none" }}>
          <Text className="text-light" strong>
            No follow ups found for this deal!
          </Text>
        </Card>
      )}
    </>
  );
};

const CollapseHeader = ({ data }) => {
  const { Text, Title } = Typography;

  return (
    <Card style={{ boxShadow: "none" }} size="small">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={10} xl={8} lg={10} xxl={8}>
          <Flex align="start" justify="start" gap={6}>
            {data?.executed ? (
              <FaDotCircle
                style={{ fontSize: 18, marginTop: 5, color: "#1cc88a" }}
              />
            ) : (
              <FaDotCircle
                style={{ fontSize: 18, marginTop: 5, color: "#e74a3b" }}
              />
            )}
            <Space direction="vertical" size={0}>
              <Title style={{ margin: 0, padding: 0 }} level={4}>
                {data?.followUpStepName}
              </Title>
              <Text>
                {new Date(data?.createdDate).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}
              </Text>
            </Space>
          </Flex>
        </Col>

        <Col xs={24} md={14} xl={16} lg={14} xxl={16}>
          <Flex align="center" justify="end" gap={6}>
            {data?.conditionMet ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Condition met
              </Tag>
            ) : (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Condition not met
              </Tag>
            )}

            <Flex align="center" gap={6} justify="end">
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                {data.executeAfterDays} days
              </Tag>
            </Flex>

            {data?.bridge == StepExecutionConditionBridge.And ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                And
              </Tag>
            ) : data?.bridge == StepExecutionConditionBridge.Or ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Or
              </Tag>
            ) : data?.bridge == StepExecutionConditionBridge.Not ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Not
              </Tag>
            ) : (
              ""
            )}

            {data?.executionCondition == StepExecutionType.UserSignedUp ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Sign up
              </Tag>
            ) : data?.executionCondition == StepExecutionType.UserSubscribed ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Subscribe
              </Tag>
            ) : data?.executionCondition ==
              StepExecutionType.UserUnsubscribed ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Unsubscribe
              </Tag>
            ) : data?.executionCondition == StepExecutionType.UserDeleted ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Delete
              </Tag>
            ) : data?.executionCondition == StepExecutionType.TrialExceded ? (
              <Tag className="icon-flex black-color-tag" color="#e3e3e3">
                Trial exceded
              </Tag>
            ) : (
              ""
            )}
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default DealDetailsStepsList;
