import {
  Avatar,
  Button,
  Card,
  Dropdown,
  Flex,
  List,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { FiMoreVertical, FiPlus } from "react-icons/fi";
import { BsFillKeyFill } from "react-icons/bs";
import ApiKeyOperations from "./ApiKeyOperations";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CreateApiKey from "./CreateApiKey";

const YourApiKeys = () => {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const { Title, Text } = Typography;
  const [isOpen, setIsOpen] = useState(false);
  const [keys, setKeys] = useState();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const perPage = 5;

  useEffect(async () => {
    const response = await axios.post("/Api/GetAll", {
      page: page,
      pageSize: perPage,
    });
    setTotal(response.data.count);
    setKeys(response.data.data);
  }, [page]);

  const columns = [
    {
      title: "Name",
      render: (row, data) => <Text strong>{row.name}</Text>,
    },
    {
      title: "Key",
      render: (row) => (
        <Text strong>
          <BsFillKeyFill /> ******************
        </Text>
      ),
    },
    {
      title: "Date",
      render: (data, row) => <Text strong>
        {new Date(row.createdAt).toLocaleDateString()}
      </Text>
    },
    {
      title: "",
      width: 50,
      render: (row) => <ApiKeyOperations id={row.id} setData={setKeys} />,
    },
  ];

  return (
    <>
      {isOpen && (
        <CreateApiKey setData={setKeys} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      <Flex align="center" justify="space-between" style={{marginBottom:5}}>
        <Title level={4} style={{ margin: 5 }}>
          Api keys ({total})
        </Title>

        <Space>
          <Button
            type="primary"
            size="large"
            className="primary-gradient-background flex-button bold-button"
            icon={<FiPlus style={{ fontSize: 20 }} />}
            style={{ borderRadius: 15 }}
            onClick={() => setIsOpen(true)}
          >
            New key
          </Button>
        </Space>
      </Flex>

      <Card className="zero-margin-padding" style={{ boxShadow: "none" }}>
        <Table
          dataSource={keys}
          columns={columns}
          pagination={{
            page: page,
            pageSize: perPage,
            total: total,
            onChange: (page, pageSize) => setPage(page),
          }}
        ></Table>
      </Card>
    </>
  );
};

export default YourApiKeys;
