import { Segmented, Select, Space, Typography } from "antd";
import { useContext } from "react";
import { NotificationTemplatesContext } from "../NotificationTemplatesContext";
import { OrderDirection } from "../../../../shared/enums";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
const NotificationTemplatesOrderDirectionFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(NotificationTemplatesContext);

  return (
    <Select
      size="large"
      default={filters?.orderDirection}
      defaultValue={filters?.orderDirection}
      style={{width:"150px"}}
      className="order-by-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
      onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
      options={[
        {
          value: OrderDirection.Asc,
          label: "A - Z",
        },
        {
          value: OrderDirection.Dsc,
          label: "Z - A",
        },
      ]}
    />
  );
};

export default NotificationTemplatesOrderDirectionFilter;
