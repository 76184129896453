import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Flex,
  Typography,
  Input,
  Table,
  Space,
  Tag,
  Button,
  Popover,
} from "antd";
import TableLoading from "../../../shared/TableLoading";
import { DateFormat } from "../../../dateformat";
import { FaRegClock } from "react-icons/fa";
import { HiInformationCircle } from "react-icons/hi";
import { MdClose } from "react-icons/md";
const SmsResponses = ({ id }) => {
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();

  const [data, setData] = useState();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(() => 1);
  const [loading, setLoading] = useState(() => false);

  const perPage = 10;

  const columns = [
    {
      title: "Number",
      render: (data, row, index) => (
        <Space direction="vertical" size={0}>
          <Text strong>
            {(page - 1) * perPage + index + 1}. {row.number}
          </Text>
          <Text className="text-light icon-flex">
            <FaRegClock />
            {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
          </Text>
        </Space>
      ),
    },
    {
      title: "Errors",
      render: (data, row, index) =>
        row.errorCode ? (
          <Tag color="red">
            <Flex align="center" justify="center" gap={0}>
              {row.errorCode}

              <Popover
                title="Sms delivery error"
                trigger={["click"]}
                content={<Text danger>{row.message}</Text>}
              >
                <Button
                  size="small"
                  type="text"
                  danger
                  icon={<HiInformationCircle />}
                  className="flex-button"
                ></Button>
              </Popover>
            </Flex>
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "Delivered",
      width: 100,
      render: (data, row, index) =>
        !row.errorCode ? (
          <Tag color="green" bordered={false}>
            DELIVERED
          </Tag>
        ) : (
            <Flex align="center" justify="center">

          <MdClose style={{fontSize:20, color:"#e74a3b"}}/>
            </Flex>
        ),
    },
  ];
  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(
      `/Campaign/${id}/responses/sms/${page}/${perPage}`
    );

    setData(response.data.data);
    setTotal(response.data.count);
    setLoading(false);
  }, [page]);
  return (
    <>
      <Flex align="center" justify="space-between">
        <Title level={3}>All responses ({total})</Title>

        <Input
          placeholder="Search..."
          size="large"
          style={{ width: "270px" }}
        />
      </Flex>

      {loading && <TableLoading />}

      {!loading && (
        <Table
          dataSource={data}
          columns={columns}
          pagination={{
            current: page,
            pageSize: perPage,
            total: total,
            onChange: (page, pagesize) => {
              setPage(page);
            },
          }}
        />
      )}
    </>
  );
};

export default SmsResponses;
