import { Button, Drawer, Flex, Select, Space, Typography } from "antd";
import { useEffect, useState } from "react";

const AudioSettingsDrawer = ({ open, setOpen }) => {
  const { Text, Title } = Typography;

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [selectedInput, setSelectedInput] = useState('');
  const [selectedOutput, setSelectedOutput] = useState('');

  const handleInputChange = (event) => {
    setSelectedInput(event.target.value);
  };

  const handleOutputChange = (event) => {
    setSelectedOutput(event.target.value);
    if ('setSinkId' in HTMLMediaElement.prototype) {
      // Assuming you have a media element
      const mediaElement = document.getElementById('audioElement');
      mediaElement.setSinkId(event.target.value)
        .then(() => {
          console.log(`Audio output set to ${event.target.value}`);
        })
        .catch(error => {
          console.error('Error setting audio output:', error);
        });
    } else {
      console.warn('Output device selection is not supported in your browser');
    }
  };

  useEffect(() => {
    async function getDevices() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const inputs = devices.filter(device => device.kind === 'audioinput');
        const outputs = devices.filter(device => device.kind === 'audiooutput');
        setAudioInputDevices(inputs);
        setAudioOutputDevices(outputs);
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    }

    getDevices();
  }, []);


  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} title="Audio settings">
        <Flex className="h-100" vertical justify="space-between">
          <Space className="w-100" size="large" direction="vertical">
            <Text>Change your audio settings</Text>

            <Space className="w-100" direction="vertical" size={24}>
              <Space className="w-100" direction="vertical" size={0}>
                <Text className="bold">Input</Text>
                <Select onChange={handleInputChange} value={selectedInput} className="w-100" size="large" options={audioInputDevices.map(c => ({
                    value: c.deviceId,
                    label: c.label
                }))} />
              </Space>

              <Space className="w-100" direction="vertical" size={0}>
                <Text className="bold">Output</Text>
                <Select onChange={handleOutputChange} value={selectedOutput} className="w-100" size="large" options={audioOutputDevices.map(c => ({
                    value: c.deviceId,
                    label: c.label
                }))} />
              </Space>
            </Space>
          </Space>

          <Flex align="center" justify="end" gap={6}>
            <Button size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="primary" size="large">Save changes</Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default AudioSettingsDrawer;
