import { Modal } from "antd";
import "./PreviewModal.css";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TableLoading from "../../shared/TableLoading";
const PreviewTemplateModal = ({ open, setOpen, id, type }) => {
  const axios = useAxiosPrivate();
  const [html, setHtml] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(!id || !type){
      return;
    }

    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchHtml = async () => {
      const response = await axios.get(`/Templates/Email/Html/${id}/${type}`, {
        signal: signal,
      });

      setHtml(response.data.html);
      setLoading(false);
    };

    fetchHtml();

    return () => controller.abort();
  }, [id, type]);
  return (
    <Modal centered
      size="small"
      title="Template preview"
      width={1000}
      open={open}
      onCancel={() => setOpen(false)}
      footer={false}
    >
      {loading ? (
        <>
          <TableLoading />
        </>
      ) : (
        <div
          className="preview-modal-content"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      )}
    </Modal>
  );
};

export default PreviewTemplateModal;
