import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";

const DeleteUser = ({ user, handleDelete }) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(() => false);

  const onDelete = async () => {
    setLoading(true);

    const response = await axios.post("/Users/DeleteUsers", {
      ids: [user],
    });

    if (response.data.success) {
      handleDelete(user);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  return (
    <Popconfirm
      okText="Yes, delete!"
      title="Delete selected user!"
      onConfirm={onDelete}
      description="Are you sure you want to remove the selected user?"
    >
      Delete selected user
    </Popconfirm>
  );
};

export default DeleteUser;
