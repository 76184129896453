import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import AssignedUsersSelect from "../deals/FormItems/AssignedUsersSelect";

const AddProduct = ({ show, setShow, onAdd }) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.post(`/Product/Create`, {
      name: e.name,
      description: e.description,
      autoFollowupEmails: e.autoFollowupEmails,
      ownerIds: e.owners,
      autoFollowupNotifications: e.autoFollowupNotifications,
    });

    if (response.data.success) {
      onAdd(response.data);
      setShow(false);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={show}
      destroyOnClose
      onCancel={() => setShow(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Create a new product
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={5}>
          <Button onClick={() => setShow(false)}>Close</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save product
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={onSubmit}>
        <Text>Name</Text>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter a name",
            },
          ]}
        >
          <Input placeholder="Enter a product name" size="large" />
        </Form.Item>

        <AssignedUsersSelect
          name="owners"
          setDefault={(e) => form.setFieldValue("owners", [e])}
        />

        <Space className="w-100" size={0} direction="vertical">
          <Form.Item name="autoFollowupEmails" valuePropName="checked" className="no-error">
            <Checkbox>Automatic email followups</Checkbox>
          </Form.Item>
          <Form.Item name="autoFollowupNotifications" valuePropName="checked" className="no-error">
            <Checkbox>Automatic notification followups</Checkbox>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddProduct;
