import { Button, Card, Flex, Select, Space, Typography } from "antd";
import DealDetailsStepsList from "./DealDetailsStepsList";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DealDetailsSteps = () => {
  const { Title, Text } = Typography;
  const [followUp, setFollowUp] = useState();

  const navigation = useNavigate();

  return (
    <>
      <Space className="w-100" direction="vertical">
        <Flex align="center" justify="space-between">
          <Title style={{ margin: 5 }} level={4}>
            Follow up executions
          </Title>

          {followUp?.name && (
            <Flex align="center" justify="start" gap={6}>
              <Text>Go to: </Text>
              <Button
                onClick={() => navigation(`/Followup/details/${followUp?.id}`)}
                type="link"
                style={{ paddingLeft: 0 }}
              >
                {followUp?.name}
              </Button>
            </Flex>
          )}
        </Flex>
        <DealDetailsStepsList setFollowUp={setFollowUp} />
      </Space>
    </>
  );
};

export default DealDetailsSteps;
