import { DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { SmsListContext } from "../SmsListContext";
import { MdArrowDropDown } from "react-icons/md";

const SmsFromDateFilter = () => {
    const {Text} = Typography;
    const {filters, setFilters} = useContext(SmsListContext)
  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{color:"#a5b6d5"}}>From</Text>
      <DatePicker
        size="large"
        placeholder="Select created date start"
        defaultValue={filters?.from}
        value={filters?.from}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            from: e,
          }))
        }
        className="dates-filter-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
      />
    </Flex>
  );
};

export default SmsFromDateFilter;
