import { Button, Flex, List, Space, Typography } from "antd";
import { FaLock } from "react-icons/fa";
import { RiPassValidFill } from "react-icons/ri";
import { FaSquarePhone } from "react-icons/fa6";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GrLinkNext } from "react-icons/gr";

const FacebookAccount = ({ current, setCurrent }) => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const listItems = [
    {
      title: "Authentication",
      icon: <FaLock />,
      description:
        "To authenticate your business account, open this link on a separate tab.",
      link: "",
    },
    {
      title: "Code",
      icon: <FaSquarePhone />,
      description:
        'You must now generate a code in your authentication app and enter it in the "Enter code" field',
      link: "",
    },
    {
      title: "Post",
      icon: <RiPassValidFill />,
      description: "Once you confirm, you can close the tab",
      link: "",
    },
  ];

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      <Title level={4}>
        You must have a personal Facebook account (linked to your business
        email).
      </Title>

      <Text>
        If you have a Facebook business account created and logged in on this
        system, then you must authenticate it before you start the onboarding
        process by clickking Login with Facebook.
      </Text>

      <List
        bordered={true}
        dataSource={listItems}
        renderItem={(item, ind) => (
          <>
            <List.Item>
              <Flex>
                <Space style={{ width: "100%" }} direction="vertical" size={0}>
                  <Flex align="center" justify="start" gap={10}>
                    {item.icon}
                    <Title level={4} style={{ margin: 0 }}>
                      {item.title}
                    </Title>
                  </Flex>

                  <Text>{item.description}</Text>
                </Space>
              </Flex>
            </List.Item>
          </>
        )}
      ></List>

      <Flex align="center" justify="space-between">
        <Button
          className="flex-button"
          size="large"
          type="link"
          onClick={() => navigate(-1)}
        >
          <IoMdArrowRoundBack /> Back to whatsapp senders
        </Button>

        <Button
          type="primary"
          size="large"
          className="primary-gradient-background flex-button bold-button"
          onClick={() => setCurrent(current + 1)}
          icon={<GrLinkNext/> }
        >
          Continue to Details
        </Button>
      </Flex>
    </Space>
  );
};

export default FacebookAccount;
