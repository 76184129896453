import { arrayMove } from "@dnd-kit/sortable";
import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Form,
  InputNumber,
  Modal,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const WonDealModal = ({ open, onSubmit, onClose }) => {
  const { Title, Text } = Typography;

  return (
    <Modal
      destroyOnClose
      title={
        <Title style={{ margin: 0, padding: 0 }} level={4}>
          Mark Deal As Won
        </Title>
      }
      centered
      open={open}
      footer={<></>}
      onCancel={onClose}
    >
      <Divider style={{ margin: "5px 0px" }} />
      <Form layout="vertical" onFinish={onSubmit}>
        <Space className="w-100" direction="vertical" size={0}>
          <Text>Close Date</Text>
          <Form.Item
            name="closeDate"
            rules={[
              {
                required: true,
                message: "Please choose a date!",
              },
            ]}
          >
            <DatePicker size="large" className="w-100" style={{ borderRadius:15 }} />
          </Form.Item>
        </Space>
        <Space size={0} className="w-100" direction="vertical">
          <Text>Total Revenue</Text>
          <Form.Item
            name="revenue"
            rules={[
              {
                required: true,
                message: "Please enter a value!",
              },
            ]}
          >
            <InputNumber className="w-100" size='large' placeholder="Enter total revenue"/>
          </Form.Item>
        </Space>
        <Space size={0} direction="vertical" className="w-100">
          <Form.Item label="Closed Won Reason" name="closedReason">
            <TextArea className="w-100" size="large" />
          </Form.Item>
        </Space>
        <Flex align="center" justify="end" gap={12}>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" htmlType="submit">
            Save deal
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default WonDealModal;
