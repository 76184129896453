import { Outlet } from "react-router-dom";
import { CampaignContextProvider } from "./CampaignContext";

const CampaignLayout = () => {

  return (
    <>
      <CampaignContextProvider>
        <Outlet />
      </CampaignContextProvider>
    </>
  );
};

export default CampaignLayout;
