import { Avatar, Col, Row } from "antd";
import { MdCampaign, MdEmail, MdFirstPage, MdLastPage, MdOutlineClose } from "react-icons/md";
import Stat from "../../../shared/Stat";
import { useContext } from "react";
import { EmailSenderContext } from "./EmailSenderContext";
import { DateFormat } from "../../../dateformat";
import { FaCheck } from "react-icons/fa";
import { GrDomain } from "react-icons/gr";

const SenderStats = () => {
    const {stats} = useContext(EmailSenderContext)

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.total}
            name="Total"
            icon={<MdEmail style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.confirmed}
            name="Confirmed"
            icon={<FaCheck style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.first ? new Date(stats?.first * 1000).toLocaleDateString("en-US", DateFormat) : "-"}
            name="First"
            icon={<MdFirstPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.last ? new Date(stats?.last * 1000).toLocaleDateString("en-US", DateFormat) : "-"}
            name="Last"
            icon={<MdLastPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default SenderStats;
