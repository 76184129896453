import {
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  message,
  Pagination,
  Progress,
  Row,
  Space,
  Statistic,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { DateFormat } from "../../../dateformat";
import ButtonIcon from "../../../shared/ButtonIcon";
import { FiPlus } from "react-icons/fi";
import {
  MdEmail,
  MdNavigateBefore,
  MdNavigateNext,
  MdOutlineEqualizer,
  MdSms,
} from "react-icons/md";
import { IoEllipsisHorizontal, IoLogoWhatsapp } from "react-icons/io5";
import {
  FaArrowLeft,
  FaFolderOpen,
  FaPen,
  FaRegTrashAlt,
  FaTrash,
  FaTrophy,
} from "react-icons/fa";
import { CardStatus } from "../../../shared/enums";
import { AiFillSchedule } from "react-icons/ai";
import { RiGitCommitFill, RiTreasureMapFill } from "react-icons/ri";
import { FaHandshakeSimple } from "react-icons/fa6";
import { IoMdCloseCircle, IoMdMore } from "react-icons/io";
import QueryString from "qs";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import CountUp from "react-countup";
import AddTagToContactSider from "../../contacts/AddTagToContactSider";
import CreateDealDrawer from "../../deals/CreateDealDrawer/CreateDealDrawer";
import ContactCommentsList from "../../contacts/Comments/ContactCommentsList";
import VerifyContact from "../../contacts/Comments/VerifyContact";
import SendEmailModal from "../../../shared/SendEmailModal";
import CallContactModal from "./CallContactModal";
import "./ContactDetails.css";
import CallNumberModal from "./CallNumberModal";
import ContactCommucations from "./ContactCommucations";

const formatter = (value, from = 0) => (
  <CountUp once={true} start={from} end={value} separator="," />
);

const ContactDetails = () => {
  const { id } = useParams();
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();

  const [editTagsOpen, setEditTagsOpen] = useState(false);

  const [details, setDetails] = useState();

  const navigate = useNavigate();

  const [navigation, setNavigation] = useState(null);
  const [responses, setResponses] = useState();
  const [responsesLoading, setResponsesLoading] = useState(false);
  const [responsePagination, setResponsePagination] = useState();
  const [page, setPage] = useState(1);
  const [counts, setCounts] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchCounts = async () => {
      let response = await axios.get(`Contacts/Counts/${id}`, {
        signal: signal,
      });

      setCounts(response.data);
    };

    const fetchNav = async () => {
      let response = await axios.get(`/Contact/Navigation/${id}`, {
        signal: signal,
      });

      setNavigation(response.data);
    };

    const fetchDetails = async () => {
      let response = await axios.get(`/Contact-Details/${id}`, {
        signal: signal,
      });

      setDetails(response.data);
    };

    fetchDetails();
    fetchNav();
    fetchCounts();

    return () => controller.abort();
  }, [id]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchResponses = async () => {
      setResponsesLoading(true);
      let response = await axios.get(`/Contact/Responses`, {
        signal: signal,
        params: {
          id: id,
          page: page,
          pageSize: 7,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setResponses(response.data.items);
      setResponsePagination({
        total: response.data.total,
      });
      setResponsesLoading(false);
    };
    fetchResponses();

    return () => controller.abort();
  }, [page, id]);

  const onTagsUpdate = (id, newTags) => {
    setDetails((prev) => ({
      ...prev,
      audiences: newTags,
    }));
  };

  const removeFromTag = async (tagId) => {
    const response = await axios.delete(
      `/Contacts/Remove-From-Tag/${tagId}/${id}`
    );

    if (response.data.success) {
      setDetails((prev) => ({
        ...prev,
        audiences: prev.audiences.filter((c) => c.id !== tagId),
      }));
    } else {
      message.error(response.data.message);
    }
  };

  const removeFromDeal = async (dealId) => {
    const response = await axios.post(`/Deal/DeleteContact`, {
      dealId: dealId,
      contactId: id,
    });

    if (response.data.success) {
      setDetails((prev) => ({
        ...prev,
        dealTags: prev.dealTags.filter((c) => c.id !== dealId),
      }));
    } else {
      message.error(response.data.message);
    }
  };
  const [addDealOpen, setAddDealOpen] = useState(false);

  const onDealCreated = async (e) => {
    const response = await axios.post("/Deal/CreateDeal", {
      emailId: e.contacts,
      companyIds: e.companies,
      stage: e.status,
      ownerIds: e.assignedUser,
      amount: e.amount,
      closeDate: e.closeDate,
      title: e.title,
      productIds: e.products,
    });

    if (!response.data.success) {
      message.error(response.data.message);
      return;
    }
    setDetails((prev) => ({
      ...prev,
      dealTags: [
        ...prev.dealTags,
        {
          dealStage: e.status,
          id: response.data.id,
          name: e.title,
          createdAt: response.data.createdOn,
          inThisStageSince: response.data.inStageSince,
        },
      ],
    }));

    setAddDealOpen(false);
  };

  const removeContact = async () => {
    const response = await axios.delete(`/Contacts/${id}`);

    if (response.data.success) {
      message.success("Contact removed");
      navigate(`/audience`);
    } else {
      message.error(response.data.message);
    }
  };

  const [sendOpen, setSendOpen] = useState(false);
  const [callOpen, setCallOpen] = useState(false);
  const [callNumberOpen, setCallNumberOpen] = useState(false);

  return (
    <>
      <CallNumberModal
        open={callNumberOpen}
        setOpen={setCallNumberOpen}
        fullName={details?.name}
        number={details?.phone}
      />
      <CallContactModal
        open={callOpen}
        setOpen={setCallOpen}
        fullName={details?.name}
        id={id}
        email={details?.email}
      />
      <SendEmailModal open={sendOpen} setOpen={setSendOpen} contactId={id} />
      <AddTagToContactSider
        onUpdate={onTagsUpdate}
        contact={details}
        open={editTagsOpen}
        setOpen={setEditTagsOpen}
      />
      <CreateDealDrawer
        onSubmit={onDealCreated}
        contactId={id}
        isDrawerOpen={addDealOpen}
        setIsDrawerOpen={setAddDealOpen}
      />

      <Space direction="vertical" className="w-100" size={24}>
        <Card size="small">
          <Flex align="center" justify="space-between">
            <Flex align="center" justify="start" gap={6}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                icon={<FaArrowLeft />}
                className="flex-button"
              ></Button>
              <Title className="zero-margin-padding" level={4}>
                Contact Details
              </Title>
            </Flex>

            <Flex align="center" justify="end" gap={6}>
              <Card
                bodyStyle={{ padding: 0 }}
                style={{ boxShadow: "none" }}
                size="small"
              >
                <Flex align="center" justify="space-between" gap={6}>
                  <Button
                    disabled={navigation?.contactIndex == 0}
                    type="text"
                    className="flex-button"
                    icon={<MdNavigateBefore style={{ fontSize: 22 }} />}
                    onClick={() =>
                      navigate(
                        `/audience/contact/${navigation?.oneContactsBefore}`
                      )
                    }
                  ></Button>
                  <Text strong>
                    {navigation?.contactIndex} of {navigation?.totalContacts}
                  </Text>
                  <Button
                    disabled={
                      navigation?.contactIndex == navigation?.totalContacts
                    }
                    type="text"
                    className="flex-button"
                    onClick={() =>
                      navigate(
                        `/audience/contact/${navigation?.oneContactsAfter}`
                      )
                    }
                    icon={<MdNavigateNext style={{ fontSize: 22 }} />}
                  ></Button>
                </Flex>
              </Card>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: 1,
                      label: "Remove current contact",
                      danger: true,
                      icon: <FaTrash />,
                      onClick: () => removeContact(),
                    },
                  ],
                }}
              >
                <Button
                  type="text"
                  shape="circle"
                  className="flex-button"
                  icon={<IoMdMore style={{ fontSize: 26, color: "#7e7e7e" }} />}
                />
              </Dropdown>
            </Flex>
          </Flex>
        </Card>

        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Space
              style={{
                position: "sticky",
                top: 80,
              }}
              className="w-100"
              size={24}
              direction="vertical"
            >
              <Card size="small">
                <Row>
                  <Col span={6}>
                    {details && (
                      <ProfileAvatar
                        name={details?.name}
                        size={100}
                        font={38}
                      />
                    )}
                  </Col>
                  <Col span={18}>
                    <Space className="w-100" direction="vertical" size={24}>
                      <Space className="w-100" direction="vertical" size={0}>
                        <Flex align="center" justify="start" gap={6}>
                          <Title level={4} style={{ margin: 0 }}>
                            {details?.name}
                          </Title>

                          {details?.emailStatus == 1 && (
                            <Tag
                              color="green"
                              className="flex-button"
                              style={{ fontWeight: 600, borderWidth: "2px" }}
                            >
                              Safe
                            </Tag>
                          )}
                        </Flex>
                        <Text>
                          {details?.email} • {details?.phone}
                        </Text>
                        <Text>
                          Joined{" "}
                          {new Date(
                            details?.createdAt * 1000
                          ).toLocaleDateString("en-us", DateFormat)}
                        </Text>
                      </Space>
                      <Card size="small" style={{ boxShadow: "none" }}>
                        <Text>Verification level</Text>

                        <Progress
                          strokeColor={
                            details?.emailStatus == 1
                              ? "#1cc88a"
                              : details?.emailStatus == 2
                              ? "#e74a3b"
                              : details?.emailStatus == 3
                              ? "orage"
                              : details?.emailStatus == 4
                              ? "gray"
                              : "blue"
                          }
                          percent={
                            details?.emailStatus == 1
                              ? 100
                              : details?.emailStatus == 2
                              ? 100
                              : details?.emailStatus == 3
                              ? 90
                              : details?.emailStatus == 4
                              ? 50
                              : 0
                          }
                        />
                      </Card>

                      <Space size={0} direction="vertical">
                        <Text>Communicate</Text>
                        <Flex align="center" justify="start" gap={6} wrap>
                          <Button
                            className="contact-button"
                            size="small"
                            onClick={() => setSendOpen(true)}
                          >
                            Send Email
                          </Button>
                          <Button
                            disabled
                            className="contact-button"
                            size="small"
                            onClick={() => setCallOpen(true)}
                          >
                            Arrange Meet
                          </Button>
                          <Button
                            disabled
                            className="contact-button"
                            size="small"
                            onClick={() => setCallNumberOpen(true)}
                          >
                            Call
                          </Button>

                          <Button
                            className="contact-button"
                            size="small"
                            disabled
                          >
                            Whatsapp
                          </Button>
                          <Button
                            className="contact-button"
                            size="small"
                            disabled
                          >
                            SMS
                          </Button>
                        </Flex>{" "}
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Card>

              <Card size="small">
                <Flex align="center" justify="space-between">
                  <Title level={4} style={{ margin: 0 }}>
                    Contact tags
                  </Title>
                  <Button
                    type="primary"
                    className="flex-button new-buttons"
                    onClick={() => setEditTagsOpen(true)}
                  >
                    Edit
                    <ButtonIcon
                      icon={<FaPen />}
                      background={"#5a6bc5"}
                      size="large"
                    />
                  </Button>
                </Flex>
                {details?.audiences && details?.audiences?.length > 0 ? (
                  <Text>
                    This contact includes tags such as
                    {details?.audiences?.map((c, i) => (
                      <Card
                        key={i}
                        size="small"
                        style={{
                          boxShadow: "none",
                          borderTopLeftRadius: i == 0 ? 10 : 0,
                          borderTopRightRadius: i == 0 ? 10 : 0,
                          borderBottomLeftRadius:
                            i == details.audiences.length - 1 ? 10 : 0,
                          borderBottomRightRadius:
                            i == details.audiences.length - 1 ? 10 : 0,
                        }}
                      >
                        <Flex align="center" justify="space-between">
                          <Text type="link">{c.name}</Text>
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: 1,
                                  label: "Remove from selected tag",
                                  icon: <FaRegTrashAlt />,
                                  danger: true,
                                  onClick: () => removeFromTag(c.id),
                                },
                              ],
                            }}
                            trigger={["click"]}
                          >
                            <Button
                              shape="circle"
                              className="flex-button"
                              type="text"
                              icon={
                                <IoEllipsisHorizontal
                                  style={{ fontSize: 26, color: "#b1b1b0" }}
                                />
                              }
                            ></Button>
                          </Dropdown>
                        </Flex>
                      </Card>
                    ))}
                  </Text>
                ) : (
                  <Text>
                    * This contact does not belong on any contact list!
                  </Text>
                )}
              </Card>

              <Card size="small">
                <Flex align="center" justify="space-between">
                  <Title level={4} style={{ margin: 0 }}>
                    Contact deals
                  </Title>
                  <Button
                    onClick={() => setAddDealOpen(true)}
                    type="primary"
                    className="flex-button new-buttons"
                  >
                    New deal
                    <ButtonIcon
                      icon={<FiPlus />}
                      background={"#5a6bc5"}
                      size={"large"}
                    />
                  </Button>
                </Flex>

                {details?.dealTags && details?.dealTags?.length > 0 ? (
                  <Text>
                    This contact includes deals such as:
                    {details?.dealTags?.map((c, i) => (
                      <Card
                        key={i}
                        size="small"
                        style={{
                          boxShadow: "none",
                          borderTopLeftRadius: i == 0 ? 10 : 0,
                          borderTopRightRadius: i == 0 ? 10 : 0,
                          borderBottomLeftRadius:
                            i == details.audiences.length - 1 ? 10 : 0,
                          borderBottomRightRadius:
                            i == details.audiences.length - 1 ? 10 : 0,
                        }}
                      >
                        <Flex align="center" justify="space-between">
                          <Flex align="center" justify="start" gap={6}>
                            <Flex
                              align="center"
                              justify="center"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: 30,
                                background: "#e3e3e3",
                                color: "#1b254b",
                              }}
                            >
                              {c.dealStage == CardStatus.New ? (
                                <FaFolderOpen style={{ fontSize: 18 }} />
                              ) : c.dealStage == CardStatus.Qualifying ? (
                                <MdOutlineEqualizer style={{ fontSize: 22 }} />
                              ) : c.dealStage == CardStatus.DemoScheduled ? (
                                <AiFillSchedule style={{ fontSize: 22 }} />
                              ) : c.dealStage ==
                                CardStatus.PendingCommitment ? (
                                <RiGitCommitFill style={{ fontSize: 22 }} />
                              ) : c.dealStage == CardStatus.InNegotiation ? (
                                <FaHandshakeSimple style={{ fontSize: 22 }} />
                              ) : c.dealStage == CardStatus.Won ? (
                                <FaTrophy style={{ fontSize: 22 }} />
                              ) : (
                                <IoMdCloseCircle style={{ fontSize: 22 }} />
                              )}
                            </Flex>
                            <Space
                              className="w-100"
                              direction="vertical"
                              size={0}
                            >
                              <Flex
                                align="start"
                                justify="space-between"
                                gap={0}
                              >
                                <Title
                                  onClick={() =>
                                    navigate(`/Deals/Details/${c.id}`)
                                  }
                                  level={5}
                                  type="link"
                                  strong
                                  style={{ margin: 0, padding: 0 }}
                                >
                                  {c.name}
                                </Title>
                                <Text strong>
                                  {c.dealStage == CardStatus.New
                                    ? "1 / 6 - New"
                                    : c.dealStage == CardStatus.Qualifying
                                    ? "2 / 6 - Qualifying"
                                    : c.dealStage == CardStatus.DemoScheduled
                                    ? "3 / 6 - Demo Scheduled"
                                    : c.dealStage ==
                                      CardStatus.PendingCommitment
                                    ? "4 / 6 - Pending Commitment"
                                    : c.dealStage == CardStatus.InNegotiation
                                    ? "5 / 6 - In Negotiation"
                                    : c.dealStage == CardStatus.Won
                                    ? "6 / 6 Won"
                                    : "6 / 6 LOST"}
                                </Text>
                              </Flex>
                              <Text></Text>

                              <Progress
                                className="w-100"
                                strokeColor="#422afb"
                                percent={c.dealStage * 17}
                                showInfo={false}
                              />
                              <Text>
                                Opened{" "}
                                {new Date(
                                  c.createdAt * 1000
                                ).toLocaleDateString("en-us", DateFormat)}{" "}
                                • On this stage since{" "}
                                {new Date(
                                  c.inThisStageSince * 1000
                                ).toLocaleDateString("en-us", DateFormat)}
                              </Text>
                            </Space>
                          </Flex>
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: 1,
                                  label: "Remove from deal",
                                  icon: <FaRegTrashAlt />,
                                  danger: true,
                                  onClick: () => removeFromDeal(c.id),
                                },
                              ],
                            }}
                            trigger={["click"]}
                          >
                            <Button
                              shape="circle"
                              className="flex-button"
                              type="text"
                              icon={
                                <IoEllipsisHorizontal
                                  style={{ fontSize: 26, color: "#b1b1b0" }}
                                />
                              }
                            ></Button>
                          </Dropdown>
                        </Flex>
                      </Card>
                    ))}
                  </Text>
                ) : (
                  <Text>* This contact does not belong on any deals!</Text>
                )}
              </Card>
            </Space>
          </Col>
          <Col span={16}>
            <Card size="small">
              <Space className="w-100" direction="vertical" size={24}>
                <Space className="w-100" direction="vertical" size={0}>
                  <Title level={4} style={{ margin: 0 }}>
                    Overview
                  </Title>

                  <Card size="small" style={{ boxShadow: "none" }}>
                    <Space direction="vertical" className="w-100">
                      <Row gutter={[24, 24]}>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Sent</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              formatter={formatter}
                              value={counts?.sent}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Delivered</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              formatter={formatter}
                              value={counts?.delivered}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Opens</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              formatter={formatter}
                              value={counts?.open}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Clicks</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              formatter={formatter}
                              value={counts?.clicks}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Space>
                  </Card>
                </Space>

                <Tabs
                  size="large"
                  defaultActiveKey={1}
                  items={[
                    {
                      key: 0,
                      label: "Communications",
                      children: <ContactCommucations />,
                      disabled: true,
                    },
                    {
                      key: 1,
                      label: "Marketing",
                      children: (
                        <Space className="w-100" direction="vertical" size={0}>
                          {responsesLoading ? (
                            <TableLoading />
                          ) : responses && responses.length > 0 ? (
                            <>
                              <Space className="w-100" direction="vertical">
                                <Space
                                  className="w-100"
                                  direction="vertical"
                                  size={0}
                                >
                                  {responses?.map((c, i) => (
                                    <Card
                                      key={i}
                                      size="small"
                                      style={{
                                        boxShadow: "none",
                                        borderTopLeftRadius: i == 0 ? 10 : 0,
                                        borderTopRightRadius: i == 0 ? 10 : 0,
                                        borderBottomLeftRadius:
                                          i == responses.length - 1 ? 10 : 0,
                                        borderBottomRightRadius:
                                          i == responses.length - 1 ? 10 : 0,
                                      }}
                                    >
                                      <Flex
                                        align="center"
                                        justify="space-between"
                                        className="w-100"
                                      >
                                        <Flex
                                          align="center"
                                          justify="start"
                                          gap={6}
                                        >
                                          <div>
                                            <Flex
                                              align="center"
                                              justify="center"
                                              style={{
                                                width: 35,
                                                height: 35,
                                                borderRadius: 40,
                                                background: "#e1e1e1",
                                              }}
                                            >
                                              {c.campaign.campaignType == 0 ? (
                                                <MdEmail
                                                  style={{
                                                    fontSize: 18,
                                                  }}
                                                />
                                              ) : c.campaign.type == 1 ? (
                                                <IoLogoWhatsapp
                                                  style={{
                                                    fontSize: 18,
                                                  }}
                                                />
                                              ) : (
                                                <MdSms
                                                  style={{
                                                    fontSize: 18,
                                                  }}
                                                />
                                              )}
                                            </Flex>
                                          </div>
                                          <Space
                                            direction="vertical"
                                            className="w-100"
                                            size={0}
                                          >
                                            <Text
                                              type="link"
                                              onClick={() =>
                                                navigate(
                                                  `/Campaigns/Details/${c.campaign.id}`
                                                )
                                              }
                                              strong
                                            >
                                              {c.campaign.name}
                                            </Text>
                                            <Text>
                                              {new Date(
                                                c.date * 1000
                                              ).toLocaleDateString(
                                                "en-us",
                                                DateFormat
                                              )}
                                            </Text>
                                          </Space>
                                        </Flex>

                                        <Space
                                          direction="vertical"
                                          space={0}
                                          align="end"
                                        >
                                          <Text strong>{c.status}</Text>

                                          <Text>
                                            {c.status == "dropped"
                                              ? `1 out of ${c.campaign.dropped}`
                                              : c.status == "processed"
                                              ? `1 out of ${c.campaign.processed}`
                                              : c.status == "click"
                                              ? `1 out of ${c.campaign.click}`
                                              : c.status == "delivered"
                                              ? `1 out of ${c.campaign.delivered}`
                                              : c.status == "bounce"
                                              ? `1 out of ${c.campaign.bounce}`
                                              : c.status == "spamreport"
                                              ? `1 report out of ${c.campaign.spamreport}`
                                              : c.status == "deferred"
                                              ? `1 out of ${c.campaign.deferred}`
                                              : `1 out of ${c.campaign.open}`}
                                          </Text>
                                        </Space>
                                      </Flex>
                                    </Card>
                                  ))}
                                </Space>

                                <Flex align="center" justify="end">
                                  <Pagination
                                    current={page}
                                    total={responsePagination?.total}
                                    showSizeChanger={false}
                                    onChange={(page) => setPage(page)}
                                  />
                                </Flex>
                              </Space>
                            </>
                          ) : (
                            <NoDataFound
                              onAdd={() => navigate("/Campaigns")}
                              title="No marketing data found"
                              description="Click the New Campaign button on the campaigns page to create a new email campaign"
                              addText="Go to campaigns"
                            />
                          )}
                        </Space>
                      ),
                    },
                    {
                      key: 2,
                      label: "Comments",
                      children: <ContactCommentsList id={id} />,
                    },
                    {
                      key: 3,
                      label: "Verification",
                      children: <VerifyContact email={details?.email} />,
                    },
                  ]}
                ></Tabs>
              </Space>
            </Card>
          </Col>
        </Row>
      </Space>
    </>
  );
};
export default ContactDetails;
