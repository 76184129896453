import { Button, message } from "antd";
import { useState } from "react";
import { FaCartShopping } from "react-icons/fa6";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { loadStripe } from "@stripe/stripe-js";
import env from '../../../../env.json'

const PayButton = ({ prices, phoneNumber, lat, long, region }) => {
  const stripePromise = loadStripe(env.stripePK);

  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const handlePayment = async (number) => {
    setLoading(true);
    const response = await axios.post(`/PhoneNumbers/pay`, {
      countryIos: prices.isoCountry,
      phoneNumber: number,
      type: "Toll Free",
      lat: lat,
      long:long,
      region: region
    });

    if (response.data.success) {
      const stripe = await stripePromise;

      // When the Checkout Session is created, redirect the user to the Stripe Checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (result.error) {
        message.error(result.error.message);
      }
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Button
      type="primary"
      size="large"
      disabled
      icon={<FaCartShopping />}
      onClick={() => handlePayment(phoneNumber)}
      loading={loading}
      className="flex-button primary-gradient-background bold-button"
    >
      Buy
    </Button>
  );
};

export default PayButton;
