import {
  Button,
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Space,
  Typography,
  message,
} from "antd";
import React, { useEffect } from "react";
import useDealsDetailsCtx from "../../../../hooks/useDealsDetailsCtx";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";

const DealDetailsData = () => {
  const axios = useAxiosPrivate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const { dealDetails, setDealDetails } = useDealsDetailsCtx();
  const { Title, Text } = Typography;

  const onSubmit = async (e) => {
    const response = await axios.post("/Deal/UpdateDealDetails", {
      id: id,
      title: e.name,
      closedDate: e.closeDate,
      amount: e.amount,
    });

    if (response.data.success) {
      setDealDetails(e);
      message.success("Deal data updated");
    } else {
      message.error(response.data.message);
    }
  };

  useEffect(() => {
    form.setFieldValue("name", dealDetails.name);
    form.setFieldValue("amount", dealDetails.amount);
    form.setFieldValue("closeDate", dealDetails.closeDate);
    form.setFieldValue("createdOn", dealDetails.createdOn);
  }, [dealDetails]);

  return (<>
  
    <Title style={{ margin: 5 }} level={4}>Deal details</Title>
    <Card style={{boxShadow:"none"}}>

    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Space size={0} direction="vertical" className="w-100">
        <Text>Name</Text>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please Enter a Name",
            },
          ]}
          name="name"
        >
          <Input size="large"></Input>
        </Form.Item>
      </Space>

      <Space size={0} direction="vertical" className="w-100">
        <Text>Amount</Text>
        <Form.Item name="amount">
          <InputNumber size="large" style={{ width: "100%" }}></InputNumber>
        </Form.Item>
      </Space>

      <Space size={0} direction="vertical" className="w-100">
        <Text>Close Date</Text>
        <Form.Item name="closeDate">
          <DatePicker
            size="large"
            style={{ width: "100%", borderRadius: 15 }}
          ></DatePicker>
        </Form.Item>
      </Space>

      <Space className="w-100" direction="vertical" size={0}>
        <Text>Created On</Text>
        <Form.Item name="createdOn">
          <DatePicker
            disabled
            size="large"
            style={{ width: "100%", borderRadius: 15 }}
          ></DatePicker>
        </Form.Item>
      </Space>

      <Flex justify="end" gap={10}>
        <Button htmlType="reset">Reset</Button>
        <Button htmlType="submit" type="primary">
          Save changes
        </Button>
      </Flex>
    </Form>
    </Card>
    
    </>
  );
};

export default DealDetailsData;
