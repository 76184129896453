import { useSearchParams } from "react-router-dom";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, Flex, Form, Input, Typography, Modal, Tooltip } from "antd";
import ButtonIcon from "../../../shared/ButtonIcon";
import { FaArrowLeft, FaCheck, FaSignature, FaUserAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import UseAiDrawer from "../../campaigns/CreateUpdate/UseAIDrawer";
import AddSignatureDrawer from "./AddSignatureDrawer";
import { Editor } from "@tinymce/tinymce-react";
import AddPersonalisationDrawer from "./AddPersonalisationDrawer";

const CreateSimpleTemplate = () => {
  const editorRef = useRef();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");
  const cmpId = searchParams.get("cmpId");

  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState('');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleSave = async (e) => {
    setLoading(true);

    var formData = new FormData();

    formData.append("Html", "");
    formData.append("Name", e.name);
    formData.append("Style", "");
    formData.append("IsSimple", true);
    formData.append("SimpleHtml", editorState);
    if (cmpId) {
      formData.append("CmpId", cmpId);
    }

    var response = await axiosPrivate.post("/Template", formData);

    if (!response || !response.data.success) {
      return;
    }

    setLoading(false);

    if (returnUrl) {
      navigate(`/${returnUrl}`);
    } else {
      navigate(`/Templates/Email`);
    }
  };

  const [saveOpen, setSaveOpen] = useState(false);

  const [aiOpen, setAiOpen] = useState(false);

  const saveAi = (e) => {
    setEditorState(e);
    setAiOpen(false);
  };

  const [signatureOpen, setSignatureOpen] = useState(false);

  const onSave = (e) => {
   
    const editor = editorRef.current;
    if (editor) {
      const span = editor.dom.select('div#signature')[0];
      if (span) {
        editor.dom.remove(span);
      }

      const newContent = editor.getContent({ format: 'html' }) + e;
      editor.setContent(newContent);

      editor.fire('change');
    }

    setSignatureOpen(false);
  };

  const [addPersonalisationOpen, setAddPersonalisationOpen] = useState(false);
  
  const onAddPersonalisation = (e) =>{
    if (editorRef.current) {
      const editor = editorRef.current;
      editor.insertContent(e);
    }

    setAddPersonalisationOpen(false);
  }
  return (
    <>
    <AddPersonalisationDrawer  onSave={onAddPersonalisation} open={addPersonalisationOpen} setOpen={setAddPersonalisationOpen}/>

      <AddSignatureDrawer
        onSave={onSave}
        open={signatureOpen}
        setOpen={setSignatureOpen}
      />
      <UseAiDrawer
        text={editorState}
        open={aiOpen}
        setOpen={setAiOpen}
        onSave={saveAi}
      />
      <Form onFinish={handleSave} form={form}>
        <SaveModal
          loading={loading}
          open={saveOpen}
          form={form}
          setOpen={setSaveOpen}
        />
      </Form>

      <div
        style={{
          height: "60px",
          width: "100%",
          background: "#fff",
        }}
      >
        <Flex
          align="center"
          justify="space-between"
          style={{
            height: "60px",
            borderTop: `1px solid #cbd5e0`,
            margin: "0px 10px",
          }}
        >
          <Flex align="center" justify="start" gap={6}>
            <Tooltip title="Go back">
              <Button
                className="flex-button"
                size="large"
                icon={<FaArrowLeft />}
                onClick={() => navigate(-1)}
              ></Button>
            </Tooltip>
            <Tooltip title="Add signature">
              <Button
                size="large"
                className="flex-button"
                onClick={() => setSignatureOpen(true)}
                icon={<FaSignature className="icon" />}
              ></Button>
            </Tooltip>
          </Flex>
          <Flex align="center" justify="end" gap={6}>
            <Tooltip title="Insert personalisation">
              <Button
                size="large"
                className="flex-button"
                onClick={() => setAddPersonalisationOpen(true)}
                icon={<FaUserAlt />}
              ></Button>
            </Tooltip>

            <Tooltip title="Use Artificial Intelligence">
              <Button
                size="large"
                className="bold"
                onClick={() => setAiOpen(true)}
              >
                Use AI
              </Button>
            </Tooltip>
            <Button
              size="large"
              type="primary"
              className="bold"
              onClick={() => setSaveOpen(true)}
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </div>

      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        className="subject-text-box"
        apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
        onEditorChange={setEditorState}
        value={editorState}
        init={{
          plugins: "",
          toolbar: true,
          menubar: true,
          statusbar: true,
          height: "100%",
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help'
        }}
      />
    </>
  );
};

const SaveModal = ({ open, setOpen, form, loading }) => {
  const { Text, Title } = Typography;

  return (
    <Modal
      title={
        <Title style={{ margin: 0 }} level={3}>
          Save simple desing
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button
            className="flex-button new-buttons"
            onClick={() => setOpen(false)}
          >
            Cancel{" "}
            <ButtonIcon
              icon={<MdCancel style={{ fontSize: 16 }} />}
              background={"#fff"}
            />
          </Button>
          <Button
            type="primary"
            className="flex-button new-buttons"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes{" "}
            <ButtonIcon
              icon={<FaCheck style={{ fontSize: 14 }} />}
              background={"#5a6bc5"}
            />
          </Button>
        </Flex>
      }
    >
      <Text>Name</Text>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "A name is required" },
          {
            max: 50,
            message: "Name should contain at most 20 characters",
          },
        ]}
      >
        <Input
          size="large"
          count={{
            show: true,
            max: 50,
          }}
          placeholder="Enter a name for your design"
          allowClear
        />
      </Form.Item>
    </Modal>
  );
};
export default CreateSimpleTemplate;
