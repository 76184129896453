import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../shared/AuthorSelectListItem";
import useAuth from "../hooks/useAuth";

export const AuthorContext = createContext({
    authors: [],
    setAuthors: () => {},
    you: null,
    setYou: () => {}
})

export const AuthorContextProvider = ({children}) => {
    const {auth} = useAuth();
    const [authors, setAuthors] = useState();
    const [you, setYou] = useState(() => auth.id);

    const axios = useAxiosPrivate();

    useEffect(async () => {
        const response = await axios.get(`Users/GetAuthors`);

        setAuthors(response.data.data.map((d) => ({
            value: d.id,
            name: `${d.firstname} ${d.lastname}`,
            profile: d.profileImagePath,
            label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),  
        })))

    }, [])

    return <AuthorContext.Provider value={{
        authors,
        you
    }}>
        {children}
    </AuthorContext.Provider>
}