import React, { useEffect, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import env from '../../env.json'
import { useParams } from 'react-router-dom';

    const loadJitsiScript = () => {

        return new Promise((resolve, reject) => {
          const existingScript = document.getElementById('jitsi-script');
          if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://meet.tapzap.ai/external_api.js';
            script.id = 'jitsi-script';
            script.onload = () => resolve(true);
            script.onerror = () => reject(new Error('Failed to load Jitsi API script'));
            document.body.appendChild(script);
          } else {
            resolve(true);
          }
        });
      };
      
      const VideoCallLayout = () => {
        const {id} = useParams();
        const jitsiContainer = useRef(null);
        const [currentUser, setCurrentUser] = useState();

        const axios = useAxiosPrivate();

        useEffect(async () => {
            const fetchUser = async () => {
                const response = await axios.get("Users/CurrentUser");

                console.log(response);

                setCurrentUser(response.data.data);

                return response.data.data
            } 

            const fetchRoom = async () => {
                const response  = await axios.get(`/Meeting-Room/${id}`);

                return response.data
            }

            const room = await fetchRoom();
            const user = await fetchUser();

          loadJitsiScript()
            .then(() => {
              if (window.JitsiMeetExternalAPI) {
                const domain = 'meet.tapzap.ai';
                const options = {
                  roomName: room.roomId,
                  parentNode: jitsiContainer.current,
                  userInfo: {
                    displayName: `${user.firstname} ${user.lastname}`,
                    avatarURL: `${env.fileUpload}${user.profileImagePath}`
                  },
                };
                const api = new window.JitsiMeetExternalAPI(domain, options);
      
                // Optional: add event listeners or customize further
      
                return () => {
                  api.dispose();
                };
              } else {
                console.error('Jitsi Meet API script not loaded');
              }
            })
            .catch((err) => {
              console.error('Error loading Jitsi script:', err);
            });
        }, []);
      
        return <div ref={jitsiContainer} style={{ width: '100%', height: '100vh' }}></div>;
      };


export default VideoCallLayout