import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import useDealsDetailsCtx from '../../../hooks/useDealsDetailsCtx'
import { CardStatus } from '../../../shared/enums'
import LostDealModal from '../LostDealModal'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { message } from 'antd'
import { DealListContext } from '../DealListContext'
const DealDetailsLostModal = ({open,setOpen}) => {
    const {id} = useParams()
    const axios = useAxiosPrivate();

    const {setDealStatus} = useDealsDetailsCtx()
    
    const {refreshStats} = useContext(DealListContext)

    const changeDealStatus = async (e) => {
      const response = await axios.post('/Deal/UpdateDealStatus',{
        id: id,
        newStage: CardStatus.Closed,
        lostReason: e.reason,
        additionalComments: e.additionalComments
      });

      if(response.data.success){
        setDealStatus(prev=>({...prev,status:CardStatus.Closed}))
        setOpen(false)
        await refreshStats();
      }else{
        message.error(response.data.message);
      }
    }
    function onClose() {
        setOpen(false)
    }
  return (
    <LostDealModal onSubmit={changeDealStatus} open={open} onClose={onClose}/>
  )
}

export default DealDetailsLostModal