import {
  Input,
  Button,
  Flex,
  Form,
  Modal,
  Space,
  Typography,
  Select,
  message,
} from "antd";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
const CreateTemplateComponentModal = ({ open, setOpen }) => {
  const { Title, Text } = Typography;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const navigate = useNavigate();

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post(`/Template-Component`, {
      name: e.name,
      type: e.type,
    });

    if (response.data.success) {
      navigate(`/Templates/Create/Component/${response.data.id}`);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  const options = [
    {
      label: "User",
      value: 1,
    },
    {
      label: "Product",
      value: 2,
    },
    {
      label: "Regular component",
      value: 3,
    },
  ];
  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Title className="zero-margin-padding" level={4}>
            Create your template component
          </Title>
        }
        footer={
          <Flex align="center" justify="end" gap={6}>
            <Button onClick={() => setOpen(false)} size="large">
              Cancel
            </Button>{" "}
            <Button
              size="large"
              type="primary"
              loading={loading}
              onClick={() => form.submit()}
            >
              Save changes
            </Button>{" "}
          </Flex>
        }
      >
        <Form form={form} onFinish={submit}>
          <Space direction="vertical" size={0} className="w-100">
            <Text className="thick-lable">Name *</Text>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  max: 128,
                },
                {
                  min: 3,
                },
              ]}
            >
              <Input
                size="large"
                count={{
                  show: true,
                  max: 128,
                }}
                placeholder="Enter a name you will remember"
              />
            </Form.Item>
          </Space>

          <Space direction="vertical" size={0} className="w-100">
            <Text className="thick-lable">What does it represent *</Text>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={options}
                size="large"
                placeholder="Enter the type of the component"
              />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTemplateComponentModal;
