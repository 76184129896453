import { Flex, Space, Spin, Statistic, Typography } from "antd";
import Card from "antd/es/card/Card";
import { useContext } from "react";
import CountUp from "react-countup";

const formatter = (value) => <CountUp end={value} separator="," />;
const DashboardNumericStats = ({ name, icon, value, loading }) => {
  const { Text } = Typography;

  return (
    <>
      <Card size="small">
        <Spin spinning={loading}>
          <Flex align="center" justify="space-between" gap={6}>
            <Space direction="vertical" size={0}>
              <Text>{name}</Text>

              <Statistic
                style={{ marginTop: "-5px" }}
                precision={0}
                value={value}
                formatter={formatter}
                valueStyle={{ fontWeight: 600 }}
              />
            </Space>
            <Flex
              align="center"
              justify="center"
              style={{
                width: "50px",
                height: "50px",
                background: "#f2f2fd",
                borderRadius: "50px",
              }}
            >
              {icon}
            </Flex>
          </Flex>
        </Spin>
      </Card>
    </>
  );
};

export default DashboardNumericStats;
