import { DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";
import { MdArrowDropDown } from "react-icons/md";

const EmailSenderCreatedDateFromSelect = () => {
  const { Text } = Typography;
  const { filters, setFilters } = useContext(EmailSenderContext);

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{color:"#a5b6d5"}}>From</Text>
      <DatePicker
        size="large"
        defaultValue={filters?.from}
        value={filters?.from}
        onChange={(e) => setFilters((prev) => ({ ...prev, from: e }))}
        placeholder="Select created date from"
        className="dates-filter-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
      />
    </Flex>
  );
};

export default EmailSenderCreatedDateFromSelect;
