import { useContext } from "react";
import { ProductListContext } from "../ProductListContext";
import OrderBySelect from "../../../shared/OrderBySelect";

const OrderByProductFilter = () => {
    const {filters, setFilters} = useContext(ProductListContext)

    const options = [
        {
          value: "created",
          label: "Date created"
        },
        {
          value: "name",
          label: "Name"
        },
      ];

      const handleChanged = (e) => {
        setFilters(prev => ({...prev, orderBy:e}))
      }
    return   <>
    <OrderBySelect
      options={options}
      defaultValue={filters?.orderBy}
      handleChanged={(e) => handleChanged(e)}
    />
    
  </>
}

export default OrderByProductFilter;