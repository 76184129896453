import {
  Button,
  Card,
  Flex,
  List,
  Popconfirm,
  Space,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import AddContact from "./AddContact";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import { FaTrashAlt } from "react-icons/fa";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import DealContactsOptions from "./DealContactsOptions";
import { FaPen } from "react-icons/fa6";
import DeleteConfirmationModal from "../../../../../components/DeleteConfirmationModal";
const DealContacts = ({}) => {
  const axios = useAxiosPrivate();
  const { id } = useParams();
  const { Title, Text } = Typography;
  const { contacts, setContacts } = useDealsDetailsCtx();
  const navigate = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [current, setCurrent] = useState()

  const onDelete = async (contactId, deleteConfirmed) => {
    if (!deleteConfirmed) {
      return;
    }

    const response = await axios.post("/Deal/DeleteContact", {
      dealId: id,
      contactId: contactId,
    });

    if (response.data.success) {
      setContacts((prev) => prev.filter((contact) => contact.id != contactId));
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
<DeleteConfirmationModal
        isOpen={deleteOpen}
        onDeleteConfirm={onDelete}
        onClose={() => setDeleteOpen(false)}
        item={current}
      />

      <Flex align="center" justify="space-between">
        <Title level={4} style={{ margin: 5 }}>
          Associated contacts
        </Title>

        <Button
          style={{ borderRadius: 12 }}
          icon={<FaPen />}
          className="bold-button flex-button"
          onClick={() => setShowDrawer(true)}
        >
          Edit
        </Button>
      </Flex>
      <Card
        className="no-head-space zero-margin-padding"
        style={{ body: { padding: 0, margin: 0 }, boxShadow: "none" }}
      >
        {contacts?.length == 0 ? (
          <div style={{ padding: 15 }}>
            <Text strong className="text-light">
              No contacts found
            </Text>
          </div>
        ) : (
          <List
            style={{ borderRadius: 20, border: "none" }}
            dataSource={contacts}
            renderItem={(item, index) => (
              <List.Item>
                <Space 
                  direction="vertical"
                  size={0}
                  onClick={() => navigate(`/audience/contact/${item.id}`)}
                  style={{ width: "100%", padding: "0px 15px", cursor:"pointer" }}
                >
                  <Flex
                    align="center"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Space direction="vertical" size={0}>
                      <Text strong style={{ margin: 0 }}>
                        {item.name} {item.lastName}
                      </Text>

                      <Space>
                        {item.email && <Text>{item.email}</Text>}
                        <Text>{item.email && item.phoneNumber && "|"}</Text>
                        {item.phoneNumber && <Text>{item.phoneNumber}</Text>}
                      </Space>
                    </Space>

                    <DealContactsOptions id={item.id} onConfirm={(e) => {
                      setCurrent(e);
                      setDeleteOpen(true)
                    }}/>
                  </Flex>
                </Space>
              </List.Item>
            )}
          />
        )}
        <AddContact setOpen={setShowDrawer} open={showDrawer} />
      </Card>
    </>
  );
};

export default DealContacts;
