import { Button, Dropdown, message } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { IoEllipsisHorizontal } from "react-icons/io5";

const SmsTemplateOptions = ({ id, onDelete, setCurrent, setShowUpdate }) => {
  const axios = useAxiosPrivate();

  const onSubmit = async () => {
    const response = await axios.delete(`/SmsTemplate/${id}`);

    if (response.data.success) {
      onDelete(id);
    } else {
      message.error(response.data.message);
    }
  };
  

  const items = [
    {
      label: <p className="drop-down-text">Edit</p>,
      key: 1,
      icon: <AiFillEdit className="drop-down-icon" />,
      onClick: () => {
        setCurrent(id);
        setShowUpdate(true);
      },
    },
    {
      label: <p className="drop-down-text">Delete</p>,
      danger: true,
      key: 2,
      onClick: () => onSubmit(),
      icon: <BsTrashFill className="drop-down-icon"/>,
    },
  ];

  
  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
      <Button
          className="flex-button"
          type="text"
          icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default SmsTemplateOptions;
