import { Button, message, Dropdown } from "antd";
import { IoMdMore } from "react-icons/io";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { BsBookmarkFill } from "react-icons/bs";

const WhatsappSenderOptions = ({ id, handleUpdate }) => {
  const axios = useAxiosPrivate();

  const updateDefault = async () => {
    const response = await axios.put(`/Whatsapp/numbers/default/set/${id}`);

    if (response.data.success) {
      handleUpdate(id);
    } else {
      message.error(response.data.message);
    }
  };
  const items = [
    {
      key: 1,
      label: "Set current as default",
      icon:<BsBookmarkFill />,
      onClick: () => updateDefault(id)
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          type="text"
          shape="circle"
          className="flex-button"
          icon={<IoMdMore style={{ fontSize: 20 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default WhatsappSenderOptions;
