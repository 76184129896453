import React, { useEffect, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import env from '../../env.json'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import axios from '../../api/axios';

const loadJitsiScript = () => {

    return new Promise((resolve, reject) => {
      const existingScript = document.getElementById('jitsi-script');
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://meet.tapzap.ai/external_api.js';
        script.id = 'jitsi-script';
        script.onload = () => resolve(true);
        script.onerror = () => reject(new Error('Failed to load Jitsi API script'));
        document.body.appendChild(script);
      } else {
        resolve(true);
      }
    });
  };

const MeetingCallLayout = () => {

    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

    const {id} = useParams();
    const jitsiContainer = useRef(null);
    const navigate = useNavigate();


    useEffect(async () => {
       

        const fetchRoom = async () => {
            const response  = await axios.get(`/Meeting-Room/Meet/${id}`);

            if(response.data.success){

                return response.data
            }

            message.error("Room not found! Redirecting to campaigns.");

            navigate("/Campaigns")
            
        }

        const room = await fetchRoom();

      loadJitsiScript()
        .then(() => {
          if (window.JitsiMeetExternalAPI) {
            const domain = 'meet.tapzap.ai';
            const options = {
              roomName: room.roomId,
              parentNode: jitsiContainer.current,
              userInfo: {
                displayName: `${queryParams.get('name') ? queryParams.get('name') : queryParams.get('email')}`,
              },
            };
            const api = new window.JitsiMeetExternalAPI(domain, options);
  
            // Optional: add event listeners or customize further
  
            return () => {
              api.dispose();
            };
          } else {
            console.error('Jitsi Meet API script not loaded');
          }
        })
        .catch((err) => {
          console.error('Error loading Jitsi script:', err);
        });
    }, []);
  
    return <div ref={jitsiContainer} style={{ width: '100%', height: '100vh' }}></div>;
  };


export default MeetingCallLayout