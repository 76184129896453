import { Flex, Segmented, Select, Typography } from "antd";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { OrderDirection } from "../../../shared/enums";
import { useContext } from "react";
import { ProductListContext } from "../ProductListContext";
import { MdArrowDropDown } from "react-icons/md";

const OrderDirectionProductFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(ProductListContext);

  const handleChanged = (e) => {
    setFilters((prev) => ({ ...prev, orderDirection: e }));
  };

  return (
    <>
     
      <Select
       size="large"
       style={{ width: "150px" }}
       className="order-by-select"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            label: "A - Z",
          },
          {
            value: OrderDirection.Dsc,
            label: "Z - A",
          },
        ]}
      />
    </>
  );
};

export default OrderDirectionProductFilter;
