import { Button, Drawer, Flex, Form, Space, message } from "antd";
import React, { useState } from "react";
import CompaniesMultiselect from "../../../FormItems/CompaniesMultiselect";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
const AddCompanyDrawer = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const [showAdd, setShowAdd] = useState(() => false)
  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.put("/Deal/Company", {
      dealId: id,
      companyIds: e.companies,
    });

    if (response.data.success) {
      setOpen(false);
      setCompanies(
        response.data?.items?.map((d) => ({
          name: d.name,
          id: d.id,
          phone: d.phone,
        }))
      );
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const { companies, setCompanies } = useDealsDetailsCtx();

  function onClose() {
    setOpen(false);
  }

  const initialValue = {
    companies: companies?.map((el) => el.id),
  };

  const addNewCmp = (e) => {
    if(form.getFieldValue("companies")){
      form.setFieldValue("companies", [...form.getFieldValue("companies"), e.id])
    }else{
      form.setFieldValue("companies", [e.id])
    }
  }
  return (
    <Drawer open={open} onClose={onClose} destroyOnClose title="Edit Companies">
      <Form form={form}
        layout="vertical"
        initialValues={initialValue}
        onFinish={onSubmit}
        preserve={false}
      >
        <Space direction="vertical" size={0} className="w-100">
          <CompaniesMultiselect showAdd={showAdd} setShowAdd={setShowAdd} onAddNewCompany={addNewCmp}/>
          <Button
            size="large"
            className="flex-button"
            type="link"
            block={false}
            style={{marginTop:-20}}
            icon={<IoMdAddCircle />}
            onClick={() => setShowAdd(true)}
          >
            Add company
          </Button>
        </Space>
        <Flex
          justify="space-between"
          gap={"20px"}
          style={{ marginTop: "auto" }}
        >
          <Button onClick={onClose} type="text">
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Save changes
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default AddCompanyDrawer;
