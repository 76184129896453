import { Flex } from "antd";
import { Outlet } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "./EmptyApplicationLayout.css";
const EmptyApplicationLayout = () => {
  return (
    <>
      <Flex align="center" justify="center">
        <img src={logo} className="logo-icon" />
      </Flex>

      <Outlet />
    </>
  );
};

export default EmptyApplicationLayout;
