import { Avatar, Button, Divider, Flex, List, Modal, Space, Typography } from "antd";
import { BiSolidRename } from "react-icons/bi";
import { HiPhone } from "react-icons/hi";
import { PiMapPinFill } from "react-icons/pi";

const NumberDetails = ({ open, setOpen, prices, number }) => {
  const { Title, Text } = Typography;

  return (
    <Modal
      footer={
        <Flex align="center" justify="end">
          <Button onClick={() => setOpen(false)}>Okay</Button>
        </Flex>
      }
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Number details
        </Title>
      }
    >
      <Space size={0} direction="vertical">
        <Title style={{ margin: 0 }}>{prices?.country}</Title>
        <Space>
          <Text className="icon-flex text-light" type="link">
            <PiMapPinFill />
            {number?.region}
          </Text>{" "}
          <Text className="text-light" strong>|</Text>
          <Text className="icon-flex text-light">
            <BiSolidRename /> {number?.friendlyName}
          </Text>
          <Text className="text-light" strong>|</Text>
          <Text className="icon-flex text-light">
            <HiPhone /> {number?.phoneNumber}
          </Text>
        </Space>
      </Space>

      <List
        itemLayout="horizontal"
        dataSource={prices?.phoneNumberPrices}
        renderItem={(item, index) => (
          <List.Item>
            <Flex align="top" justify="space-between" style={{ width: "100%" }}>
              
                <Title level={5} strong style={{ textTransform: "uppercase", margin:0, padding:0 }}>
                  {item.type}
                </Title>
              <Space align="end" direction="vertical" size={0}>
                <Text className="text-light">
                  {item.basePrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
                <Text
                  className="text-light"
                  style={{ textAlign: "right !important" }}
                >
                  + {prices.fee}%
                </Text>
                <Title level={3} style={{ margin: 0, padding: 0 }}>
                  {item.currentPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Title>
              </Space>
            </Flex>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default NumberDetails;
