import {
  Button,
  Drawer,
  Flex,
  Form,
  Input,
  message,
  Select,
  Space,
  Typography,
} from "antd";
import { MdCancel } from "react-icons/md";
import ButtonIcon from "../../../shared/ButtonIcon";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useContext } from "react";
import { AudienceContext } from "../AudienceContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const CreateContactDrawer = ({ open, setOpen, onAdd }) => {
  const { Text } = Typography;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const { tags } = useContext(AudienceContext);

  const axios = useAxiosPrivate();

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post(`Contacts`, {
        email: e.email,
        firstname: e.name,
        lastname: e.lastname,
        phoneNumber: e.phone,
        tags: e.tags
    });

    if(response.data.success){
        onAdd(response.data);
        setOpen(false)
    }else{
        message.error(response.data.message);
    }
    
    setLoading(false);
  };

  const validateEmailOrPhone = (_, value) => {
    const phone = form.getFieldValue("phone");
    const email = form.getFieldValue("email");

    if (!phone && !email) {
      return Promise.reject(
        new Error("Either email or phone must be provided.")
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title="Add contact form"
      >
        <Flex vertical justify="space-between" style={{ height: "100%" }}>
          <Form form={form} onFinish={submit}>
            <Space direction="vertical" size={0} className="w-100">
              <Space direction="vertical" size={0} className="w-100">
                <Text>Name</Text>
                <Form.Item name="name">
                  <Input placeholder="Enter name" size="large" />
                </Form.Item>
              </Space>
              <Space direction="vertical" size={0} className="w-100">
                <Text>Lastname</Text>
                <Form.Item name="lastname">
                  <Input placeholder="Enter lastname" size="large" />
                </Form.Item>
              </Space>

              <Space direction="vertical" size={0} className="w-100">
                <Text>Email</Text>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid email!",
                    },
                    {
                      validator: validateEmailOrPhone,
                    },
                  ]}
                >
                  <Input placeholder="Enter email" size="large" />
                </Form.Item>
              </Space>
              <Space direction="vertical" size={0} className="w-100">
                <Text>Phone</Text>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      validator: validateEmailOrPhone,
                    },
                  ]}
                >
                  <PhoneInput
                    inputStyle={{ width: "100%", height: 50 }}
                    containerStyle={{ width: "100%" }}
                    country={"us"}
                    className="w-100"
                  />
                </Form.Item>
              </Space>

              <Space direction="vertical" size={0} className="w-100">
                <Text>Tags</Text>

                <Form.Item name="tags">
                  <Select
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={tags?.map((c) => ({
                      label: c.name,
                      value: c.id,
                    }))}
                    className="w-100"
                    placeholder="Enter tags"
                    size="large"
                  />
                </Form.Item>
              </Space>
            </Space>
          </Form>

          <Flex align="center" justify="end" gap={6}>
            <Button
              className="flex-button new-buttons"
              onClick={() => setOpen(false)}
            >
              Cancel
              <ButtonIcon
                icon={<MdCancel style={{ fontSize: 16 }} />}
                background={"#fff"}
              />
            </Button>
            <Button
              loading={loading}
              onClick={() => form.submit()}
              type="primary"
              className="flex-button new-buttons"
            >
              Save changes
              <ButtonIcon
                icon={<FaCheck style={{ fontSize: 14 }} />}
                background={"#5a6bc5"}
              />
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default CreateContactDrawer;
