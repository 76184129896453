const ButtonIcon = ({ icon, color, background, size }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        background: background,
        height: "100%",
        marginRight: -5,
        padding: size == 'large' ? "0px 10px" : "0px 5px"
      }}
    >
      {icon}
    </div>
  );
};

export default ButtonIcon;
