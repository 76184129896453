import { Button, Checkbox, Flex, Form, Input, message, Modal, Typography } from "antd";
import ButtonIcon from "../../shared/ButtonIcon";
import { FiPlus } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import TextArea from "antd/es/input/TextArea";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreatefollowupModal = ({ open, setOpen }) => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const navigation = useNavigate();
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    let response = await axios.post(`/Followup`, {
        name: e.name,
        useDefaultSteps: e.useDefault
    });
    if(response.data.success){
        navigation(`/Followup/details/${response.data.id}`)
    }else{
        message.error(response.data.message)
    }

    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={3}>
          Create a follow up
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button
            className="flex-button new-buttons"
            onClick={() => setOpen(false)}
          >
            Cancel{" "}
            <ButtonIcon
              icon={<MdCancel style={{ fontSize: 16 }} />}
              background={"#fff"}
            />
          </Button>
          <Button
            type="primary"
            className="flex-button new-buttons"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes{" "}
            <ButtonIcon
              icon={<FaCheck style={{ fontSize: 14 }} />}
              background={"#5a6bc5"}
            />
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={submit}>
        <Text>Name</Text>
        <Form.Item className="no-error"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
            {
              min: 3,
              message:"Name should contain more than 2 characters"
            },
            {
              max: 128,
              name:"Name should be less than 128 characters"
            }
          ]}
          name="name"
        >
          <Input placeholder="Follow up name" size="large" count={{
            show:true,
            max:128
          }} />
        </Form.Item>

        <Form.Item name="useDefault" valuePropName="checked">
          <Checkbox size="large">Use default steps</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatefollowupModal;
