import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export const BalanceContext = createContext({
  balances: {
    email: 0,
    sms: 0,
    whatsapp: 0,
    lastEmailFill: null,
    lastSmsFill: null,
    lastWhatsappFill: null,
  },
  setBalances: () => {},
});

export const BalanceContextProvider = ({ children }) => {
  const [balances, setBalances] = useState(() => ({
    email: 0,
    sms: 0,
    whatsapp: 0,
    lastEmailFill: null,
    lastSmsFill: null,
    lastWhatsappFill: null,
    emailPrice: 0,
    smsPrice:0,
    whatsappPrice:0
  }));

  const axios = useAxiosPrivate();

  useEffect(async () => {
    const response = await axios.get(`/Balances`);

    setBalances((prev) => ({
      email: response.data.email,
      sms: response.data.sms,
      whatsapp: response.data.whatsapp,
      lastEmailFill: response.data.lastEmailFill,
      lastSmsFill: response.data.lastSmsFill,
      lastWhatsappFill: response.data.lastWhatsappFill,
      emailPrice: response.data.emailPrice,
      smsPrice:response.data.smsPrice,
      whatsappPrice:response.data.whatsappPrice
    }));
  }, []);
  return (
    <BalanceContext.Provider
      value={{
        balances: balances,
        setBalances: setBalances,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};
