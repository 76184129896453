import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  message,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useContext } from "react";
import { ImportContactContext } from "./ImportContactContext";
import { FaCheckCircle } from "react-icons/fa";
import { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useEffect } from "react";

const MapFileStep = ({ active }) => {
  const [showMap, setShowMap] = useState(() => false);
  const { Text, Title } = Typography;
  const {
    setStep,
    fileUploadStep,
    dataMapStep,
    setDataMapStep,
    dataMapStepConfirmed,
    setDataMapStepConfirmed,
  } = useContext(ImportContactContext);

  const options = [
    {
      value: 0,
      label: "Do not map",
    },
    {
      value: 1,
      label: "Firstname",
    },
    {
      value: 2,
      label: "Lastname",
    },
    {
      value: 3,
      label: "Email",
    },
    {
      value: 4,
      label: "Phone number",
    },
  ];

  const handleSelectChange = (value, column, ind) => {
    setDataMapStep((prevDataMapStep) => {
      if (
        value != 0 &&
        prevDataMapStep.filter((c) => c.value == value)?.length > 0
      ) {
        message.error("You already mapped such a column");
        return [...prevDataMapStep];
      }
      const updatedDataMapStep = prevDataMapStep.filter(
        (item) => item.column !== column
      );

      return [...updatedDataMapStep, { column, value, ind }];
    });
  };

  const [form] = Form.useForm();

  const submit = (e) => {
    const containsEmailOrPhone = Object.values(e).some(
      (value) => value === 3 || value === 4
    );

    if (!containsEmailOrPhone) {
      message.error("You should at least map a phone number or email");
      return;
    }
    setStep((prev) => prev + 1);
    setDataMapStepConfirmed(true);
  };

  useEffect(() => {
    dataMapStep?.forEach((c, i) => {
      if (c.value == 3) {
        form.setFieldValue(c.column, 3);
      }
      if (c.value == 4) {
        form.setFieldValue(c.column, 4);
      }
    });
  }, [active]);
  return (
    <>
      <Card size="small" style={{ borderRadius: 0 }}>
        <Space direction="vertical" size="large" className="w-100">
          <Flex align="center" justify="space-between">
            <Space direction="vertical" size={0} className="w-100">
              <Title level={4} style={{ margin: 0 }}>
                {dataMapStepConfirmed ? (
                  <FaCheckCircle style={{ color: "#1cc88a" }} />
                ) : (
                  "2."
                )}{" "}
                Mapping data
              </Title>
              {dataMapStepConfirmed ? (
                <Flex align="center" justify="start">
                  <Text>
                    {dataMapStep?.filter((c) => c.value != 0)?.length}{" "}
                    attributes
                  </Text>
                  <Button
                    type="link"
                    onClick={() => setShowMap((prev) => !prev)}
                  >
                    {showMap ? "Hide" : "Show"} mapping
                  </Button>
                </Flex>
              ) : (
                <Text>
                  Select the contact attribute that corresponds to your data.
                  You can select existing attributes, create new ones, or choose
                  not to import some data.
                </Text>
              )}
            </Space>

            {dataMapStepConfirmed ? (
              <Button
                type="link"
                size="large"
                onClick={() => {
                  setStep(2);
                  setDataMapStepConfirmed(false);
                }}
              >
                Edit mapping
              </Button>
            ) : (
              ""
            )}
          </Flex>

          {dataMapStepConfirmed && showMap && (
            <>
              <table
                border="1"
                cellPadding="5"
                cellSpacing="0"
                className="preview-table"
              >
                <thead>
                  <tr>
                    {dataMapStep
                      ?.filter((c) => c.value != 0)
                      ?.map((header, index) => (
                        <th key={index}>
                          <Space
                            direction="vertical"
                            className="w-100"
                            size={0}
                          >
                            {header.column}
                            <Text className="no-bold">
                              {header.value == 3
                                ? "Email"
                                : header.value == 1
                                ? "Firstname"
                                : header.value == 2
                                ? "Lastname"
                                : "Phone number"}
                            </Text>
                          </Space>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {fileUploadStep?.data.map((row, index) => (
                    <tr key={index}>
                      {dataMapStep
                        ?.filter((c) => c.value != 0)
                        ?.map((col, idx) => (
                          <td key={idx}>{row[col.column]}</td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {active && (
            <>
              <Form form={form} onFinish={submit}>
                <Space direction="vertical" size={0} className="w-100">
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      <Text className="column-header">File header</Text>
                    </Col>
                    <Col span={8}>
                      {" "}
                      <Text className="column-header">Data</Text>
                    </Col>
                    <Col span={8}>
                      {" "}
                      <Text className="column-header">CONTACT ATTRIBUTE</Text>
                    </Col>
                  </Row>

                  {fileUploadStep?.columns?.map((c, i) => (
                    <Card
                      key={i}
                      style={{
                        boxShadow: "none",
                        borderRadius: 0,
                        borderTopRightRadius: i == 0 ? 10 : 0,
                        borderTopLeftRadius: i == 0 ? 10 : 0,
                        borderBottomRightRadius:
                          i == fileUploadStep?.columns?.length - 1 ? 10 : 0,
                        borderBottomLeftRadius:
                          i == fileUploadStep?.columns?.length - 1 ? 10 : 0,
                      }}
                      size="small"
                    >
                      <Row gutter={[24, 24]}>
                        <Col span={8}>
                          <Text strong>{c}</Text>
                        </Col>
                        <Col span={8}>
                          <Space className="w-100" direction="vertical">
                            {fileUploadStep?.data.map((row, index) => (
                              <Text key={index}>{row[c]}</Text>
                            ))}
                          </Space>
                        </Col>
                        <Col span={8}>
                          <Flex
                            style={{ height: "100%" }}
                            align="center"
                            justify="center"
                          >
                            <Form.Item
                              className="w-100"
                              name={c}
                              rules={[
                                {
                                  required: true,
                                  message: "Please map column",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                maxTagCount="responsive"
                                className="order-by-select"
                                options={options}
                                placeholder="Select map"
                                onChange={(value) =>
                                  handleSelectChange(value, c, i)
                                }
                                suffixIcon={
                                  <MdArrowDropDown
                                    style={{ fontSize: 20, color: "#283371" }}
                                  />
                                }
                              />
                            </Form.Item>
                          </Flex>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Space>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    gap: 6,
                  }}
                >
                  <Text>
                    <Text strong>
                      {dataMapStep?.filter((c) => c.value != 0)?.length ?? 0}{" "}
                      columns
                    </Text>{" "}
                    to be imported
                    <br />
                    Ignored{" "}
                    <Text strong>
                      {fileUploadStep?.columns?.length -
                        dataMapStep?.filter((c) => c.value == 0)?.length}{" "}
                      columns
                    </Text>
                  </Text>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Confirm your mapping
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Space>
      </Card>
    </>
  );
};

export default MapFileStep;
